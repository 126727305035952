import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import HeroText from '../HeroText';
import { FaFlask, FaChartLine, FaComments, FaTools, FaRocket, FaLaptopCode, FaMapMarkedAlt } from "react-icons/fa";
// import { FaBullseye } from "react-icons/fa6";
// import { GrDocumentTest, GrDocumentConfig } from "react-icons/gr";
// import { AiOutlineTeam } from "react-icons/ai";
// import { PiUserFocus } from "react-icons/pi";
import { MdOutlineAssessment } from "react-icons/md";
// import { RiUserFollowLine } from "react-icons/ri";

const TalentTimeline: React.FC = () => {
    return (
        <div className='!font-jakarta container pb-16 lg:pb-32'>
            <HeroText
                tagline='PAST • PRESENT • FUTURE'
                title='Project Milestones'
                subtitle='Analysis, Design, Development, Testing, Deployment, Operations, Support'
                className='mb-8 flex flex-col items-center justify-center text-center'
            />
            <Timeline position='alternate'>
                {/* Existing Timeline Items */}
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        May 2022
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaFlask className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Research and Analysis
                        </Typography>
                        <Typography className='!font-jakarta'>Research and analysis of various talent tools in the market today with a focus on the end user experience.</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        September 2023
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaChartLine className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Assessment Tool Scoring and Job Profile Mapping
                        </Typography>
                        <Typography className='!font-jakarta'>Completed scoring integration for the assessment tool and mapped job profiles to assessment outcomes.</Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        December 2023
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaComments className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Industry Feedback for Talent Profile and Talent Search Functionality
                        </Typography>
                        <Typography className='!font-jakarta'>Gathered industry feedback on talent profiles and search functionality to enhance platform features.</Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        March 2024
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaTools className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Development Enhancements and End User Testing
                        </Typography>
                        <Typography className='!font-jakarta'>Implemented platform enhancements and conducted end-user testing to ensure quality and usability.</Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        May 2024
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <MdOutlineAssessment className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Stage 3 Assessment Integration
                        </Typography>
                        <Typography className='!font-jakarta'>Completed Stage 3 of assessment integration, enhancing the platform's evaluation capabilities.</Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        October 2024
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaMapMarkedAlt className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Talent and Industry Rollout Strategy Mapping
                        </Typography>
                        <Typography className='!font-jakarta'>Developed a comprehensive rollout strategy for talent and industry engagement.</Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        November 2024
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-green'>
                            <FaRocket className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Talent Experience is Live
                        </Typography>
                        <Typography className='!font-jakarta'>Launched the Talent Experience platform, making it live for users.</Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        January 2025
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaLaptopCode className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Industry Experience Beta and Demos
                        </Typography>
                        <Typography className='!font-jakarta'>Launched beta version of Industry Experience and conducted demos for stakeholders.</Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </div>
    );
}

export default TalentTimeline;
