import React, { useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import { useFeatureStore } from "../../common/types";
import classNames from "classnames";

interface FadeInOnViewProps {
    children: React.ReactNode;
    className?: string;
    elementType?: keyof JSX.IntrinsicElements;  // Allows users to specify the type of element
    id: string;
}

const FadeInOnView: React.FC<FadeInOnViewProps> = ({
    children,
    className,
    elementType = 'div',  // Default element type is 'div'
    id
}) => {
    const ref = useRef<HTMLElement>(null);
    const documentRef = useRef(document);
    const isInView = useInView(ref, {
        margin: "-50% 0px -50% 0px",
        // NOTE: The only reason we pass in the document here, is because
        // of security restrictions set by the browser when using an iFrame.
        // In an iFrame (so eg in the preview on frontend.fyi),
        // margin won't take effect unless you specify the root manually.
        // By default it will be the window element, which is what we want in this case.
        // If you specify your own root, you can usually only pass in an Element, and
        // not the document (since document/window is the default). However, in order
        // to fix the issue in the iframe, we need to pass in the document here and thus
        // tell TypeScript that we know what we're doing. If you're implementing
        // this in your own website, you can just pass in the root property as well as the documentRef.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        root: documentRef.current,
    });

    const setInViewFeature = useFeatureStore((state: any) => state.setInViewFeature);
    const inViewFeature = useFeatureStore((state: any) => state.inViewFeature);

    useEffect(() => {
        if (isInView) setInViewFeature(id);
        if (!isInView && inViewFeature === id) setInViewFeature(null);
    }, [isInView, id, setInViewFeature, inViewFeature]);


    const opacity = isInView ? 1 : 0.1;

    return React.createElement(elementType, {
        ref,
        style: { opacity, transition: 'opacity 0.3s ease-in-out' },
        className: classNames('transition-opacity', className),
        children,
    });
};

export default FadeInOnView;
