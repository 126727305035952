import FadeUp from "./animations/FadeUp";
import Subtitle from "./typography/Subtitle";
import Title from "./typography/Title";


interface TextImageSplitProps {
    title: string;
    subtitle: React.ReactNode;
    imageUrl: string;
    spinning?: boolean;
}

const TextImageSplit: React.FC<TextImageSplitProps> = ({ title, subtitle, imageUrl, spinning }) => {
    const animationClass = spinning ? "animate-slow-spin" : "";
    return (
        <div className="container mx-auto px-4 py-16 lg:py-52">
            <FadeUp>
                <div className="flex flex-col lg:flex-row justify-center items-center gap-12 z-20">
                    <div className="lg:w-1/2">
                        <Title variant='h2' >{title}</Title>
                        <Subtitle>{subtitle}</Subtitle>
                    </div>
                    <div className="lg:w-1/2">
                        <img src={imageUrl} alt="Cluster" className={`max-w-1/2 w-auto max-h-[40rem] mx-auto rounded-lg ${animationClass}`} />
                    </div>
                </div>
            </FadeUp>
        </div>
    );
};

export default TextImageSplit;
