import { useEffect, useState } from "react";
import { GlobeGroup, GlobePoint, GlobeType } from '../../common/types';
import { useGetGlobeGroups, useGetGlobePoints, useGetGlobeTypes, useUpdateGlobePoint } from '../../hooks/globeHooks';
import useTable from '../../hooks/useTable';
import FadeLeft from '../animations/FadeLeft';
import usePreventBodyScroll from '../../hooks/usePreventBodyScroll';
import PlacesAutocomplete from "../PlacesAutocomplete";

const DrawerEditGlobePoint = () => {
    const [pointName, setPointName] = useState('');
    const [description, setDescription] = useState('');
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [group, setGroup] = useState('');
    const [types, setTypes] = useState<string[]>([]);
    const [state, setState] = useState('');
    const [link, setLink] = useState('');

    const updatePoint = useUpdateGlobePoint();
    const {
        selectedRowId, handleDrawerEditToggle, openSnackbar
    } = useTable();
    const { data: globePoints } = useGetGlobePoints();
    const { data: globeGroups } = useGetGlobeGroups();
    const { data: globeTypes } = useGetGlobeTypes();
    usePreventBodyScroll();

    useEffect(() => {
        if (selectedRowId) {
            const point = globePoints.data.find((point: GlobePoint) => point.pointId === selectedRowId);
            setPointName(point?.name || '');
            setDescription(point?.description || '');
            setLongitude(point?.lng || '');
            setLatitude(point?.lat || '');
            setGroup(point?.groupId || '');
            setTypes(point?.types || '');
            setState(point?.state || '');
            setLink(point?.link || '');
        }
    }, [selectedRowId, globePoints]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!group) {
            openSnackbar('Please select a group');
            return;
        }

        const globePointData = {
            pointId: selectedRowId,
            name: pointName,
            description: description,
            lat: Number(latitude),
            lng: Number(longitude),
            groupId: Number(group),
            link,
            image: '',
            types: types,
            state: state
        };
        updatePoint.mutate({
            data: globePointData, apiKey: 'ZcgMq-tTnVqXkf97lYSEfdQpxJ_FuMO4QuDUT9vB0Vc'
        }, {
            onSuccess: () => {
                openSnackbar('Point updated successfully');
                handleDrawerEditToggle(null);
            },
            onError: (error: unknown) => {
                openSnackbar(`Error: ${error}`);
            }
        });
    };

    const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (e.target.checked) {
            setTypes([...types, value]);
        } else {
            setTypes(types.filter((type) => type !== value));
        }
    };

    return (
        <FadeLeft velocity={1000} opacity={1} translateX={"-100%"} className="font-jakarta fixed top-0 left-0 z-40 w-full h-screen max-w-3xl p-4 overflow-y-auto bg-white dark:bg-gray-800">
            <form onSubmit={handleSubmit} className="" tabIndex={-1}>
                <h5 id="drawer-label" className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400">Edit Globe Point</h5>
                <button type="button" onClick={() => handleDrawerEditToggle(null)} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    Close
                </button>
                <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
                    {/* Point Name */}
                    <div className="space-y-4 sm:col-span-2 sm:space-y-6">
                        <div>
                            <label htmlFor="point-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Point Name</label>
                            <input onChange={e => setPointName(e.target.value)} value={pointName} type="text" name="pointName" id="point-name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="point name" required />
                        </div>
                        {/* Description */}
                        <div>
                            <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                            <textarea id="description" rows={4} className="block w-full px-2.5 py-1.5 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" value={description} onChange={e => setDescription(e.target.value)} placeholder="Write point description here" required />
                        </div>
                        {/* Address */}
                        <div>
                            <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Address</label>
                            <PlacesAutocomplete
                                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
                                setNewLat={setLatitude}
                                setNewLng={setLongitude}
                            />
                        </div>
                        {/* Longitude */}
                        <div className="flex justify-center gap-4 w-full">
                            <div className="w-full">
                                <label htmlFor="longitude" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Longitude</label>
                                <input onChange={e => setLongitude(e.target.value)} value={longitude} type="text" name="longitude" id="longitude" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="longitude" required />
                            </div>
                            {/* Latitude */}
                            <div className="w-full">
                                <label htmlFor="latitude" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Latitude</label>
                                <input onChange={e => setLatitude(e.target.value)} value={latitude} type="text" name="latitude" id="latitude" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="latitude" required />
                            </div>
                        </div>
                    </div>
                    {/* Group, Types, State, Link */}
                    <div className="space-y-4 sm:space-y-6">
                        {/* Group Dropdown */}
                        <label htmlFor="group" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Group</label>
                        <select value={group} onChange={e => setGroup(e.target.value)} id="group" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                            {globeGroups?.data.map((group: GlobeGroup) => (
                                <option key={group.groupId} value={group.groupId}>{group.groupName}</option>
                            ))}
                        </select>
                        <div>
                            {/* Types Checkbox */}
                            <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type</label>
                            <div className="flex flex-wrap flex-col">
                                {globeTypes?.data.map((type: GlobeType) => (
                                    <div key={type.typeId} className="flex items-center mr-2 mb-2">
                                        <input
                                            type="checkbox"
                                            id={`type-${type.typeId}`}
                                            value={type.typeName}
                                            onChange={handleTypeChange}
                                            checked={types.includes(type.typeName)}
                                            className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label htmlFor={`type-${type.typeId}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-white">{type.typeName}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* State */}
                        <div>
                            <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">State</label>
                            <select id="state" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white" value={state} onChange={e => setState(e.target.value)}>
                                <option value="KS">Kansas</option>
                                <option value="MO">Missouri</option>
                            </select>
                        </div>
                        {/* Link */}
                        <div>
                            <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Link</label>
                            <input onChange={e => setLink(e.target.value)} value={link} type="text" name="link" id="link" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="link" required />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-6 sm:w-1/2">
                    <button type="submit" onClick={() => handleSubmit} className="text-white bg-ekc-blue hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-ekc-blue dark:focus:ring-primary-800">Update Point</button>
                    <button type="button" onClick={() => handleDrawerEditToggle(null)} className="text-red-600 inline-flex justify-center items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                        {/* Delete button SVG here */}
                        Cancel
                    </button>
                </div>
            </form >
        </FadeLeft>
    );
};

export default DrawerEditGlobePoint;
