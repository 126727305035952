import { useQuery, useMutation, useQueryClient } from 'react-query';
import { addResource, deleteResources, getResources, updateResource, uploadFile } from '../utils/api';

export const useGetResources = () => {
    return useQuery("resources", getResources);
}

export const useAddResource = () => {
    const queryClient = useQueryClient();
    return useMutation(addResource, {
        onSuccess: () => {
            queryClient.invalidateQueries('resources');
        },
    });
};

export const useUpdateResource = () => {
    const queryClient = useQueryClient();
    return useMutation(updateResource, {
        onSuccess: () => {
            queryClient.invalidateQueries('resources');
        },
    });
};

export const useDeleteResources = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteResources, {
        onSuccess: () => {
            queryClient.invalidateQueries('resources');
        },
    });
};


export const useUploadFile = () => {
    const queryClient = useQueryClient();
    return useMutation(uploadFile, {
        onSuccess: () => {
            queryClient.invalidateQueries('resources');
        },
    });
}