import { useRef, useEffect, Fragment } from 'react'
import { Tab } from '@headlessui/react'
import { Transition } from '@headlessui/react'

interface TabType {
    title: string
    img: string
    tag: string
    excerpt: string
    link: string
}

interface UnconventionalTabsProps {
    tabs: TabType[]
}

const UnconventionalTabs: React.FC<UnconventionalTabsProps> = ({ tabs }) => {


    const tabsRef = useRef<HTMLDivElement>(null)

    const heightFix = () => {
        if (tabsRef.current && tabsRef.current.parentElement) tabsRef.current.parentElement.style.height = `${tabsRef.current.clientHeight}px`
    }

    useEffect(() => {
        heightFix()
    }, [])

    return (
        <Tab.Group>
            {({ selectedIndex }: { selectedIndex: number }) => (
                <div className='font-jakarta'>
                    {/* Buttons */}
                    <div className="flex justify-center">
                        <Tab.List className="shadow-lg inline-flex flex-wrap justify-center bg-custom-gray rounded-[20px] p-1 mb-8 min-[480px]:mb-12">
                            {tabs.map((tab, index) => (
                                <Tab key={index} as={Fragment}>
                                    <button
                                        className={`flex-1 text-sm font-semibold h-8 px-4 rounded-2xl whitespace-nowrap focus-visible:outline-none ui-focus-visible:outline-none ui-focus-visible:ring ui-focus-visible:ring-indigo-300 transition-colors duration-150 ease-in-out ${selectedIndex === index ? "bg-ekc-blue text-white " : '!text-white'}} `}
                                    >
                                        {tab.title}
                                    </button>
                                </Tab>
                            ))}
                        </Tab.List>
                    </div>

                    {/* Tab panels */}
                    <Tab.Panels className="max-w-[640px] mx-auto">
                        <div className="relative flex flex-col" ref={tabsRef}>
                            {tabs.map((tab, index) => (
                                <Tab.Panel
                                    key={index}
                                    as={Fragment}
                                    static={true}
                                >
                                    <Transition
                                        as="article"
                                        show={selectedIndex === index}
                                        unmount={false}
                                        className="w-full bg-white rounded-2xl shadow-xl min-[480px]:flex items-stretch focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300"
                                        enter="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 transform order-first"
                                        enterFrom="opacity-0 -translate-y-8"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-300 transform absolute"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-12"
                                        beforeEnter={() => heightFix()}
                                    >
                                        <figure className="min-[480px]:w-1/2 p-2 md:min-h-[330px]">
                                            <img className="w-full h-[180px] min-[480px]:h-full object-contain rounded-lg p-8" width="304" height="214" src={tab.img} alt={tab.title} />
                                        </figure>
                                        <div className="min-[480px]:w-1/2 flex flex-col justify-center p-5 pl-3">
                                            <div className="flex justify-between mb-1">
                                                <header>
                                                    <div className="font-caveat text-xl font-bold text-ekc-blue">{tab.tag}</div>
                                                    <h1 className="text-xl font-semibold text-slate-900">{tab.title}</h1>
                                                </header>
                                            </div>
                                            <div className="text-slate-500 text-sm mb-2">{tab.excerpt}</div>

                                        </div>
                                    </Transition>
                                </Tab.Panel>
                            ))}

                        </div>
                    </Tab.Panels>
                </div>
            )}
        </Tab.Group>
    )
}

export default UnconventionalTabs;