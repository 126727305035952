// In TechnologyPage.tsx
import React from 'react';

import DescriptionImage from '../../assets/svgs/tech-icon.svg';
import BottomLogo from '../../assets/svgs/pptx_tech_logo.svg';
import Background from '../../assets/images/technology-bg.webp';
import ClusterContact from '../../components/clusters/ClusterContact';
import ClusterDescription from '../../components/clusters/ClusterDescription';
import ClusterContribute from '../../components/clusters/ClusterContribute';
import ClusterHeader from '../../components/clusters/ClusterHeader';
import ClusterDYK from '../../components/clusters/ClusterDYK';
import DYKLogo1 from '../../assets/svgs/holding-us-dollar.svg';
import DYKLogo2 from '../../assets/svgs/ai.svg';
import DYKLogo3 from '../../assets/svgs/windturbine.svg';
import DYKLogo4 from '../../assets/svgs/iot.svg';
import RegularFade from '../../components/animations/RegularFade';
import { statsTechnology } from '../../common/constants';
import { clusterStatId } from '../../common/constants';

const facts = [
    {
        fact: "The Technology Cluster is a key driver of global economic growth. Industry size is estimated at at over $4.6 trillion in 2023 and is expected to reach over $11.8 trillion in 2025 with a CAGR of 9%.",
        imageSrc: DYKLogo1,
    },
    {
        fact: "The EnterpriseKC Datasphere tracks the entire Technology Cluster but also isolates certain key subsets such as Artificial Intelligence and Microelectronics.",
        imageSrc: DYKLogo2,
    },
    {
        fact: "The Midwest Institute for Defense and Energy (MIDE) – on the UMKC campus, does innovative research, development, and prototyping of new technologies – areas of interest include Machine Learning, Wearable Sensors, Biometrics, and Autonomous Systems.",
        imageSrc: DYKLogo3,
    },
    {
        fact: "The most frequent specialized skills showing up in Artificial Intelligence job postings are Machine Learning, Computer Science, Python, Data Analysis, Data Science, SQL, and Project Management.",
        imageSrc: DYKLogo4,
    }
]

const Technology: React.FC = () => {
    return (
        <div>
            <ClusterHeader
                name="Technology"
                motto="Unleashing Innovation, Shaping Tomorrow’s World"
                backgroundImage={Background}
                industry="technology"
            />
            <ClusterDescription
                title="Technology"
                subtitle="The Technology cluster is made up of companies that earn most, or all their revenue from the development, manufacturing and sale of technology hardware or software applied to other industry segments or earn most or all of their revenue from providing technology hardware, software, or technology as a service."
                imageUrl={DescriptionImage}
                stats={statsTechnology}
                clusterId={clusterStatId.TECHNOLOGY}
            />
            <ClusterDYK
                facts={facts}
            />
            <ClusterContribute />
            <ClusterContact
                icon={BottomLogo}
                name="Technology"
            />
        </div>
    );
};

export default RegularFade(Technology);
