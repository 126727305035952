import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import useTable from '../../hooks/useTable';

const TableNav = () => {
    const { currentPage, itemsPerPage, totalItems, handlePageChange } = useTable();
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        handlePageChange(value);
    };

    return (
        <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center" className="p-4">
            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing
                <span className="font-semibold text-gray-900 dark:text-white"> {(currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, totalItems)} </span>
                of
                <span className="font-semibold text-gray-900 dark:text-white"> {totalItems}</span>
            </span>
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChange}
                shape="rounded"
            />
        </Stack>
    );
};

export default TableNav;
