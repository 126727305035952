import React from 'react';
import { useNavbarTransparency } from '../hooks/useNavbarTransparency';
import RegularFade from './../components/animations/RegularFade';

const PrivacyPolicyPage = () => {
    useNavbarTransparency(false);

    return (
        <div className="font-jakarta bg-white">
            <div className="container px-8 py-32 md:py-48 bg-white">
                <h1 className="text-2xl md:text-3xl font-bold mb-4">EnterpriseKC Privacy Policy</h1>
                <p className="mb-2 text-sm md:text-base"><strong>Last Modified on March 9, 2023</strong></p>

                <p className="mb-4">
                    At EnterpriseKC, we respect the privacy of our users and are committed to protecting their personal information. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website.
                </p>

                <h2 className="text-xl font-semibold mb-3">What Information do we collect?</h2>
                <p className="mb-4">
                    When you use our website, we may collect the following types of personal information:
                    <ul className="list-disc list-inside mb-4">
                        <li>Information you provide voluntarily, such as your name and email address when you sign up for our newsletter or to access our content.</li>
                        <li>Information we automatically collect when you use our website, such as your IP address, browser type, and device information.</li>
                        <li>Information we collect through cookies or similar technologies, which may include information about your browsing activity on our website.</li>
                    </ul>
                </p>

                <h2 className="text-xl font-semibold mb-3">How do we use your information?</h2>
                <p className="mb-4">
                    We may use your personal information for the following purposes:
                    <ul className="list-disc list-inside mb-4">
                        <li>To provide you with the products and services you have requested, such as PDF downloads of our various reports.</li>
                        <li>To add you to our quarterly distribution list if you opt-in to receiving them.</li>
                        <li>To analyze and improve our website and services.</li>
                        <li>To comply with legal and regulatory requirements.</li>
                    </ul>
                </p>

                <h2 className="text-xl font-semibold mb-3">Do we share your information?</h2>
                <p className="mb-4">
                    We may share your personal information with third parties in the following circumstances:
                    <ul className="list-disc list-inside mb-4">
                        <li>With service providers who assist us in providing our website and services.</li>
                        <li>When required by law or legal process, such as in response to a court order or subpoena.</li>
                    </ul>
                </p>

                <h2 className="text-xl font-semibold mb-3">How do we protect your information?</h2>
                <p className="mb-4">
                    We take careful measures to protect your personal information from unauthorized access, use, or disclosure. However, no security measures are completely foolproof, and we cannot guarantee the security of your information.
                </p>

                <h2 className="text-xl font-semibold mb-3">How long do we keep your information?</h2>
                <p className="mb-4">
                    We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected unless a longer retention period is required or permitted by law.
                </p>

                <h2 className="text-xl font-semibold mb-3">Your rights and choices.</h2>
                <p className="mb-4">
                    You have certain rights regarding your personal information, including the right to access, correct, or delete your information. You may also have the right to object or restrict certain uses of your information, or to withdraw your consent where we have relied on your consent to process your information. To exercise your rights, please contact us using the contact information below.
                </p>

                <h2 className="text-xl font-semibold mb-3">Updates to this Privacy Policy</h2>
                <p className="mb-4">
                    We may update this Privacy Policy from time to time. We will notify you of any material changes to this Privacy Policy by posting a notice on our website or by other means.
                </p>

                <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
                <p className="mb-4">
                    If you have any questions or concerns about our Privacy Policy or our handling of your personal information, please contact us at <a href="mailto:accounts@enterprise-kc.com" className="text-blue-600 hover:text-blue-800">accounts@enterprise-kc.com</a> or call 913-728-0133.
                </p>

                <h2 className="text-xl font-semibold mb-3">Effective Date</h2>
                <p className="mb-4">
                    This Privacy Policy is effective as of 3.01.2023.
                </p>

                <p className="mt-4">
                    For additional inquiries, you can also email us at <a href="mailto:info@enterprise-kc.com" className="text-blue-600 hover:text-blue-800">info@enterprise-kc.com</a>.
                </p>
            </div>
        </div>
    );
};

export default RegularFade(PrivacyPolicyPage);
