import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDetectAdBlock } from 'adblock-detect-react';
import App from '../App';
import { Provider } from 'react-redux';
import store from '../redux/store';

const TRACKING_ID = 'G-766C9LEL8K';

const RootComponent: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    const initializeGA = async () => {
      try {
        await ReactGA.initialize(TRACKING_ID);
        console.log("Analytics Success.");
      } catch (err) {
        console.log("Analytics Failure.", err);
      } finally {
        setIsInitialized(true);
      }
    };

    if (!adBlockDetected) {
      initializeGA();
    } else {
      console.log("AdBlocker detected. Skipping GA initialization.");
      setIsInitialized(true);
    }
  }, [adBlockDetected]);

  if (!isInitialized) return null;

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default RootComponent;
