import React from 'react';
import SimpleButton from './SimpleButton';
import FadeRight from './animations/FadeRight';
import Title from './typography/Title';
import Subtitle from './typography/Subtitle';
import { IconWithText } from '../common/types';


interface LayeredPanelProps {
    title: string;
    subtitle: string;
    buttonText: string;
    onButtonClick?: () => void;
    image: string;
    buttonIcon: React.ReactNode;
    icons?: IconWithText[];
    className?: string;
}

const LayeredPanel: React.FC<LayeredPanelProps> = ({
    title,
    subtitle,
    buttonText,
    buttonIcon,
    onButtonClick,
    image,
    icons,
    className
}) => {
    return (
        <div className={`bg-custom-gray relative z-10 pt-16 xl:pt-32 pb-[30rem] xl:pb-64 overflow-hidden px-4 ${className} z-10`}>
            <div className="container xl:px-12 shadow-lg max-w-[1180px] bg-gradient-to-r from-ekc-blue to-ekc-green font-jakarta rounded-[25px] flex flex-col xl:flex-row items-center justify-between relative h-[360px]">
                <div className="my-12 xl:my-0 text-center xl:text-left z-20 xl:w-2/5">
                    <Title className="text-white">{title}</Title>
                    <Subtitle className="text-white mb-6">{subtitle}</Subtitle>
                    <div className='flex justify-center xl:justify-start'>
                        <SimpleButton
                            label={buttonText}
                            textColor="ekc-text-gradient"
                            bgColor="bg-white"
                            size="medium"
                            roundness="rounded-full"
                            Icon={() => buttonIcon}
                            onClick={onButtonClick}
                        />
                    </div>
                </div>
                <div className="xl:absolute top-10 right-6 transform z-30">
                    <FadeRight>
                        <img
                            src={image}
                            alt="Cluster Data Visualization"
                            className="mb-4 w-[660px] h-auto rounded-lg drop-shadow-lg"
                        />
                    </FadeRight>
                    <div className='flex flex-col sm:flex-row justify-between mx-auto items-start sm:items-center max-w-md w-full text-md gap-4 sm:gap-0'>
                        {icons && icons.map(({ icon, text }, index) => (
                            <div key={index} className='flex items-center gap-2'>
                                {icon}
                                {text}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayeredPanel;