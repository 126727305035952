import React, { useEffect, useState } from 'react';
import Dropdown from './Dropdown';
import MobileDropdown from './MobileDropdown';
import { Link } from 'react-router-dom';
import { DropdownItem, MenuItem } from '../common/types';
import SimpleButton from './SimpleButton';
import { useAppSelector } from '../redux/hooks';
import { HiMiniBellAlert } from "react-icons/hi2";

type NavItem = MenuItem | DropdownItem;

interface NavBarProps {
    logo: string;
    navItems: NavItem[];
}

const NavBar: React.FC<NavBarProps> = ({ logo, navItems }) => {
    const [hasScrolled, setHasScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState<string | null>(null);
    const isNavbarTransparent = useAppSelector((state) => state.navbar.isTransparent);

    const handleScroll = () => {
        const offset = window.scrollY;
        setHasScrolled(offset > 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleDropdown = (label: string) => {
        if (openDropdown === label) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(label);
        }
    };

    return (
        <div className={`hcr{HiddenInHTML!?} fixed top-0 z-30 w-full text-white font-jakarta font-bold ${hasScrolled && 'shadow-md'}`}>
            {/* Upper bar */}
            <div className='bg-ekc-blue text-sm bg-color-bar bg-no-repeat'>
                <div className='container mx-auto px-4'>
                    <ul className="flex justify-between list-none m-0 py-3">
                        <li className='bg-'>
                            <Link
                                to="/grow-with-google"
                                className='text-white font-normal py-2 px-3 transition-colors duration-500'
                            >
                                <HiMiniBellAlert className='fill-ekc-light-green inline-block align-middle mr-2 text-lg' /> Apply For An <strong>EKC Scholarship!</strong> <u>Click Here</u>
                            </Link>
                        </li>
                        <li className='lg:inline-block align-middle hidden'>
                            <Link
                                to="/contact-us"
                                className='text-white py-2 px-3 transition-colors duration-500'
                            >
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Navigation bar */}
            <nav className={`${!isNavbarTransparent || hasScrolled ? '!bg-custom-black' : 'bg-transparent'} ${isMobileMenuOpen ? "!bg-custom-black" : "bg-transparent"} transition duration-500 ease-in-out`}>
                <div className='container mx-auto px-4 lg:px-20 py-6'>
                    <div className='flex flex-wrap items-center justify-between'>
                        <div className='shrink-0'>
                            <Link to="/">
                                <picture>
                                    <source srcSet={logo} type="image/webp" />
                                    <img src={logo} alt="logo" className='max-h-5 sm:max-h-10' />
                                </picture>
                            </Link>
                        </div>
                        <div className='hidden lg:flex'>
                            <menu className='flex justify-end'>
                                <div className="list-none m-0 p-0">
                                    {navItems.map((item, index) => {
                                        if (item.type === 'link') {
                                            return (
                                                <div key={index} className='inline-block px-4'>
                                                    <Link
                                                        to={item.to}
                                                        onClick={item.onClick}
                                                        className='text-white py-2 px-3 hover:text-gray-400 transition-colors duration-500'
                                                    >
                                                        {item.label}
                                                    </Link>
                                                </div>
                                            );
                                        } else if (item.type === 'dropdown') {
                                            return (
                                                <Dropdown
                                                    key={index}
                                                    label={item.label}
                                                    leftColumn={item.leftColumn}
                                                    rightColumn={item.rightColumn}
                                                    clickableLabel={item.clickableLabel}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                                <a href="/explore-data/kansas/" target="_blank" rel="noreferrer">
                                    <SimpleButton
                                        label="Explore the Data"
                                        textColor="text-white"
                                        bgColor="ekc-image-gradient"
                                        size="small"
                                        roundness="rounded-full"
                                    />
                                </a>
                            </menu>
                        </div>
                        {/* Hamburger button */}
                        <div className='block lg:hidden'>
                            <button
                                className='flex items-center px-3 py-2 border rounded text-gray-400 border-custom-gray hover:text-ekc-blue hover:border-ekc-blue'
                                onClick={toggleMobileMenu}
                            >
                                <svg
                                    className='fill-current h-3 w-3'
                                    viewBox='0 0 20 20'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <title>Menu</title>
                                    <path
                                        d='M0 0h20v20H0V0zm2 3h16v2H2V3zm0 5h16v2H2V8zm0 5h16v2H2v-2z'
                                        fillRule='evenodd'
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                {isMobileMenuOpen && <div className='w-full border-b border-gray-600 mx-auto' style={{ maxWidth: '90%' }}></div>}
            </nav>

            {/* Mobile menu */}
            <div className={`absolute top-full left-0 w-full bg-custom-black px-4 transition-opacity duration-500 ease-in-out overflow-y-auto max-h-screen ${isMobileMenuOpen ? "opacity-100 visible" : "opacity-0 invisible"}`}>
                <ul className="flex flex-col items-start py-5">
                    {navItems.map((item, index) => {
                        if (item.type === 'link') {
                            return (
                                <li key={index} className='w-full text-left'>
                                    <Link
                                        to={item.to}
                                        onClick={() => setIsMobileMenuOpen(false)}
                                        className='block py-2 px-4 text-white transition-colors duration-500'
                                    >
                                        {item.label}
                                    </Link>
                                </li>
                            );
                        } else if (item.type === 'dropdown') {
                            return (
                                <MobileDropdown
                                    key={index}
                                    label={item.label}
                                    leftColumn={item.leftColumn}
                                    rightColumn={item.rightColumn}
                                    isOpen={openDropdown === item.label}
                                    toggleDropdown={() => toggleDropdown(item.label)}
                                    closeMobileMenu={() => setIsMobileMenuOpen(false)}
                                />
                            );
                        }
                        return null;
                    })}
                    <li className='w-full text-left'>
                        <Link
                            to="/contact-us"
                            onClick={() => setIsMobileMenuOpen(false)}
                            className='block py-2 px-4 text-white transition-colors duration-500'
                        >
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default NavBar;
