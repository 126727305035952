import Subtitle from "../typography/Subtitle";
import CombinedView from "../../assets/images/CylinderModelCombined.webp";
import Title from "../typography/Title";
import Tagline from "../typography/Tagline";

const CylinderModel: React.FC = () => {
    return (
        <div className="bg-white">
            <div className="container">
                <div className='flex flex-col md:flex-row justify-between items-center gap-12 py-16 lg:py-52'>
                    <div className="lg:w-1/2">
                        <img src={CombinedView} alt="Front View of Cylinder Model" className="w-full" />
                    </div>
                    <div className="lg:w-1/2">
                        <Tagline className="ekc-text-gradient">Systematic, Measurable, Scalable</Tagline>
                        <Title className="">The Cylinder Model</Title>
                        <Subtitle>
                            EnterpriseKC has developed a disruptive, scalable system to identify high-potential enterprises both current and new and understand workforce supply chain, investment capital and other levers affecting accelerated enterprise growth. We call it the Cylinder Model. The model serves as a data-driven, actionable plan for accelerated growth in any cluster.
                        </Subtitle>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CylinderModel;
