import React from 'react';
import GrowHover from './animations/GrowHover';


export interface SplitButtonSideProps {
    label: string;
    link: string;
    side: 'left' | 'right';
    textColor: string;
    bgColor: string;
    growScale: number;
    roundness?: string;
    icon?: string;
    onClick?: () => void;
    className?: string;
}

const SplitButtonSide: React.FC<SplitButtonSideProps> = ({
    label, link, textColor, bgColor, icon, onClick, roundness, growScale, className, side
}) => (
    <a href={link} target='_blank' rel="noreferrer">
        <GrowHover scale={growScale} originX={side === 'left' ? 1 : 0}>
            <button
                className={`flex items-center justify-center gap-3 font-bold cursor-pointer ${bgColor} ${roundness} ${className}`}
                onClick={onClick}
            >
                {icon && (
                    <img src={icon} alt="icon" className={`w-12 ${side === 'right' ? 'order-1' : 'order-2'}`} />
                )}
                <span className={`whitespace-nowrap ${textColor} order-1`}>{label}</span>
            </button>
        </GrowHover>
    </a>
);

export default SplitButtonSide;
