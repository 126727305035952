import TableHeader from './TableHeader';
import TableCol from './TableCol';
import TableProvider from '../../providers/TableProvider';
import { ColumnConfig } from '../../common/types';
import TableBody from './TableBody';

interface TableProps<T> {
    name: string;
    columns: ColumnConfig<T>[];
    data: T[];
    isLoading: boolean;
    error: unknown;
    keyField: keyof T;
    nameField: keyof T;
}

const Table = <T,>({ name, columns, data, isLoading, error, keyField, nameField }: TableProps<T>) => {
    return (
        <TableProvider>
            <div className="bg-white shadow-md sm:rounded-lg max-w-[1440px] mx-auto mb-12 z-20">
                <TableHeader name={name} />
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <TableCol columns={columns} />
                        <TableBody columns={columns} data={data} isLoading={isLoading} error={error} keyField={keyField} nameField={nameField} />
                    </table>
                </div>
            </div>
        </TableProvider>
    );
};

export default Table;
