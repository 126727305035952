import React from 'react';
import Image from "../../assets/images/focus-enterprise.webp";
import Subtitle from '../typography/Subtitle';
import Title from '../typography/Title';
import Tagline from '../typography/Tagline';


interface EnterpriseFocusProps {
    tagline: string;
    title: string;
    subtitle?: React.ReactNode;
    alignment?: 'left' | 'center' | 'right';
    darkMode?: boolean;
    className?: string;
}

const defaultSubtitle = <p><b>Ent•ter•prise [ en-tər-ˌprīz ] noun. 1. a for profit organization, regardless of size, that derives 70% or more of its revenue from outside the region.</b>
    <br></br><br></br>
    EnterpriseKC was founded on the premise that the most effective way to drive economic growth is to grow clusters of enterprises. We focus on cluster-by-cluster enterprise growth because enterprises drive regional prosperity. With more and more revenue dollars coming from outside the region, a multiplier effect is created as those imported dollars go to work across the region – dollars that in turn fuel the regional construction, healthcare, services, financial, transportation, and entertainment markets. Dollars that fuel additional capital investment. Dollars that fuel additional charitable giving.
    <br></br><br></br>
    Enterprises drive regional job growth at a significantly higher rate than other business entities. Why? Because for an enterprise to grow, those business entities across the region that supply products and services to the enterprise must also grow. The economic growth formula is simple: The higher the concentration of enterprises, the greater the economic lift and the greater our regional prosperity.
</p>;

const EnterpriseFocus: React.FC<EnterpriseFocusProps> = ({ tagline, title, subtitle, alignment = 'left', darkMode, className }) => {
    return (
        <div className="container">
            <div className='flex flex-col lg:flex-row justify-between items-center gap-12 py-16 lg:py-32'>
                <img src={Image} alt="placeholder" className="lg:order-1 order-2 object-contain w-[35rem] aspect-1 rounded-3xl" />
                <div className='lg:order-2 order-1'>
                    <div className={`flex flex-col items-${alignment} justify-center text-${alignment} ${className}`}>
                        <Tagline className={`ekc-text-gradient ${darkMode ? 'text-gray-100' : ''}`}>{tagline}</Tagline>
                        <Title className={darkMode ? 'text-gray-100' : ''}>{title}</Title>
                        <Subtitle className={`${darkMode ? 'text-gray-100' : 'text-gray-500'}`}>{defaultSubtitle}</Subtitle>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnterpriseFocus;