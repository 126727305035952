import { motion } from 'framer-motion';
import React from 'react';

type AnyComponent = React.ComponentType<any>;

const Transition = (Component: AnyComponent) => {
    const WrappedComponent: React.FC = (props) => {
        return (
            <>
                <Component {...props} />
                <motion.div
                    // className="bg-shield fixed top-0 left-0 w-full h-screen bg-cover bg-center bg-no-repeat origin-top z-10"
                    className="fixed top-0 left-0 w-full h-screen bg-[#0f0f0f] origin-bottom z-10"
                    initial={{ y: 0 }}  // Start off-screen above the viewport
                    animate={{ y: "100%" }}        // Move to fully visible on-screen
                    exit={{ y: 0 }}      // Exit off-screen to the right
                    transition={{ duration: .5, ease: [0.22, 1, 0.36, 1] }}
                />
            </>
        );
    };

    return WrappedComponent;
};

export default Transition;
