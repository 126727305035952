import ImaginePic from "../../assets/images/imagine.webp";
import HeroText from "../HeroText";

const Imagine: React.FC = () => {
    return (
        <div className="container">
            <div className='flex flex-col lg:flex-row justify-between items-center gap-12 py-16 lg:py-32'>
                <HeroText
                    tagline="Transforming Futures Together"
                    title='Imagine'
                    subtitle="As a think and do tank, all of us at EnterpriseKC like to imagine. We imagine Kansas and Western Missouri as an epicenter for enterprise growth and innovation. A region focused on creating access to high demand, high opportunity jobs for a diverse and fully inclusive workforce. Jobs that can positively change the trajectory of an individual, of a family, of a generation. We understand that to have these jobs, we must increase our focus on improving the ecosystem for the enterprises who create them.​"
                />
                <img src={ImaginePic} alt="placeholder" className="w-[30rem] rounded-3xl" />
            </div>
        </div>
    );
}

export default Imagine;