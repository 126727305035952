import React from 'react';


interface BlobWrapperProps {
    children: React.ReactNode;
    maxWidth?: string;
    maxHeight?: string;
    backgroundColor?: string;
    blur?: string;
    rotate?: string;
    zIndex?: string;
}

const BlobWrapper: React.FC<BlobWrapperProps> = ({
    children,
    maxWidth = '345px',
    maxHeight = '492px',
    backgroundColor = 'bg-ekc-green',
    blur = '80px',
    rotate = '64.59deg',
    zIndex = '10'
}) => {
    // Inline styles for dynamic properties
    const dynamicStyles = {
        maxWidth,
        maxHeight,
        backgroundColor,
        filter: `blur(${blur})`,
        transform: `rotate(${rotate})`,
        zIndex
    };

    return (
        <div className='relative'>
            {children}
            <div
                className={`${backgroundColor} absolute inset-0 w-full h-full m-auto`}
                style={{ ...dynamicStyles, zIndex: '10' }}
            ></div>
        </div>
    );
};

export default BlobWrapper;
