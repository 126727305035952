import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import furtherEdBackground from '../assets/images/further-ed-hero-background.png';
import govBackground from '../assets/images/gov-hero-background.png';
import grades9_12Background from '../assets/images/grades9-12-hero-background.png';
import higherEdBackground from '../assets/images/higher-ed-hero-background.png';
import industryBackground from '../assets/images/industry-hero-background.png';
import k8background from '../assets/images/k8-hero-background.png';
import militaryBackground from '../assets/images/military-hero-background.png';
import Skyline from '../assets/images/skyline.png';
import Transition from '../components/animations/Transition';


const CybersecurityUnderstanding = React.lazy(() => import('../components/cyberrange/CybersecurityUnderstanding'));
const CybersecurityWelcome = React.lazy(() => import('../components/cyberrange/CybersecurityWelcome'));
const HeroHeader = React.lazy(() => import('../components/HeroHeader'));
const StayInTouch = React.lazy(() => import('../components/cyberrange/StayInTouch'));
const SignUpCyberRange = React.lazy(() => import('../components/cyberrange/SignUpCyberRange'));

interface HeroContent {
    title: string | string[];
    subtitle: string;
    images: string[];
    buttonText: string;
    opacity: string;
    leftTitle?: string;
    leftSubtitle?: string;
}

const CyberRangeLandingPage: React.FC = () => {
    const [heroContent, setHeroContent] = useState<HeroContent>({
        title: '',
        subtitle: '',
        images: [],
        buttonText: '',
        opacity: "opacity-50",
        leftTitle: "",
        leftSubtitle: ""
    });

    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const group = params.get('group');

        switch (group) {
            case 'k-8':
                setHeroContent({
                    title: 'Champion the Cyber Playground',
                    subtitle: "In the vast universe of games and digital fun, there lurk challenges waiting to be overcome. The Heartland Cyber Range is your arena to both play and protect, blending the excitement of games with the importance of cybersecurity. Step in and ensure our digital playgrounds are safe for all tomorrow's games.",
                    images: [k8background],
                    buttonText: "Sign up now",
                    opacity: "opacity-50",
                    leftTitle: "Josiah, 17",
                    leftSubtitle: "FUTURE CYBERSECURITY ANALYST"
                });
                break;
            case 'grades9-12':
                setHeroContent({
                    title: "Challenge Today's Threats, Shape Tomorrow's Defense!",
                    subtitle: "Unlock unparalleled experiences in the world of cyber. Engage in game-based learning, witness high-stakes capture the flag exercises, and embrace a curriculum that aligns with the NICE framework. Unlock a world of high-paying, high-opportunity cybersecurity career opportunities by honing your skills in the Heartland Cyber Range, where real-world experience is the gateway to a prosperous future.",
                    images: [grades9_12Background],
                    buttonText: "Sign up now",
                    opacity: "opacity-50",
                    leftTitle: "Tyler & Sam",
                    leftSubtitle: "FUTURE CYBERSECURITY ANALYSTS"
                });
                break;
            case 'higher-education':
                setHeroContent({
                    title: "Shape the Future, One Simulation at a Time!",
                    subtitle: "Dive deeper into the realm of cybersecurity with advanced learning tools tailored for the academically driven. Engage in tailor-made scenarios, integrate into your unique lesson plans, and stand poised to make a tangible impact in the industry post-graduation.",
                    images: [higherEdBackground],
                    buttonText: "Sign up now",
                    opacity: "opacity-50"
                });
                break;
            case 'industry':
                setHeroContent({
                    title: "Empowering Enterprises in the Digital Age!",
                    subtitle: "In a world of ever-evolving threats, elevate your corporate defense mechanisms. With hands-on training tools tailored for businesses, ensure every team member is versed in protecting and upholding the organization's digital integrity. Secure your business future today.",
                    images: [industryBackground],
                    buttonText: "Sign up now",
                    opacity: "opacity-50",
                    leftTitle: "Sarah, 20",
                    leftSubtitle: "FUTURE CYBERSECURITY ANALYST"
                });
                break;
            case 'state-and-local-gov':
                setHeroContent({
                    title: "Elevate Public Service: Strengthen Our Digital Defenses!",
                    subtitle: "Welcome to the Heartland Cyber Range! As stewards of public service in the digital age, let's prioritize the security of our communities. Delve into tailored training programs, empower your team, and ensure our localities remain secure in the face of cyber threats. Together, we'll ensure a protected and prosperous digital future for all.",
                    images: [govBackground],
                    buttonText: "Sign up now",
                    opacity: "opacity-70"
                });
                break;
            case 'military':
                setHeroContent({
                    title: "Redefining Valor in the Digital Age!",
                    subtitle: "The call to defend doesn't end; it evolves. With Heartland Cyber Range, you can channel your military experience into an expansive field of cybersecurity. Discover specialized training modules, gain insights from professionals, and forge your path in a sector that values your unique skill set. Transition with confidence, and safeguard tomorrow's digital world.",
                    images: [militaryBackground],
                    buttonText: "Sign up now",
                    opacity: "opacity-50"
                });
                break;
            case 'further-education':
                setHeroContent({
                    title: "Cyber Enlightenment for All!",
                    subtitle: "No matter your background or previous expertise, the Heartland Cyber Range welcomes you to a world of potential. Dive into curated modules, grasp the significance of cybersecurity in our modern age, and be the digital change you wish to see. Embrace the knowledge, safeguard your personal and professional domains, and lead the charge in digital awareness.",
                    images: [furtherEdBackground],
                    buttonText: "Sign up now",
                    opacity: "opacity-60"
                });
                break;
            default:
                setHeroContent({
                    title: ['Live Hands-On Experience:', 'The Ultimate Path to Real-World Preparedness'],
                    subtitle: "In a rapidly evolving cybersecurity landscape, staying current with the latest threats is crucial. Whether you're venturing into cybersecurity or a seasoned professional, the Heartland Cyber Range offers training you need to defend tomorrow, today.",
                    images: [Skyline],
                    buttonText: "Sign up now",
                    leftTitle: "",
                    opacity: "opacity-50"
                });
                break;
        }
    }, [location]);


    return (
        <>
            <HeroHeader {...heroContent}
                className="relative overflow-hidden flex items-center justify-center h-[900px] bg-cover font-jakarta md:bg-fixed bg-center md:bg-top sm:bg-bottom"
                id="whatisit" />
            <CybersecurityWelcome id="howitbenefits" />
            <StayInTouch />
            <CybersecurityUnderstanding id="thechallenge" />
            {/* <Testimonials id="Testimonials" /> */}
            <SignUpCyberRange />
        </>
    );
}

export default Transition(CyberRangeLandingPage);
