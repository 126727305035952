import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LinkedIn } from "../assets/svgs/linkedIn.svg";
import { ReactComponent as Instagram } from "../assets/svgs/instagram.svg";

// Seal image URL
const sealImageURL = "https://usfcr.com/assets/img/verified-vendor-seal-2024-sm.png";

interface FooterProps {
    copyrightText: string;
    logo: string;
}

const Footer: React.FC<FooterProps> = ({ copyrightText, logo }) => {
    return (
        <div>
            <footer className="bg-ekc-blue font-jakarta">
                <div className="container pb-8 pt-32 max-w-7xl m-auto">
                    <div className="gap-1 md:gap-8 grid grid-cols-[repeat(auto-fill,215px)]">
                        <div className="flex justify-center items-center flex-col">
                            <Link to="/">
                                <picture>
                                    <source srcSet={logo} type="image/webp" />
                                    <img src={logo} alt="logo" className='max-h-5 sm:max-h-10 drop-shadow-md' />
                                </picture>
                            </Link>
                            <a href="https://usfcr.com" target="_blank" rel="noreferrer">
                                <img src={sealImageURL} alt="USFCR Verified Vendor" title="US Federal Contractor Registration System for Award Management Verified Vendor Seal" className='mt-4 w-24 drop-shadow-md' />
                            </a>
                        </div>
                        <div className="mt-16 gap-8 xl:mt-0 xl:mx-auto">
                            <h3 className="text-white font-semibold text-xl">Location</h3>
                            <ul className="list-none mt-6 space-y-2 text-white">
                                <li>
                                    <a href="https://www.google.com/maps/search/?api=1&query=11150+Overbrook+Road+Leawood+Kansas+66211" target="_blank" rel="noreferrer" className="text-white hover:text-gray-300">
                                        11150 Overbrook Road <br /> Leawood, Kansas 66211
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-16 gap-8 xl:mt-0 xl:mx-auto">
                            <h3 className="text-white font-semibold text-xl">Start a conversation</h3>
                            <ul className="list-none mt-6 space-y-2 text-white">
                                <li className="hover:text-gray-300"><a href="mailto:info@enterprise-kc.com">info@enterprise-kc.com</a></li>
                                <li className="hover:text-gray-300"><a href="tel:913-728-0133">+1 913-728-0133</a></li>
                                <li className="flex !mt-8">
                                    <a href="https://www.instagram.com/enterprisekc_/" target="_blank" rel="noreferrer" aria-label="Instagram">
                                        <Instagram className="cursor-pointer w-6 h-6 fill-white" />
                                    </a>
                                    <a className="ml-6" href="https://www.linkedin.com/company/enterprisekc/" target="_blank" rel="noreferrer" aria-label="LinkedIn">
                                        <LinkedIn className="cursor-pointer w-6 h-6 fill-white" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-white border-t-2 pt-8 mt-16 border-white/[0.1] border-solid text-xs sm:mt-20 lg:mt-24">
                        <p>{copyrightText} | <Link to="/privacy-policy">Privacy Policy</Link> </p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
