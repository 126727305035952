import React, { useState } from 'react';
import TableContext from '../contexts/TableContext';

interface TableProviderProps {
    children: React.ReactNode;
}

const TableProvider: React.FC<TableProviderProps> = ({ children }) => {
    const [selectedRowId, setSelectedRowId] = useState<string | number | null>(null);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [showActionOptions, setShowActionOptions] = useState(false);
    const [showModalCreate, setShowModalCreate] = useState(false);
    const [showDrawerEdit, setShowDrawerEdit] = useState(false);
    const [showDrawerAdd, setShowDrawerAdd] = useState(false);
    const [showDrawerPreview, setShowDrawerPreview] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [tableName, setTableName] = useState("");
    const itemsPerPage = 10;
    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);

    const handleFilterOptionsToggle = () => {
        setShowFilterOptions(!showFilterOptions);
        setShowActionOptions(false);
        setShowModalCreate(false);
        setShowDrawerEdit(false);
        setShowDrawerPreview(false);
        setShowDrawerAdd(false);
    };

    const handleActionOptionsToggle = () => {
        setShowActionOptions(!showActionOptions);
        setShowFilterOptions(false);
        setShowModalCreate(false);
        setShowDrawerEdit(false);
        setShowDrawerPreview(false);
        setShowDrawerAdd(false);
    };

    const handleModalCreateToggle = () => {
        setShowModalCreate(!showModalCreate);
        setShowFilterOptions(false);
        setShowActionOptions(false);
        setShowDrawerEdit(false);
        setShowDrawerPreview(false);
        setShowDrawerAdd(false);
    };

    const handleDrawerEditToggle = (id: string | number) => {
        if (selectedRowId !== id || !showDrawerEdit) {
            setSelectedRowId(id);
            setShowDrawerEdit(true);
        } else {
            setShowDrawerEdit(false);
        }
        setShowFilterOptions(false);
        setShowActionOptions(false);
        setShowModalCreate(false);
        setShowDrawerPreview(false);
        setShowDrawerAdd(false);
    };

    const handleDrawerAddToggle = (id: string | number) => {
        setSelectedRowId(id);
        setShowDrawerAdd(!showDrawerAdd);
        setShowFilterOptions(false);
        setShowActionOptions(false);
        setShowModalCreate(false);
        setShowDrawerEdit(false);
        setShowDrawerPreview(false);
    }

    const handleDrawerPreviewToggle = (id: string | number) => {
        setSelectedRowId(id);
        setShowDrawerPreview(!showDrawerPreview);
        setShowFilterOptions(false);
        setShowActionOptions(false);
        setShowModalCreate(false);
        setShowDrawerEdit(false);
        setShowDrawerAdd(false);
    };

    const handleModalDeleteToggle = (id: string | number) => {
        setSelectedRowId(id);
        setShowModalDelete(!showModalDelete);
        setShowFilterOptions(false);
        setShowActionOptions(false);
        setShowModalCreate(false);
        setShowDrawerEdit(false);
        setShowDrawerPreview(false);
        setShowDrawerAdd(false);
    }

    const openSnackbar = (message: string) => {
        setSnackbarMessage(message);
        setShowSnackbar(true);
    }

    const closeSnackbar = () => {
        setShowSnackbar(false);
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    }

    const handleTableName = (name: string) => {
        setTableName(name);
    }

    const toggleGroupId = (groupId: string) => {
        setSelectedGroupIds(prevState => 
            prevState.includes(groupId) ? prevState.filter(id => id !== groupId) : [...prevState, groupId]
        );
    };



    return (
        <TableContext.Provider value={{
            showFilterOptions,
            showActionOptions,
            showModalCreate,
            showDrawerEdit,
            showDrawerAdd,
            showDrawerPreview,
            selectedRowId,
            showModalDelete,
            showSnackbar,
            snackbarMessage,
            searchTerm,
            currentPage,
            itemsPerPage,
            totalItems,
            tableName,
            selectedGroupIds,
            handleFilterOptionsToggle,
            handleActionOptionsToggle,
            handleModalCreateToggle,
            handleDrawerEditToggle,
            handleDrawerAddToggle,
            handleDrawerPreviewToggle,
            handleModalDeleteToggle,
            openSnackbar,
            closeSnackbar,
            handleSearch,
            handlePageChange,
            setTotalItems,
            handleTableName,
            toggleGroupId,
            setSelectedGroupIds,
        }}>
            {children}
        </TableContext.Provider>
    );
};

export default TableProvider;