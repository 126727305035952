import React from 'react';
import { motion } from 'framer-motion';

interface LogoLoadingProps {
    strokeColor?: string;
}

const LogoLoading: React.FC<LogoLoadingProps> = ({ strokeColor = "rgba(255,255,255,1)" }) => {
    const pathVariants = {
        hidden: { strokeDashoffset: 1000, strokeDasharray: 1000 },
        visible: {
            strokeDashoffset: 0,
            transition: {
                duration: .5,
                ease: "easeInOut",
                repeat: Infinity,
                repeatDelay: 0
            }
        }
    };


    return (
        <div className="w-full h-full flex justify-center items-center align-content-center self-center">
            <div className="w-full h-full flex justify-center items-center">
                <motion.svg className="w-64 h-64 m-auto" viewBox="-40 40 200 220">
                    <motion.path
                        d="M112.285,82.44C112.285,86.647 87.642,90.056 57.244,90.056C26.846,90.056 2.204,86.647 2.204,82.44L2.204,112.135C2.204,116.341 26.846,119.752 57.244,119.752C87.642,119.752 112.285,116.341 112.285,112.135L112.285,82.44Z"
                        style={{ fill: "rgb(213,224,77)", stroke: strokeColor, strokeWidth: "2px" }}
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                    <motion.path
                        d="M112.285,132.064C112.285,136.27 87.642,139.68 57.244,139.68C26.846,139.68 2.204,136.27 2.204,132.064L2.204,161.759C2.204,165.965 26.846,169.376 57.244,169.376C87.642,169.376 112.285,165.965 112.285,161.759L112.285,132.064Z"
                        style={{ fill: "rgb(84,185,71)", stroke: strokeColor, strokeWidth: "2px" }}
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"

                    />
                    <motion.path
                        d="M112.285,181.688C112.285,185.894 87.642,189.304 57.244,189.304C26.846,189.304 2.204,185.894 2.204,181.688L2.204,211.383C2.204,215.589 26.846,219 57.244,219C87.642,219 112.285,215.589 112.285,211.383L112.285,181.688Z"
                        style={{ fill: "rgb(0,125,183)", stroke: strokeColor, strokeWidth: "2px" }}
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                    <motion.path
                        d="M33.087,138.908L33.087,118.979C14.981,117.754 2.465,115.193 2.22,112.223L2.22,132.152C2.465,135.122 14.981,137.682 33.087,138.908Z"
                        style={{ fill: "rgb(160,206,103)", stroke: strokeColor, strokeWidth: "2px" }}
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                    <motion.path
                        d="M33.087,188.532L33.087,168.603C14.981,167.378 2.465,164.817 2.22,161.847L2.22,181.776C2.465,184.746 14.981,187.306 33.087,188.532Z"
                        style={{ fill: "rgb(0,160,176)", stroke: strokeColor, strokeWidth: "2px" }}
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                    <motion.path
                        d="M112.285,82.501C112.285,86.707 87.642,90.117 57.244,90.117C26.846,90.117 2.204,86.707 2.204,82.501C2.204,78.295 26.846,74.885 57.244,74.885C87.642,74.885 112.285,78.295 112.285,82.501Z"
                        style={{ fill: "rgb(0,82,136)", stroke: strokeColor, strokeWidth: "2px" }}
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                </motion.svg>
            </div>
        </div>
    );
}
export default LogoLoading 