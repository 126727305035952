import React from 'react';
import TalentSkills from '../../assets/images/talent-skills.webp';
import BlobWrapper from '../BlobWrapper';
import Title from '../typography/Title';
import Subtitle from '../typography/Subtitle';
import FadeRight from '../animations/FadeRight';

const AssessmentSkills: React.FC = () => {
    return (
        <div className="container z-20">
            <div className='flex flex-col lg:flex-row justify-between items-center gap-12 pb-18 lg:pb-32'>
                <div className='flex flex-col' >
                    <Title variant='h2'>Showcase Skills and Certificates</Title>
                    <Subtitle>Talent can showcase skills and certificates through the Talent Platform. By building their "Everyday Resume", talent can highlight their journey into and through the world of cybersecurity.</Subtitle>
                </div>
            <FadeRight>
                    <BlobWrapper backgroundColor="ekc-image-gradient" maxHeight='150px'>
                        <img src={TalentSkills} alt="Talent Skills" className="lg:w-[40rem] w-full h-auto rounded-xl shadow-lg relative z-30" />
                    </BlobWrapper>
                </FadeRight>
            </div>
        </div >
    );
}

export default AssessmentSkills;
