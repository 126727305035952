import React from 'react';

interface TaglineProps {
    children: React.ReactNode;
    className?: string;
}

const Tagline: React.FC<TaglineProps> = ({ children, className }) => {
    return <div className={`font-jakarta font-bold text-base lg:text-lg mb-4 ${className} inline-block`}>{children}</div>;
};

export default Tagline;
