import React from 'react';

interface ParallaxHeaderProps {
    title: string;
    description: string;
    backgroundImage: string;
    className?: string;
}

const ParallaxHeader: React.FC<ParallaxHeaderProps> = ({ title, description, backgroundImage, className }) => {
    return (
        <div
            className={`relative overflow-hidden flex items-center justify-center h-[900px] bg-cover font-jakarta md:bg-fixed bg-center md:bg-top sm:bg-bottom ${className}`}
            style={{
                backgroundImage: `url(${backgroundImage})`
            }}
        >
            <div className="absolute top-0 left-0 w-full h-full"></div>
            <div className="relative text-center p-4 md:p-8 lg:p-12">
                <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-medium mb-4 font-jakarta">{title}</h1>
                <p className="text-white text-lg md:text-xl lg:text-2xl max-w-4xl mx-auto font-jakarta">{description}</p>
            </div>
        </div>
    );
};

export default ParallaxHeader;
