import React from 'react';
import HeroText from './HeroText';
import { LazyLoadImage } from "react-lazy-load-image-component";

interface LandingHeaderProps {
    tagline: string;
    title: string;
    subtitle: string;
    image: string;
}

const LandingHeader: React.FC<LandingHeaderProps> = ({ tagline, title, subtitle, image }) => {
    return (
        <div className='flex flex-col lg:flex-row justify-center items-center gap-12 py-16 lg:py-32'>
            <div className='max-w-lg'>
                <HeroText
                    tagline={tagline}
                    title={title}
                    subtitle={subtitle}
                />
            </div>
            <div className='flex'>
                <LazyLoadImage 
                    src={image} 
                    alt="placeholder" 
                    className="object-cover object-top w-[35rem] h-auto aspect-1 rounded-3xl" 
                />
            </div>
        </div>
    );
};

export default LandingHeader;