import React from 'react';
import imageOne from '../assets/images/EnterpriseKC_logo.webp';
import imageTwo from '../assets/images/quick_start_guide.webp';
import imageThree from '../assets/images/EnterpriseKC_logo_blue.png';
import imageFour from '../assets/images/quick_start_guide.png';
import RegularFade from './../components/animations/RegularFade';

const ImageHost: React.FC = () => {
    return (
        <div className="font-jakarta bg-custom-black min-h-screen">
            <div className="flex flex-col">
                <img
                    src={imageOne}
                    alt="A serene lakeside at sunset with mountains in the background"
                    className="w-full h-auto object-cover mb-4"
                />
                <img
                    src={imageTwo}
                    alt="Close-up of a blooming red rose with dew drops"
                    className="w-full h-auto object-cover mb-4"
                />
                <img
                    src={imageThree}
                    alt="A bustling city skyline illuminated at night"
                    className="w-full h-auto object-cover mb-4"
                />
                <img
                    src={imageFour}
                    alt="Children playing in a vibrant autumn park"
                    className="w-full h-auto object-cover"
                />
            </div>
        </div>
    );
};



export default RegularFade(ImageHost);