import ContentLayout from '../../components/dashboard/ContentLayout';
import ResourceTable from '../../components/dashboard/ResourceTable';


const ResourceDashboardPage = () => {
    return (
        <div className='bg-white'>
            <ContentLayout>
                <ResourceTable />
            </ContentLayout>
        </div>
    )
};

export default ResourceDashboardPage;