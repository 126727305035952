import React from 'react';

interface SubtitleProps {
    children: React.ReactNode;
    className?: string;
}

const Subtitle: React.FC<SubtitleProps> = ({ children, className }) => {
    return <p className={`font-jakarta text-base max-w-2xl leading-loose mb-4 ${className}`}>{children}</p>;
};

export default Subtitle;
