// In ClusterDescription.tsx
import React, { useEffect, useState } from 'react';
import StatsCard from './StatsCard';
import { Stat } from '../../common/types';
import TextImageSplit from '../TextImageSplit';
import { useGetClusterStats } from '../../hooks/globeHooks';

interface ClusterDescriptionProps {
    title: string;
    subtitle: React.ReactNode;
    imageUrl: string;
    stats: Stat[];
    clusterId: number;
}


const ClusterDescription: React.FC<ClusterDescriptionProps> = ({ title, subtitle, imageUrl, stats, clusterId }) => {
    const { data: statsAPI, isLoading } = useGetClusterStats(clusterId);
    const [mergedStats, setMergedStats] = useState<Stat[]>(stats);

    useEffect(() => {
        if (!isLoading && statsAPI) {
            const updatedStats = stats.map(stat => {
                switch (stat.label) {
                    // case 'Total Companies':
                    //     return { ...stat, value: statsAPI.totalCompanies ?? 0 };
                    case 'Verified Companies':
                        return { ...stat, value: statsAPI.verifiedCompanies ?? 0 };
                    case 'Primary Enterprises':
                        return { ...stat, value: statsAPI.primaryEnterprises ?? 0 };
                    case 'Secondary Enterprises':
                        return { ...stat, value: statsAPI.secondaryEnterprises ?? 0 };
                    case 'Businesses':
                        return { ...stat, value: statsAPI.businessEnterprises ?? 0 };
                    case '% Revenue by Enterprises':
                        return { ...stat, value: statsAPI.revenuePercent ?? 0 };
                    case '% Employment by Enterprises':
                        return { ...stat, value: statsAPI.employmentPercent ?? 0 };
                    default:
                        return stat;
                }
            });
            setMergedStats(updatedStats);
        }
    }, [stats, statsAPI, isLoading]);

    return (
        <div className="bg-custom-black text-white font-jakarta">
            <div className="mt-[-200px] z-50 rounded-lg container">
                <StatsCard stats={mergedStats} />
            </div>
            <TextImageSplit title={title} subtitle={subtitle} imageUrl={imageUrl} />
        </div>
    );
};

export default ClusterDescription;
