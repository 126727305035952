import Profile from '../../components/Projects/Profile';
import Jay from '../../assets/images/Jay_Icon.webp';
import Banner from '../../assets/images/jay-banner.webp';
import DataSphereTimeline from '../../components/Projects/DataSphereTimeline';
import SectionSlant from '../../components/SectionSlant';
import Report from '../../assets/images/single-source-of-truth-cover.webp';
import FactGrid from '../../components/FactGrid';
import ArrowUp from '../../assets/svgs/arrow-growth-filled.svg';
import Users from '../../assets/svgs/users.svg';
import Speed from '../../assets/svgs/speed.svg';
import ParallaxHeader from '../../components/ParallaxHeader';
import BackgroundImage from "../../assets/images/datasphere-bg.webp";
import WhatIsIt from '../../components/Projects/WhatIsIt';
import Icon from '../../assets/images/ssot_mini_icon.webp';
import Stats from "../../assets/images/datasphere-landing.webp"
import ToolDefinition from '../../components/Projects/ToolDefinition';
import Graphic from '../../assets/images/ssot-graphic.webp';
import ToolShowcase from '../../components/Projects/ToolShowcase';
import EnterpriseGlobe from "../../assets/svgs/enterprise_globe.svg";
import GlobalSearch from "../../assets/svgs/global-search.svg";
import RegularFade from '../../components/animations/RegularFade';
import RibbonCard from '../../components/RibbonCard';
import Vision from "../../assets/images/vision_formula.png";
import Mission from "../../assets/images/mission_formula.png";
import Puzzle from "../../assets/svgs/extension-puzzle-outline.svg";

const facts = [
    { fact: "Support and grow enterprises that maximize economic lift.", imageSrc: Speed },
    { fact: "Keep the jobs and talent we already have in the region.", imageSrc: Users },
    { fact: "Create new high opportunity, high demand jobs resulting in more graduates and transitioning military remain in the region.", imageSrc: ArrowUp },
    { fact: "Find and support gazelles and promising startups to ensure they have the resources they need for growth and to keep them here.", imageSrc: GlobalSearch },
    { fact: "Recruit more enterprises and businesses to move here because of the region’s world-class talent supply chain and world-class research institutions.", imageSrc: EnterpriseGlobe },
];

const DataSpherePage: React.FC = () => {
    return (
        <div className="z-50 flex flex-col mb-32">
            <ParallaxHeader
                title="Datasphere"
                description='A Single Source of Truth for the Region'
                backgroundImage={BackgroundImage}
            />
            <div className="container z-20 mt-16 p-0">
                <RibbonCard
                    title="Problem Statement"
                    subtitle="Because there is no single source to determine the number of enterprises in each industry cluster, track their
                    trajectory, and their economic impact on the region’s economy.
                    Because there is no single source to determine the number of high demand, high opportunity jobs that will be
                    needed next year, in 3 years, in 5 years.
                    Because there is no single source to determine the volume of talent produced each year for these high-demand,
                    high-opportunity jobs."
                    ribbon="Problem Statement"
                    image={Puzzle}
                />
            </div>
            <WhatIsIt
                tagline="Centralizing and Analyzing Data"
                title='What Is It?'
                subtitle={<div>The EnterpriseKC (EKC) Datasphere, formerly known as the Single Source of Truth (SSoT), is a custom-built platform designed to centralize and analyze data critical to the growth of our region. Combining a Data Warehouse with Survey, CRM, and Analytic capabilities, it serves as the backbone of the EKC website empowering EKC Associates to deliver insights, personalized reports, and data to stakeholders across Kansas and Missouri.<br /><br /> Key features of the EKC Datasphere include the identification of relationships and trends among disparate sources, customizable survey tools, and seamless integration with other EKC platforms and systems. By harnessing these advanced functionalities, EKC gains a more comprehensive understanding of the interconnected dynamics with the region’s economic landscape.<br /><br /> Built on EnterpriseKC’s private Constellation, the EKC Datasphere ensures data security through state-of-the-art measures, safeguarding and anonymizing sensitive information to protect stakeholders’ privacy.</div>}
                image={Icon}
                side="left"
            />
            <ToolDefinition
                tagline="Empowering Regional Growth Through Data-Driven Insights"
                title='Cluster Economic Impact Breakdown'
                subtitle="The Data Warehouse provides a detailed analysis of revenue and employment contributions across various clusters in Kansas and Missouri. This breakdown highlights the economic impact of companies within each cluster, offering insights into the distribution of total revenue and employment. By filtering data by counties, clusters, and NAICS codes, users can explore specific economic dynamics and make informed decisions based on comprehensive data."
                image={Stats}
            />
            <ToolShowcase
                title="Why It's Important"
                subtitle="The EKC Datasphere team wakes up every morning looking to answer the pivotal questions that will shape the strategic direction of both individual clusters and the region as a whole. These questions span from understanding the composition of enterprises and their future workforce needs to analyzing the dynamics of regional growth and comparing our regions economic performance to others nationwide. Through rigorous data collection, analysis, and actionable insights, the Datasphere serves as a compass guiding our efforts towards a prosperous and resilient future."
                image={Graphic}
            />
            <SectionSlant
                tagline=""
                title="Datasphere"
                subtitle="Dive deeper into the details."
                image={Report}
                topColor='from-white'
                bottomColor='to-white'
                buttonText="Download the Narrative"
            />
            <FactGrid
                title="For the Region"
                subtitle="A Single Source of Truth in support of accelerated regional growth will help the region to...                "
                facts={facts}
                className="bg-white !text-black !pb-8"
            />
            <div className="container z-20 mb-32 p-0">
                <div className='flex flex-col justify-start items-start gap-20'>
                    <RibbonCard
                        title="Mission"
                        subtitle="The mission of the Datasphere is to assist policymakers, academic, and enterprise leaders in better understanding the factors that drive enterprise development, innovation, and job growth in support of data driven investment opportunities that result in accelerated economic growth and prosperity across the region."
                        ribbon="Mission"
                        image={Mission}
                    />
                    <RibbonCard
                        title="Vision"
                        subtitle={<p><em>Imagine</em> a single, comprehensive, trusted source of actionable information able to represent employment, enterprise growth, and innovation on a cluster-by-cluster basis across the region.

                            <em>Imagine</em> a single, comprehensive source of actionable information that aggregates the number of high-demand, high-opportunity jobs needed in the next year, in 3 years, in 5 years.

                            <em>Imagine</em> a single, comprehensive source of actionable information that identifies the firms in the region that have the best opportunity to grow and the high potential workforce contributors in each cluster.</p>}
                        ribbon="Vision"
                        image={Vision}
                    />
                </div>
            </div>
            <DataSphereTimeline />
            <Profile
                name="Jay Konomos"
                role="Pillar Leader"
                imageUrl={Jay}
                linkedinUser="jaykonomos"
                email="jkonomos@enterprise-kc.com"
                phone="913-728-0133"
                bannerUrl={Banner}
            />
        </div>
    )
}

export default RegularFade(DataSpherePage);