import React from 'react';

const ScrollToTop: React.FC = () => {
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <button
            onClick={handleScrollToTop}
            className="z-50 fixed bottom-4 right-4 bg-custom-black hover:bg-custom-blue text-white p-2 rounded-full w-10 h-10 transition duration-300 ease-in-out"
        >
            ↑
        </button>
    );
};

export default ScrollToTop;
