import React from 'react';
import HeroText from '../HeroText';
import BlobWrapper from '../BlobWrapper';
import FadeRight from '../animations/FadeRight';


interface ToolDefinitionProps {
    tagline: string;
    title: string;
    subtitle: string;
    image: string;
}


const ToolDefinition: React.FC<ToolDefinitionProps> = ({ tagline, title, subtitle, image }) => {
    return (
        <div className="container">
            <div className='flex flex-col lg:flex-row justify-between items-center gap-12 py-16 lg:py-32'>
                <HeroText
                    tagline={tagline}
                    title={title}
                    subtitle={subtitle}
                />
                <FadeRight>
                    <BlobWrapper backgroundColor="ekc-image-gradient" maxHeight='200px'>
                        <img src={image} alt="Assessment Tool" className="relative lg:w-[40rem] w-full h-auto rounded-xl shadow-lg z-20" />
                    </BlobWrapper>
                </FadeRight>
            </div>
        </div>
    );
}

export default ToolDefinition;
