import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface SidebarItemProps {
    href: string;
    icon: ReactElement;
    text: string;
}

function SidebarItem({ href, icon, text }: SidebarItemProps) {
    return (
        <li>
            <Link to={href} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="h-5 w-5 fill-gray-700">{icon}</svg>
                <span className="ms-3">{text}</span>
            </Link>
        </li>
    );
}

export default SidebarItem;