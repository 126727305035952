// import ActionOptions from "./ActionOptions";
// import FilterOptions from "./FilterOptions";
import { useTable } from './../../hooks/useTable';
import { Snackbar } from "@mui/material";
import DrawerAddGlobePoint from "./DrawerAddGlobePoint";
import { AnimatePresence } from "framer-motion";
import Subtitle from "../typography/Subtitle";
import React, { useEffect } from "react";
import TableNav from "./TableNav";
import DrawerAddGlobeGroup from "./DrawerAddGlobeGroup";
import DrawerAddGlobeType from "./DrawerAddGlobeType";
import DrawerAddResource from "./DrawerAddResource";
import DrawerAddGlobeCluster from "./DrawerAddGlobeCluster";

interface TableHeaderProps {
    name: string;
}

const TableHeader: React.FC<TableHeaderProps> = ({ name }) => {
    const {
        // showFilterOptions,
        // showActionOptions,
        snackbarMessage,
        showDrawerAdd,
        showSnackbar,
        // handleFilterOptionsToggle,
        // handleActionOptionsToggle,
        handleDrawerAddToggle,
        closeSnackbar,
        handleSearch,
        handleTableName
    } = useTable()

    useEffect(() => {
        handleTableName(name);
    }, [name, handleTableName]);

    return (
        <div className="top-0 bg-white">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                <div className="flex-1 flex items-center space-x-2">
                    <TableNav />
                </div>
                <Subtitle className="font-semibold ekc-text-gradient" >{name}</Subtitle>
            </div>
            <div className="flex flex-col md:flex-row items-stretch md:items-center md:space-x-3 space-y-3 md:space-y-0 justify-between mx-4 py-4 border-t dark:border-gray-700">
                <div className="w-full md:w-1/2">
                    <form className="flex items-center">
                        <label htmlFor="simple-search" className="sr-only">Search</label>
                        <div className="relative w-full">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
                                </svg>
                            </div>
                            <input type="text" onChange={handleSearch} placeholder="Search data" required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" />
                        </div>
                    </form>
                </div>
                <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                    <button type="button" onClick={handleDrawerAddToggle} className="flex items-center justify-center text-white bg-ekc-blue hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-ekc-blue focus:outline-none dark:focus:ring-primary-800">
                        <svg className="h-3.5 w-3.5 mr-1.5 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path clipRule="evenodd" fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" />
                        </svg>
                        Add Data
                    </button>
                    {/* Disable filter button */}
                    {/* <button disabled onClick={handleFilterOptionsToggle} className="w-full md:w-auto relative flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-ekc-blue focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="h-4 w-4 mr-1.5 -ml-1 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
                        </svg>
                        Filter options
                        <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path clipRule="evenodd" fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                        </svg>
                        {showFilterOptions &&
                            <FilterOptions />
                        }
                    </button> */}
                    {/* <button disabled onClick={handleActionOptionsToggle} className="relative w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-ekc-blue focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                        Actions
                        <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path clipRule="evenodd" fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                        </svg>
                        {showActionOptions && <ActionOptions />}
                    </button> */}
                </div>
                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={closeSnackbar}
                    message={snackbarMessage}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                <AnimatePresence>
                    {showDrawerAdd && (
                        name === "Globe Points" ? <DrawerAddGlobePoint /> :
                            name === "Globe Groups" ? <DrawerAddGlobeGroup /> :
                                name === "Globe Types" ? <DrawerAddGlobeType /> :
                                    name === "Resources" ? <DrawerAddResource /> :
                                        name === "Globe Clusters" ? <DrawerAddGlobeCluster /> :
                                            null
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}

export default TableHeader;