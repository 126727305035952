import { Navigate, useOutlet } from "react-router-dom";
import Sidebar from "../components/dashboard/Sidebar";
import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";


const DashboardLayout = () => {
    const outlet = useOutlet();
    const { isLoggedIn, isLoading } = useContext(AuthContext);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <Navigate to="/admin/login" />;
    }

    return (
        <>
            <Sidebar />
            {outlet}
        </>
    );
};

export default DashboardLayout;