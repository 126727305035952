import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from 'react-icons/io';
interface MobileDropdownProps {
    label: string;
    leftColumn: {
        title?: string;
        items: string[];
    };
    rightColumn?: {
        title?: string;
        items: string[];
    };
    isOpen: boolean;
    toggleDropdown: () => void;
    closeMobileMenu: () => void;
}

const MobileDropdown: React.FC<MobileDropdownProps> = ({ label, leftColumn, rightColumn, isOpen, toggleDropdown, closeMobileMenu }) => {
    return (
        <div className="inline-block text-left text-white font-bold font-inter w-full">
            {/* Button that toggles the dropdown's visibility on click */}
            <button
                onClick={toggleDropdown}
                className="w-full flex justify-between items-center px-4 py-2 text-white transition-colors duration-500"
                aria-expanded={isOpen}
            >
                {label} <span className="ml-2">{isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
            </button>

            {/* Content that is toggled on click */}
            {isOpen && (
                <div className="bg-white text-gray-600 shadow-lg rounded-md border border-gray-200 p-3 flex flex-col w-full">
                    {/* Left column of the dropdown */}
                    <div className="p-4">
                        {leftColumn.title && (<div className="font-bold text-gray-400 mb-2">{leftColumn.title}</div>)}
                        {leftColumn.items.map(item => (
                            <Link key={item} to={`${item.toLowerCase().replace(/\s+/g, '-')}`} className="block hover:text-custom-blue" onClick={closeMobileMenu}>
                                {item}
                            </Link>
                        ))}
                    </div>
                    {/* Right column of the dropdown */}
                    {rightColumn && (
                        <div className="p-4">
                            {rightColumn.title && (<div className="font-bold text-gray-400 mb-2">{rightColumn.title}</div>)}
                            {rightColumn.items.map(item => (
                                <Link key={item} to={`${item.toLowerCase().replace(/\s+/g, '-')}`} className="block hover:text-custom-blue" onClick={closeMobileMenu}>
                                    {item}
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MobileDropdown;
