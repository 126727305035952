import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';

interface FadeLeftProps {
    children: React.ReactNode;
    playOnce?: boolean;
    velocity?: number;
    className?: string;
    opacity?: number;
    translateX?: number | string;
}

const FadeLeft: React.FC<FadeLeftProps> = ({ children, playOnce = true, velocity = 100, className, opacity = 0, translateX = -200 }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: playOnce });

    const animationProps = {
        initial: { opacity: opacity, translateX: translateX },
        animate: isInView ? { opacity: 1, translateX: 0 } : {},
        transition: { type: "tween", velocity: velocity },
        exit: { opacity: opacity, translateX: translateX }
    };

    return (
        <motion.div ref={ref} {...animationProps} className={className}>
            {children}
        </motion.div>
    );
};

export default FadeLeft;
