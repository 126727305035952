import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SearchState {
    terms: Record<string, string>;
}

const initialState: SearchState = {
    terms: {},
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchTerm: (state, action: PayloadAction<{ id: string; term: string }>) => {
            const { id, term } = action.payload;
            state.terms[id] = term;
        },
    },
});

export const { setSearchTerm } = searchSlice.actions;

export default searchSlice.reducer;