import React, { useState } from 'react';
import SimpleButton from './SimpleButton';
import Background from '../assets/images/phone-background.png';
import SalesforceForm from './SalesforceForm';
import TripleLine from './TripleLine';
import Complete from './animations/Complete';

interface ContactFormProps {
    pageName: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ pageName }) => {
    const inputStyle = "p-2 md:p-4 rounded border-gray-200 border-1 font-jakarta bg-white text-black placeholder-black focus:ring-custom-blue block w-full appearance-none focus:outline-none focus:ring-0 focus:border-custom-blue peer";
    const labelStyle = "absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-custom-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 pointer-events-none";
    const formFields = (
        <div className='grid md:grid-cols-2 gap-4 w-full'>
            <div className="relative md:col-span-2">
                <input type="text" placeholder="" name="name" required className={`${inputStyle} md:col-span-2`} />
                <label htmlFor="name" className={labelStyle}>Name<span className="text-red-500">*</span></label>
            </div>
            <div className="relative md:col-span-2">
                <input type="email" placeholder="" name="email" required className={`${inputStyle} md:col-span-2`} />
                <label htmlFor="email" className={labelStyle}>Email<span className="text-red-500">*</span></label>
            </div>
            <div className="relative">
                <input type="text" placeholder="" name="zip" required pattern="[0-9]*" className={`${inputStyle}`} />
                <label htmlFor="zip" className={labelStyle}>Zip Code<span className="text-red-500">*</span></label>
            </div>
            <div className="relative">
                <input type="tel" placeholder="" name="phone" className={`${inputStyle}`} />
                <label htmlFor="phone" className={labelStyle}>Phone Number</label>
            </div>
            <div className="relative md:col-span-2">
                <textarea placeholder="" name="description" className={`${inputStyle} md:col-span-2`} />
                <label htmlFor="description" className={labelStyle}>Message</label>
            </div>
            <input type="hidden" name="Chosen-PDF" value={pageName} className={`${inputStyle}`} />
        </div>
    );

    const submitButton = (
        <SimpleButton
            size="medium"
            label="Send Message"
            textColor="text-white"
            bgColor="bg-custom-black hover:bg-opacity-70 w-full transition duration-200"
            onClick={() => { }}
            roundness=''
            fullWidth={true}
            growScale={1}
        />
    );

    const [isSubmitted, setIsSubmitted] = useState(false);

    const checkSubmission = (submitted: boolean) => {
        if (submitted) {
            setIsSubmitted(true);
        }
    };

    return (
        <section className="min-h-screen bg-cover font-jakarta" style={{ backgroundImage: `url(${Background})` }}>
            <div className="flex flex-col min-h-screen backdrop-blur pt-28 bg-custom-black/60">
                <div className="container flex flex-col flex-1 px-6 py-12 mx-auto">
                    <div className="flex-1 lg:flex lg:items-center lg:-mx-6">
                        <div className="text-white lg:w-1/2 lg:mx-6">
                            <h1 className="text-2xl font-semibold capitalize lg:text-3xl mb-4">Connect with EnterpriseKC</h1>
                            <TripleLine />
                            <p className="max-w-xl my-6">
                                At EnterpriseKC, we're dedicated to fostering economic development and regional prosperity through innovative and enterprising solutions. Our approach is business-led, systems-driven, and action-oriented, focusing on enterprise development, innovation, and job growth within high-value, high-opportunity industry clusters. Let's drive change together!
                            </p>
                            <iframe
                                className="h-72 w-[100%] lg:h-80 max-w-xl"
                                title="Google Maps"
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCU4J23OYV1Y2FervUwR-TUzAnBLHrrYPs&amp;q=11150+Overbrook+Road+Leawood%2C+Kansas+66211&amp;zoom=11"
                            />
                            <div className="mt-6 md:mt-8">
                                <h3 className="text-gray-300 ">Follow us</h3>

                                <div className="flex mt-4 -mx-1.5 ">

                                    <a className="mx-1.5 text-white transition-colors duration-300 transform hover:text-blue-500" href="https://www.instagram.com/enterprisekc_/" >
                                        <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.9294 7.72275C9.65868 7.72275 7.82715 9.55428 7.82715 11.825C7.82715 14.0956 9.65868 15.9271 11.9294 15.9271C14.2 15.9271 16.0316 14.0956 16.0316 11.825C16.0316 9.55428 14.2 7.72275 11.9294 7.72275ZM11.9294 14.4919C10.462 14.4919 9.26239 13.2959 9.26239 11.825C9.26239 10.354 10.4584 9.15799 11.9294 9.15799C13.4003 9.15799 14.5963 10.354 14.5963 11.825C14.5963 13.2959 13.3967 14.4919 11.9294 14.4919ZM17.1562 7.55495C17.1562 8.08692 16.7277 8.51178 16.1994 8.51178C15.6674 8.51178 15.2425 8.08335 15.2425 7.55495C15.2425 7.02656 15.671 6.59813 16.1994 6.59813C16.7277 6.59813 17.1562 7.02656 17.1562 7.55495ZM19.8731 8.52606C19.8124 7.24434 19.5197 6.10901 18.5807 5.17361C17.6453 4.23821 16.51 3.94545 15.2282 3.88118C13.9073 3.80621 9.94787 3.80621 8.62689 3.88118C7.34874 3.94188 6.21341 4.23464 5.27444 5.17004C4.33547 6.10544 4.04628 7.24077 3.98201 8.52249C3.90704 9.84347 3.90704 13.8029 3.98201 15.1238C4.04271 16.4056 4.33547 17.5409 5.27444 18.4763C6.21341 19.4117 7.34517 19.7045 8.62689 19.7687C9.94787 19.8437 13.9073 19.8437 15.2282 19.7687C16.51 19.708 17.6453 19.4153 18.5807 18.4763C19.5161 17.5409 19.8089 16.4056 19.8731 15.1238C19.9481 13.8029 19.9481 9.84704 19.8731 8.52606ZM18.1665 16.5412C17.8881 17.241 17.349 17.7801 16.6456 18.0621C15.5924 18.4799 13.0932 18.3835 11.9294 18.3835C10.7655 18.3835 8.26272 18.4763 7.21307 18.0621C6.51331 17.7837 5.9742 17.2446 5.69215 16.5412C5.27444 15.488 5.37083 12.9888 5.37083 11.825C5.37083 10.6611 5.27801 8.15832 5.69215 7.10867C5.97063 6.40891 6.50974 5.8698 7.21307 5.58775C8.26629 5.17004 10.7655 5.26643 11.9294 5.26643C13.0932 5.26643 15.596 5.17361 16.6456 5.58775C17.3454 5.86623 17.8845 6.40534 18.1665 7.10867C18.5843 8.16189 18.4879 10.6611 18.4879 11.825C18.4879 12.9888 18.5843 15.4916 18.1665 16.5412Z" fill="currentColor" />
                                        </svg>
                                    </a>
                                    <a className="mx-1.5 text-white transition-colors duration-300 transform hover:text-blue-500" href="https://www.linkedin.com/company/enterprisekc/">
                                        <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.2 8.80005C16.4731 8.80005 17.694 9.30576 18.5941 10.2059C19.4943 11.1061 20 12.327 20 13.6V19.2H16.8V13.6C16.8 13.1757 16.6315 12.7687 16.3314 12.4687C16.0313 12.1686 15.6244 12 15.2 12C14.7757 12 14.3687 12.1686 14.0687 12.4687C13.7686 12.7687 13.6 13.1757 13.6 13.6V19.2H10.4V13.6C10.4 12.327 10.9057 11.1061 11.8059 10.2059C12.7061 9.30576 13.927 8.80005 15.2 8.80005Z" fill="currentColor" />
                                            <path d="M7.2 9.6001H4V19.2001H7.2V9.6001Z" fill="currentColor" />
                                            <path d="M5.6 7.2C6.48366 7.2 7.2 6.48366 7.2 5.6C7.2 4.71634 6.48366 4 5.6 4C4.71634 4 4 4.71634 4 5.6C4 6.48366 4.71634 7.2 5.6 7.2Z" fill="currentColor" />
                                        </svg>
                                    </a>

                                </div>
                            </div>
                        </div>

                        <div className="mt-8 lg:w-1/2 lg:mx-6">
                            <div className="w-full px-8 py-10 mx-auto overflow-hidden bg-white shadow-2xl rounded-xl dark:bg-gray-900 lg:max-w-xl">
                                <h1 className="text-xl font-medium text-gray-700 dark:text-gray-200">Contact form</h1>

                                <p className="mt-2 text-gray-500 dark:text-gray-400 mb-8">
                                    Ask us everything and we would love to hear from you
                                </p>
                                {isSubmitted ? (
                                    <div className="flex justify-center items-center h-full">
                                        <div className="text-center">
                                            <Complete
                                                onComplete={() => { }}
                                                text="We got your message!"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <SalesforceForm
                                        debug={true}
                                        page={pageName}
                                        children={formFields}
                                        button={submitButton}
                                        enableReCAPTCHA={false}
                                        submissionCheck={checkSubmission}
                                        formStyle='flex flex-col justify-start md:justify-center items-center w-full'
                                        loadingCallback={() => { }}
                                    />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
