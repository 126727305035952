import { createContext } from "react";
import { LoginCredentials, RegisterCredentials, User } from "../common/types";

const AuthContext = createContext<{
    user?: User,
    isLoading: boolean,
    isLoggedIn: boolean,
    error?: string,
    login: (credentials: LoginCredentials) => Promise<void>,
    logout: () => void,
    register: (credentials: RegisterCredentials) => Promise<void>
}>({
    isLoading: true,
    isLoggedIn: false,
    login: async (_) => { },
    logout: () => { },
    register: async (_) => { }
});

export default AuthContext;