import { useState } from "react";
import usePreventBodyScroll from '../../hooks/usePreventBodyScroll';
import useTable from '../../hooks/useTable';
import FadeLeft from "../animations/FadeLeft";
import { useAddGlobeType } from '../../hooks/globeHooks';

const DrawerAddGlobeType = () => {
    const [typeName, setTypeName] = useState('');
    const addType = useAddGlobeType();
    const { handleDrawerAddToggle, openSnackbar } = useTable();
    usePreventBodyScroll();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!typeName) {
            openSnackbar('Please fill out the type name');
            return;
        }

        const globeTypeData = {
            typeName: typeName
        };

        addType.mutate({
            data: globeTypeData,
            apiKey: 'ZcgMq-tTnVqXkf97lYSEfdQpxJ_FuMO4QuDUT9vB0Vc'
        }, {
            onSuccess: () => {
                openSnackbar('Type added successfully');
                handleDrawerAddToggle(null);
            },
            onError: (error: unknown) => {
                openSnackbar(`Error: ${error}`);
            }
        });
    };

    return (
        <FadeLeft velocity={1000} opacity={1} translateX={"-100%"} className="font-jakarta fixed top-0 left-0 z-40 w-full h-screen max-w-3xl p-4 overflow-y-auto bg-white dark:bg-gray-800">
            <form onSubmit={handleSubmit} className="" tabIndex={-1}>
                <h5 id="drawer-label" className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400">Add Globe Type</h5>
                <button type="button" onClick={() => handleDrawerAddToggle(null)} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    Close
                </button>
                <div className="space-y-4">
                    <div>
                        <label htmlFor="type-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type Name</label>
                        <input onChange={e => setTypeName(e.target.value)} value={typeName} type="text" name="typeName" id="type-name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Type Name" required />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-6">
                    <button type="submit" className="text-white bg-ekc-blue hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-ekc-blue dark:focus:ring-primary-800">Add Type</button>
                    <button type="button" onClick={() => handleDrawerAddToggle(null)} className="text-red-600 inline-flex justify-center items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                        Cancel
                    </button>
                </div>
            </form>
        </FadeLeft>
    );
};

export default DrawerAddGlobeType;
