import React from 'react';
import { useParams } from 'react-router-dom';
import { industryURLs } from '../common/constants';


const DataPage: React.FC = () => {
    const { state = "KS", industry = "default" } = useParams();
    const url = industryURLs[state][industry];

    return (
        <iframe
            src={url}
            title={`${industry}-${state}`}
            className="w-full h-screen"
        />
    );
};

export default DataPage;
