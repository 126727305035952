import React, { useState, useEffect } from 'react';
import { LoginCredentials, RegisterCredentials, User } from '../common/types';
import { getCurrentUser, loginUser, logoutUser, registerUser } from '../utils/api';
import AuthContext from '../contexts/AuthContext';

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const updateUserStatus = (newUser: User | undefined, loggedIn: boolean, errorMessage?: string) => {
        setUser(newUser);
        setIsLoggedIn(loggedIn);
        setError(errorMessage);
        setIsLoading(false);
    };

    const checkUserStatus = () => {
        setIsLoading(true);
        getCurrentUser()
            .then((currentUser) => {
                if (currentUser && currentUser.username !== "Anonymous") {
                    updateUserStatus(currentUser, true);
                } else {
                    updateUserStatus(undefined, false);
                }
            })
            .catch((e) => {
                updateUserStatus(undefined, false, String(e));
            });
    };

    useEffect(() => {
        checkUserStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const login = (credentials: LoginCredentials): Promise<void> => {
        setIsLoading(true);
        return loginUser(credentials.username, credentials.password)
            .then((response) => {
                if (response.username) {
                    updateUserStatus({ username: response.username, id: response.id }, true);
                } else {
                    throw new Error('Login failed');
                }
            })
            .catch((err) => {
                updateUserStatus(undefined, false, err.message || 'Login failed');
            });
    };

    const logout = () => {
        setIsLoading(true);
        logoutUser()
            .then(() => {
                updateUserStatus(undefined, false);
            })
            .catch(() => {
                setError('Logout failed');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const register = (credentials: RegisterCredentials): Promise<void> => {
        setIsLoading(true);
        return registerUser(credentials.username, credentials.password, credentials.adminPassword)
            .then((registeredUser) => {
                if (registeredUser && registeredUser.username) {
                    updateUserStatus(registeredUser, true);
                } else {
                    throw new Error('Registration failed');
                }
            })
            .catch((err) => {
                updateUserStatus(undefined, false, err.message || 'Registration failed');
            });
    };

    return (
        <AuthContext.Provider value={{ user, isLoading, isLoggedIn, error, login, logout, register }}>
            {children}
        </AuthContext.Provider>
    );
};
