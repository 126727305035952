import GlobeGroupTable from '../../components/dashboard/GlobeGroupTable';
import ContentLayout from '../../components/dashboard/ContentLayout';
import GlobePointTable from '../../components/dashboard/GlobePointTable';
import GlobeTypeTable from '../../components/dashboard/GlobeTypeTable';
import GlobeClusterTable from '../../components/dashboard/GlobeClusterTable';

const GlobeDashboardPage = () => {
    return (
        <div className='bg-white'>
            <ContentLayout>
                <GlobePointTable />
                <GlobeGroupTable />
                <GlobeTypeTable />
                <GlobeClusterTable />
            </ContentLayout>
        </div>
    )
};

export default GlobeDashboardPage;