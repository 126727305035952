import React from 'react';
import TableRow from './TableRow';
import { ColumnConfig } from '../../common/types';
import LogoLoading from '../animations/LogoLoading';
import useTable from '../../hooks/useTable';

interface TableBodyProps<T> {
    columns: ColumnConfig<T>[];
    data: T[];
    isLoading: boolean;
    error: unknown;
    keyField: keyof T;
    nameField: keyof T;
}

const TableBody = <T,>({ columns, data, isLoading, error, keyField, nameField }: TableBodyProps<T>) => {
    const {
        searchTerm,
        currentPage,
        itemsPerPage,
        setTotalItems
    } = useTable();

    const filteredData = searchTerm
        ? data.filter((item: T) => (item[nameField as keyof T] as string)?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
        : data;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);
    setTotalItems(filteredData.length);

    if (isLoading) {
        return (
            <tbody>
                <tr>
                    <LogoLoading />
                </tr>
            </tbody>
        );
    }

    if (error) {
        return (
            <tbody>
                <tr>
                    <td colSpan={columns.length} className="text-center text-red-500 py-4">
                        Error: {error.toString()}
                    </td>
                </tr>
            </tbody>
        );
    }

    if (filteredData.length === 0) {
        return (
            <tbody>
                <tr>
                    <td colSpan={columns.length} className="text-center text-lg py-4">
                        No results found
                    </td>
                </tr>
            </tbody>
        );
    }

    return (
        <tbody>
            {paginatedData.map((item: T) => (
                <TableRow key={String(item[keyField])} data={item} columns={columns} keyField={keyField} />
            ))}
        </tbody>
    );
};

export default TableBody;