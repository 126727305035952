import React from 'react';
import SplitButtonSide, { SplitButtonSideProps } from './SplitButtonSide';
interface SplitButtonProps {
    left: SplitButtonSideProps;
    right: SplitButtonSideProps;
    size: 'small' | 'medium' | 'large';
    fullWidth?: boolean;
}

const sizeClasses = {
    small: 'py-3 px-4 h-10 text-sm',
    medium: 'py-3 px-8 h-12 text-base',
    large: 'py-3 px-10 h-16 text-lg',
};

const SplitButton: React.FC<SplitButtonProps> = ({
    left, right, size, fullWidth = false
}) => (
    <div className={`${fullWidth ? "w-full" : ""} `}>
        <div className="flex w-full">
            <SplitButtonSide {...left} className={`${sizeClasses[size]}`} />
            <SplitButtonSide {...right} className={`${sizeClasses[size]}`} />
        </div>
    </div>
);

export default SplitButton;
