import { useState } from 'react';
import { Resource } from '../common/types';

const useResourceAccess = () => {
    const [showModal, setShowModal] = useState(false);

    const handleResourceClick = (resource: Resource) => {
        const hasFilledForm = localStorage.getItem('filledForm') === 'true';
        if (!hasFilledForm) {
            setShowModal(true);
        } else {
            window.open(resource.pdfLink, "_blank");
        }
    };

    return { showModal, setShowModal, handleResourceClick };
};

export default useResourceAccess;
