// hooks/useScript.ts
import { useEffect } from 'react';

const useScript = (src: string, callbackName: string) => {
    useEffect(() => {
        // Create script element
        const script = document.createElement('script');
        script.src = src;
        script.async = true;

        // Append the script to the body
        document.body.appendChild(script);

        // Optional: Provide a callback function name if needed
        if (callbackName) {
            (window as any)[callbackName] = () => {
            };
        }

        // Clean up
        return () => {
            document.body.removeChild(script);
            if (callbackName) {
                (window as any)[callbackName] = undefined;
            }
        };
    }, [src, callbackName]);
};

export default useScript;
