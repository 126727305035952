import FactGrid from "../FactGrid";
import EnterpriseGlobe from "../../assets/svgs/enterprise_globe.svg";
import GlobalSearch from "../../assets/svgs/global-search.svg";
import Rocket from "../../assets/svgs/rocket.svg";
import Speed from "../../assets/svgs/speed.svg";
import Users from "../../assets/svgs/users.svg";
import Region from "../../assets/svgs/arrow-growth-filled.svg";
import Database from "../../assets/svgs/database.svg";
import Brain from "../../assets/svgs/brain.svg";
import Cogs from "../../assets/svgs/cogs.svg";

const facts = [
    { fact: "The growth of our regional economy must be defined by the growth in high value, high opportunity jobs.", imageSrc: Speed },
    { fact: "The growth of the regional economy is highly dependent on generating more and new revenue from enterprises – those locally based firms that generate over 70% of their revenue from outside the region.", imageSrc: Region },
    { fact: "Our ability to increase enterprise capacity in the region is highly dependent on the availability of a workforce-ready talent supply chain – we must win the people game.", imageSrc: Users },
    { fact: "Our ability to increase gazelles in the region is highly dependent on the availability of sophisticated investors at each stage of an enterprise’s growth.", imageSrc: GlobalSearch },
    { fact: "We drive opportunities out of real data accumulated through talking to real customers, which will create a single source of truth at both the cluster level as well as across the entire regional economy.", imageSrc: Database },
    { fact: "Regional economic development must take a standardized approach using the strategies depicted at the top of the Cylinder, which will allow for parallel actions across multiple clusters.", imageSrc: EnterpriseGlobe },
    { fact: "A Cluster Brain Trust for each cluster will draw people together with a common purpose and energize them around maximizing the potential of each cluster.", imageSrc: Brain },
    { fact: "The best way to measure economic growth is by tracking the Regional Gross Enterprise Product (RGEP) – revenue generated by enterprises in the region and the Regional Enterprise Employment Index (REEI) – the trend in employment at enterprises in the region.", imageSrc: Rocket },
    { fact: "This effort must be business led and non-partisan and include Cluster Brain Trusts, academic institutions, and state and regional economic development entities, all working together with clarity, alignment, focus, and accountability.", imageSrc: Cogs },
];

const Principles = () => {
    return (
        <>
            <div className="container">
                <div className='flex flex-col lg:flex-row justify-between items-center gap-12 py-16 lg:py-32'>
                </div>
            </div>
            <FactGrid
                title="EKC Principles"
                subtitle="We believe..."
                facts={facts}
            />
        </>
    );
}

export default Principles;