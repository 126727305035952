import {
    Top,
    Middle,
    Bottom
} from "./Card";
import { OtherVisual } from "./Visual";
import Title from "../typography/Title";
import FadeInOnView from "./FadeInOnView";
import Subtitle from "../typography/Subtitle";
const features = [
    {
        title: "High Opportunity, High Impact Growth​",
        id: "todo-list",
        card: Top,
        visual: OtherVisual,
    },
    {
        title: "Growth Potential​",
        id: "colors",
        card: Middle,
        visual: OtherVisual,
    },
    {
        title: "Vital to the regional economy, but less opportunity to develop​",
        id: "availability",
        card: Bottom,
        visual: OtherVisual,
    },
];

function FrontView() {
    return (
        <div className="container relative font-jakarta py-52">
            <div className='text-[3.5rem] sm:text-[7rem] 2xl:text-[10rem] uppercase font-extrabold text-black opacity-5 absolute z-10 left-6 2xl:-left-24 top-[8rem] sm:top-[2rem] 2xl:top-[3.5rem] whitespace-nowrap'>
                Front View
            </div>
            <Subtitle>
                The front view of the Cylinder represents the 20 clusters that EnterpriseKC is tracking and analyzing across the region. The clusters in green represent what the data shows to be the most high-opportunity targets for Kansas and Western Missouri based on the following:
                <ul className="list-image-bullet-ekc leading-loose space-y-4 mt-8">
                    <li className="pl-2 ml-8">High wage, high impact career opportunities for all.</li>
                    <li className="pl-2 ml-8">Academic and enterprise assets that drive innovation.</li>
                    <li className="pl-2 ml-8">Existing enterprise assets.</li>
                    <li className="pl-2 ml-8">High potential growth.</li>
                    <li className="pl-2 ml-8">Ability to influence and accelerate cluster growth</li>
                </ul>
            </Subtitle>
            <div className="flex w-full items-start gap-2 sm:gap-20 py-12">
                <div className="w-full">
                    <ul>
                        {features.map((feature) => (
                            <li className="" key={feature.id}>
                                <FadeInOnView className="py-1 sm:pt-4 sm:pb-4 md:pt-12 md:pb-12 lg:pt-40 lg:pb-8" id={feature.id}>
                                    <Title className="text-xl sm:text-4xl md:text-4xl lg:text-5xl !font-normal">{feature.title}</Title>
                                </FadeInOnView>

                            </li>
                        ))}
                    </ul>
                </div>
                <div className="relative aspect-1 w-full rounded-2xl  [&:has(>_.active-card)]:bg-transparent justify-center">
                    <div className="relative aspect-square w-full rounded-2xl  [&:has(>_.active-card)]:bg-transparent">
                        {features.map((feature) => (
                            <feature.card id={feature.id} key={feature.id} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default FrontView;
