import React from 'react';
import FadeUp from '../animations/FadeUp';
import Title from '../typography/Title';
import Subtitle from '../typography/Subtitle';

interface DYKFactProps {
    fact: string;
    index: number;
    imageSrc: string;
}

interface ClusterDYKProps {
    facts: Array<{ fact: string; imageSrc: string }>;
}

const DYKFact: React.FC<DYKFactProps> = ({ fact, index, imageSrc }) => {
    return (
        <div className={`p-4 rounded-lg m-2 flex flex-col justify-start`}>
            <div className="w-24 h-24 ekc-image-gradient" style={{
                maskImage: `url(${imageSrc})`,
                WebkitMaskImage: `url(${imageSrc})`,
                maskSize: 'contain',
                WebkitMaskSize: 'contain',
                maskRepeat: 'no-repeat',
                WebkitMaskRepeat: 'no-repeat',
                maskPosition: 'center',
                WebkitMaskPosition: 'center',
                backgroundColor: 'transparent',
            }} aria-label={`Fact ${index} logo`}>
            </div>
            <Subtitle className="text-left text-gray-500 mt-2">{fact}</Subtitle>
        </div >
    );
};

interface ClusterDYKProps {
    facts: Array<{ fact: string; imageSrc: string }>;
}

const ClusterDYK: React.FC<ClusterDYKProps> = ({ facts }) => {
    return (
        <div className="bg-gradient-to-b from-custom-black from-50% via-50% to-50% to-custom-gray text-black font-jakarta">
            <div className="skew-y-6 bg-custom-gray">
                <div className="skew-y-[-6deg] py-28 lg:py-52">
                    <FadeUp>
                        <div className="container mx-auto px-4">
                            <div className="mb-12 pl-3">
                                <Title variant='h2' className='ekc-text-gradient'>Did You Know?</Title>
                                <Subtitle className='text-gray-500'>Interesting facts to pique your curiosity</Subtitle>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                {facts.map((fact, index) => (
                                    <DYKFact key={index} fact={fact.fact} index={index} imageSrc={fact.imageSrc} />
                                ))}
                            </div>
                        </div>
                    </FadeUp>
                </div>
            </div>
        </div>
    );
};


export default ClusterDYK;