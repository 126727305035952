import { IconType } from "react-icons";
import { create } from "zustand";
export interface Types {
    typeName: string;
    typeColor: string;
}
export interface GlobePoint {
    pointId: number;
    name: string;
    lat: number;
    lng: number;
    groupId: number;
    groupName: string;
    arcColor: string;
    pointColor: string;
    description: string;
    link: string;
    image: string;
    types: string[];
    state: string;
}
export interface GlobeGroup {
    groupId: number;
    groupName: string;
    arcColor: string;
    pointColor: string;
    clusterId: number;
}

export interface GlobeType {
    typeId: number;
    typeName: string;
    typeColor: string;
}

export interface GlobeCluster {
    clusterId: number;
    name: string;
    description: string;
}

export interface HeadCell<T> {
    disablePadding: boolean;
    id: keyof T;
    label: string;
    numeric: boolean;
}

export interface DropdownColumn {
    title: string;
    items: string[];
}

export interface MenuItem {
    type: 'link';
    label: string;
    to: string;
    onClick?: () => void;
}

export interface DropdownItem {
    type: 'dropdown';
    label: string;
    clickableLabel?: boolean;
    leftColumn: DropdownColumn;
    rightColumn?: DropdownColumn;
}

export type NavItem = MenuItem | DropdownItem;

export interface Stat {
    label: string;
    value: number;
    image: IconType;
}

export interface Filter {
    name: string;
    label: string;
}

export interface Resource {
    id: string;
    title: string;
    subtitle: string;
    imageLink: string;
    pdfLink: string;
    tag: string;
}

export interface ResourceAdd extends Omit<Resource, "id"> {
}

export interface SearchState {
    searchTerm: string;
}

export type ArcData = {
    startLat: number;
    startLng: number;
    endLat: number;
    endLng: number;
    arcLabel: string;
    color: string[];
    randStroke: number;
    randDashLength: number;
    randDashGap: number;
    randDashInitialGap: number;
    randDashAnimateTime: number;
};

export interface User {
    username: string;
    id: number;
}

export interface LoginCredentials {
    username: string;
    password: string;
}

export interface RegisterCredentials {
    username: string;
    password: string;
    adminPassword: string;
}

export type PolygonData = {
    properties: {
        name: string;
        id: string;
        STATE: string;
        TYPE: string;
        CNTRY: string;
    };
    type: string;
    geometry: {
        type: string;
        coordinates: number[][][];
    };
    id: string;
}[];

export type PolygonProperties = {
    name: string;
    id: string;
};

export type PolygonFeature = {
    properties: PolygonProperties;
};
export interface Location {
    id: string;
    name: string;
    latitude: string;
    longitude: string;
    description: string;
    groupId: number;
    newLink: string;
    newImage: string;
}

export interface SimpleCardData {
    image: string;
    title: string;
    count: number;
}

export interface ColumnConfig<T> {
    key: keyof T;
    label: string;
}

export interface ColorRGBA {
    r: number;
    g: number;
    b: number;
    a: number;
}

export interface IndustryMap {
    [key: string]: string;
}
export interface IndustryURLs {
    [state: string]: IndustryMap;
}

type FeaturesStore = {
    inViewFeature: string | null;
    setInViewFeature: (feature: string | null) => void;
};

export const useFeatureStore = create<FeaturesStore>((set: (fn: (state: FeaturesStore) => FeaturesStore) => void) => ({
    inViewFeature: null,
    setInViewFeature: (feature: string | null) => set((state) => ({ ...state, inViewFeature: feature })),
}));

export interface IconWithText {
    icon: React.ReactNode;
    text: string;
}

export interface ClusterStat {
    clusterId: number | null;
    totalCompanies: number | null;
    verifiedCompanies: number | null;
    primaryEnterprises: number | null;
    secondaryEnterprises: number | null;
    revenuePercent: number | null;
    employmentPercent: number | null;
    businessEnterprises: number | null;
}