import React from 'react';
import FadeUp from './animations/FadeUp';
import Subtitle from './typography/Subtitle';
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Fact {
    fact: string;
    imageSrc: string;
}

interface FactGridProps {
    title: string;
    subtitle: string;
    facts: Fact[];
    className?: string;
}

const FactGrid: React.FC<FactGridProps> = ({ title, subtitle, facts, className }) => {
    return (
        <div className={`py-32 lg:py-52 bg-custom-black font-jakarta relative text-white ${className}`}>
            <FadeUp>
                <div className="container px-4">
                    <div className="mb-12">
                        <h2 className="text-4xl font-bold text-left ekc-text-gradient">{title}</h2>
                        <p className="mt-4 text-xl text-left">{subtitle}</p>
                    </div>
                    <div className="grid max-w-96 sm:max-w-none sm:grid-cols-[repeat(auto-fill,413px)] gap-16">
                        {facts.map((fact, index) => (
                            <div key={index} className="rounded-lg flex flex-col justify-start">
                                <LazyLoadImage
                                    threshold={150}
                                    className={"w-24 h-24 ekc-image-gradient"} style={{
                                        maskImage: `url(${fact.imageSrc})`,
                                        WebkitMaskImage: `url(${fact.imageSrc})`,
                                        maskSize: 'contain',
                                        WebkitMaskSize: 'contain',
                                        maskRepeat: 'no-repeat',
                                        WebkitMaskRepeat: 'no-repeat',
                                        maskPosition: 'center',
                                        WebkitMaskPosition: 'center',
                                        backgroundColor: 'transparent',
                                    }} aria-label={`Fact ${index + 1} logo`}/>
                                    
                                <Subtitle className='mt-4 font-medium'>{fact.fact}</Subtitle>
                            </div>
                        ))}
                    </div>
                </div>
            </FadeUp>
        </div>
    );
};

export default FactGrid;
