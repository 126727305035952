import { useEffect, useState } from "react";
import { ColorRGBA, GlobeCluster, GlobeGroup } from '../../common/types';
import usePreventBodyScroll from '../../hooks/usePreventBodyScroll';
import useTable from '../../hooks/useTable';
import ColorPicker from "../globe/ColorPicker";
import FadeLeft from "../animations/FadeLeft";
import { useGetGlobeClusters, useGetGlobeGroups, useUpdateGlobeGroup } from '../../hooks/globeHooks';

const DrawerEditGlobeGroup = () => {
    const [groupName, setGroupName] = useState('');
    const [color, setColor] = useState<ColorRGBA>({ r: 0, g: 0, b: 0, a: .9 });
    const updateGroup = useUpdateGlobeGroup();
    const { selectedRowId, handleDrawerEditToggle, openSnackbar } = useTable();
    const { data: globeGroups } = useGetGlobeGroups();

    const { data: globeClusters } = useGetGlobeClusters();
    const [clusterId, setCluster] = useState('');

    usePreventBodyScroll();

    useEffect(() => {
        if (selectedRowId) {
            const group = globeGroups?.data.find((group: GlobeGroup) => group.groupId === selectedRowId);
            if (group) {
                setGroupName(group.groupName);
                const colorArray = group.pointColor.replace('rgba(', '').replace(')', '').split(',');
                setColor({ r: parseInt(colorArray[0]), g: parseInt(colorArray[1]), b: parseInt(colorArray[2]), a: parseFloat(colorArray[3]) });
                setCluster(group.clusterId.toString());
            }
        }
    }, [selectedRowId, globeGroups]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!groupName || !color) {
            openSnackbar('Please fill out all required fields');
            return;
        }

        if (!clusterId) {
            openSnackbar('Please select a cluster');
            return;
        }

        const colorString = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
        const globeGroupData = {
            groupId: selectedRowId,
            groupName: groupName,
            pointColor: colorString,
            arcColor: colorString,
            clusterId: clusterId
        };

        updateGroup.mutate({
            data: globeGroupData, apiKey: 'ZcgMq-tTnVqXkf97lYSEfdQpxJ_FuMO4QuDUT9vB0Vc'
        }, {
            onSuccess: () => {
                openSnackbar('Group updated successfully');
                handleDrawerEditToggle(null);
            },
            onError: (error: unknown) => {
                openSnackbar(`Error: ${error}`);
            }
        });
    };

    return (
        <FadeLeft velocity={1000} opacity={1} translateX={"-100%"} className="font-jakarta fixed top-0 left-0 z-40 w-full h-screen max-w-3xl p-4 overflow-y-auto bg-white dark:bg-gray-800">
            <form onSubmit={handleSubmit} className="" tabIndex={-1}>
                <h5 id="drawer-label" className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400">Add Globe Group</h5>
                <button type="button" onClick={() => handleDrawerEditToggle(null)} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    Close
                </button>
                <div className="space-y-4 sm:col-span-2 sm:space-y-6">
                    <div>
                        <label htmlFor="group-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Group Name</label>
                        <input onChange={e => setGroupName(e.target.value)} value={groupName} type="text" name="groupName" id="group-name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Group Name" required />
                    </div>
                    <div>
                        <ColorPicker
                            selectedColor={color}
                            setSelectedColor={setColor}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        />
                    </div>
                    <div>
                        {/* Cluster Dropdown */}
                        <label htmlFor="cluster" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cluster</label>
                        <select value={clusterId} onChange={e => setCluster(e.target.value)} id="cluster" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                            <option value="" >Select a cluster</option>
                            {globeClusters?.data.map((cluster: GlobeCluster) => (
                                <option key={cluster.clusterId} value={cluster.clusterId}>{cluster.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-6 sm:w-1/2">
                    <button type="submit" onClick={() => handleSubmit} className="text-white bg-ekc-blue hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-ekc-blue dark:focus:ring-primary-800">Update Group</button>
                    <button type="button" onClick={() => handleDrawerEditToggle(null)} className="text-red-600 inline-flex justify-center items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                        {/* Delete button SVG here */}
                        Cancel
                    </button>
                </div>
            </form>
        </FadeLeft>
    );
};

export default DrawerEditGlobeGroup;
