import { createContext } from "react";

const TableContext = createContext<
    {
        showFilterOptions: boolean,
        showActionOptions: boolean,
        showModalCreate: boolean,
        showDrawerEdit: boolean,
        showDrawerAdd: boolean,
        showDrawerPreview: boolean,
        selectedRowId: any,
        showModalDelete: boolean,
        showSnackbar: boolean,
        snackbarMessage: string,
        searchTerm: string,
        currentPage: number,
        itemsPerPage: number,
        totalItems: number,
        tableName: string,
        // globeGroups: any,
        selectedGroupIds: string[],
        handleFilterOptionsToggle: () => void,
        handleActionOptionsToggle: () => void,
        handleModalCreateToggle: () => void,
        handleDrawerEditToggle: (id: any) => void,
        handleDrawerAddToggle: (id: any) => void,
        handleDrawerPreviewToggle: (id: any) => void,
        handleModalDeleteToggle: (id: any) => void,
        openSnackbar: (message: string) => void,
        closeSnackbar: () => void,
        handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void,
        handlePageChange: (page: number) => void,
        setTotalItems: (total: number) => void,
        handleTableName: (name: string) => void,
        toggleGroupId: (groupId: string) => void,
        setSelectedGroupIds: React.Dispatch<React.SetStateAction<string[]>>;
    }
>({
    showFilterOptions: false,
    showActionOptions: false,
    showModalCreate: false,
    showDrawerEdit: false,
    showDrawerAdd: false,
    showDrawerPreview: false,
    selectedRowId: null,
    showModalDelete: false,
    showSnackbar: false,
    snackbarMessage: "",
    searchTerm: "",
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    tableName: "",
    selectedGroupIds: [],
    handleFilterOptionsToggle: () => { },
    handleActionOptionsToggle: () => { },
    handleModalCreateToggle: () => { },
    handleDrawerEditToggle: (id: any) => { },
    handleDrawerAddToggle: (id: any) => { },
    handleDrawerPreviewToggle: (id: any) => { },
    handleModalDeleteToggle: (id: any) => { },
    openSnackbar: (message: string) => { },
    closeSnackbar: () => { },
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => { },
    handlePageChange: (page: number) => { },
    setTotalItems: (total: number) => { },
    handleTableName: (name: string) => { },
    toggleGroupId: (groupId: string) => { },
    setSelectedGroupIds: () => { }
});

export default TableContext;