// In HumanLifeSciences.tsx
import React from 'react';

import DescriptionImage from '../../assets/svgs/dna.svg';
import BottomLogo from '../../assets/svgs/pptx_lifesci_logo.svg';
import Background from '../../assets/images/human-life-sciences-bg.webp';
import ClusterContact from '../../components/clusters/ClusterContact';
import ClusterDescription from '../../components/clusters/ClusterDescription';
import ClusterContribute from '../../components/clusters/ClusterContribute';
import ClusterHeader from '../../components/clusters/ClusterHeader';
import ClusterDYK from '../../components/clusters/ClusterDYK';
import DYKLogo1 from '../../assets/svgs/holding-us-dollar.svg';
import DYKLogo2 from '../../assets/svgs/microscope.svg';
import DYKLogo3 from '../../assets/svgs/arrow-growth.svg';
import DYKLogo4 from '../../assets/svgs/heart.svg';
import RegularFade from '../../components/animations/RegularFade';
import { statsHumanLifeSciences } from '../../common/constants';
import { clusterStatId } from '../../common/constants';

const facts = [
    {
        fact: "EnterpriseKC views Human Life Sciences as a high-opportunity cluster with an average regional salary of $70,759.",
        imageSrc: DYKLogo1,
    },
    {
        fact: "Kansas City was designated a U.S. Department of Commerce Economic Development Administration (EDA) Tech Hub, labeled the KC BioHub, and focused on biologics and biomanufacturing.",
        imageSrc: DYKLogo2,
    },
    {
        fact: "The Global Life Sciences Cluster was estimated at $1.37 trillion in 2022 and is expected to grow at a compound annual growth rate (CAGR) of 13.96% from 2023 to 2030.",
        imageSrc: DYKLogo3,
    },
    {
        fact: "The Stowers Institute in Kansas City, Missouri has 500 researchers and support staff seeking to discover how specific genes and proteins are expressed and controlled by factors both externally and internally to an organism.",
        imageSrc: DYKLogo4,
    }
]

const HumanLifeSciences: React.FC = () => {
    return (
        <div>
            <ClusterHeader
                name="Human Life Sciences"
                motto="Cultivating Wellness, Enhancing Lives"
                backgroundImage={Background}
                industry="human-life-sciences"
            />
            <ClusterDescription
                title="Human Life Sciences"
                subtitle="The Human Life Sciences cluster is made up of companies that earn most or all of their revenue from the science and business of producing commercial research and testing, biotechnology, biologics, and medical instruments and devices.  "
                imageUrl={DescriptionImage}
                stats={statsHumanLifeSciences}
                clusterId={clusterStatId.HUMAN_LIFE_SCIENCES}
            />
            <ClusterDYK
                facts={facts}
            />
            <ClusterContribute />
            <ClusterContact
                icon={BottomLogo}
                name="Human Life Sciences"
            />
        </div>
    );
};

export default RegularFade(HumanLifeSciences);
