import { ClusterStat, ResourceAdd } from "../common/types";

const baseUrl = process.env.NODE_ENV === 'development' ?
    process.env.REACT_APP_DEV_BASE_URL :
    process.env.REACT_APP_PROD_BASE_URL;

const clustersBaseUrl = process.env.REACT_APP_CLUSTERS_BASE_URL;

async function fetchWithFallback(url: string, options?: RequestInit): Promise<Response> {
    const fetchOptions: RequestInit = {
        ...options,
        credentials: 'include',
    };

    return fetch(`${baseUrl}${url}`, fetchOptions)
        .then(response => {
            if (!response.ok) {
                // print the error json from the response
                return response.json().then(error => { throw error.error });
            }
            return response;
        })
        .catch(error => {
            throw error;
        });
}

/***********************************************************************************************
                                        CREATE
***********************************************************************************************/

export async function addGlobePoint({ data, apiKey }: { data: any; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': apiKey
        },
        body: JSON.stringify(data),
    };
    const response = await fetchWithFallback('/globe/points', options);
    return response.json();
}

export async function addGlobeType({ data, apiKey }: { data: any; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': apiKey
        },
        body: JSON.stringify(data),
    };
    const response = await fetchWithFallback('/globe/types', options);
    return response.json();
}

export async function addGlobeGroup({ data, apiKey }: { data: any; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': apiKey
        },
        body: JSON.stringify(data),
    };
    const response = await fetchWithFallback('/globe/groups', options);
    return response.json();
}

export async function uploadFile({ fileData, resourceData, apiKey }: { fileData: File; resourceData: any; apiKey: string }): Promise<any> {
    const formData = new FormData();
    formData.append('file', fileData);

    for (const key in resourceData) {
        if (resourceData.hasOwnProperty(key)) {
            formData.append(key, resourceData[key]);
        }
    }

    const options = {
        method: 'POST',
        headers: {
            'API-Key': apiKey
        },
        body: formData
    };

    const response = await fetchWithFallback('/resources/upload_file', options);
    return response.json();
}

export async function addResource({ resourceData, apiKey }: { resourceData: ResourceAdd; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': apiKey
        },
        body: JSON.stringify(resourceData),
    };
    const response = await fetchWithFallback('/resources/', options);
    return response.json();
}

export async function loginUser(username: string, password: string): Promise<any> {
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password }),
    };
    const response = await fetchWithFallback('/auth/login', options);
    return response.json();
}

export async function registerUser(username: string, password: string, adminPassword: string): Promise<any> {
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Key': adminPassword
        },
        body: JSON.stringify({ username, password }),
    };
    const response = await fetchWithFallback('/auth/register', options);
    return response.json();
}

export async function addGlobeCluster({ data, apiKey }: { data: any; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': apiKey
        },
        body: JSON.stringify(data),
    };
    const response = await fetchWithFallback('/globe/clusters', options);
    return response.json();
}


/***********************************************************************************************
                                        READ
***********************************************************************************************/

export async function getGlobePoints(): Promise<any> {
    const response = await fetchWithFallback('/globe/points', { method: 'GET' });
    return response.json();
}

export async function getGlobeGroups(): Promise<any> {
    const response = await fetchWithFallback('/globe/groups', { method: 'GET' });
    return response.json();
}

export async function getGlobeTypes(): Promise<any> {
    const response = await fetchWithFallback('/globe/types', { method: 'GET' });
    return response.json();
}

export async function getResources(): Promise<any> {
    const response = await fetchWithFallback('/resources/', { method: 'GET' });
    return response.json();
}

export async function getCurrentUser(): Promise<any> {
    const response = await fetchWithFallback('/auth/whoami', { method: 'GET' });
    if (!response.ok) {
        throw new Error('Not logged in');
    }
    return response.json();
}

export async function logoutUser(): Promise<any> {
    const options: RequestInit = { method: 'GET', };
    const response = await fetchWithFallback('/auth/logout', options);
    return response.json();
}

export async function getGlobeClusters(): Promise<any> {
    const response = await fetchWithFallback('/globe/clusters', { method: 'GET' });
    return response.json();
}

export async function getClusterStats(clusterId: number): Promise<ClusterStat> {

    const response = fetch(`${clustersBaseUrl}/${clusterId}`).then(response => {
        if (!response.ok) {
            // print the error json from the response
            return response.json().then(error => { throw error.error });
        }
        return response.json();
    }
    ).catch(error => {
        throw error;
    });
    return response;
}

/***********************************************************************************************
                                        UPDATE
***********************************************************************************************/

export async function updateGlobePoint({ data, apiKey }: { data: any; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': apiKey
        },
        body: JSON.stringify(data),
    };
    const response = await fetchWithFallback('/globe/points', options);
    return response.json();
}

export async function updateGlobeGroup({ data, apiKey }: { data: any; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': apiKey
        },
        body: JSON.stringify(data),
    };
    const response = await fetchWithFallback('/globe/groups', options);
    return response.json();
}

export async function updateGlobeType({ data, apiKey }: { data: any; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': apiKey
        },
        body: JSON.stringify(data),
    };
    const response = await fetchWithFallback('/globe/types', options);
    return response.json();
}

export async function updateResource({ resourceData, apiKey }: { resourceData: ResourceAdd; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': apiKey
        },
        body: JSON.stringify(resourceData),
    };
    const response = await fetchWithFallback('/resources/', options);
    return response.json();
}

export async function updateGlobeCluster({ data, apiKey }: { data: any; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': apiKey
        },
        body: JSON.stringify(data),
    };
    const response = await fetchWithFallback(`/globe/clusters`, options);
    return response.json();
}

/***********************************************************************************************
                                        DELETE
***********************************************************************************************/

export async function deleteGlobePoints({ pointIds, apiKey }: { pointIds: number[]; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'DELETE',
        headers: {
            'API-Key': apiKey
        },
    };
    const response = await fetchWithFallback(`/globe/points/${pointIds}`, options);
    return response.json();
}

export async function deleteGlobeGroup({ groupId, apiKey }: { groupId: number; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'DELETE',
        headers: {
            'API-Key': apiKey
        },
    };
    const response = await fetchWithFallback(`/globe/groups/${groupId}`, options);
    return response.json();
}

export async function deleteGlobeType({ typeId, apiKey }: { typeId: number; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'DELETE',
        headers: {
            'API-Key': apiKey
        },
    };
    const response = await fetchWithFallback(`/globe/types/${typeId}`, options);
    return response.json();
}

export async function deleteResources({ resourceIds, apiKey }: { resourceIds: number[]; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'DELETE',
        headers: {
            'API-Key': apiKey
        },
    };

    const idsString = resourceIds.join(',');

    const response = await fetchWithFallback(`/resources/${idsString}`, options);
    return response.json();
}

export async function deleteGlobeCluster({ clusterIds, apiKey }: { clusterIds: number[]; apiKey: string }): Promise<any> {
    const options: RequestInit = {
        method: 'DELETE',
        headers: {
            'API-Key': apiKey
        },
    };

    const idsString = clusterIds.join(',');

    const response = await fetchWithFallback(`/globe/clusters/${idsString}`, options);
    return response.json();
}


