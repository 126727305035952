import { globeGroupColumns } from '../../common/constants';
import useScript from '../../hooks/useScript';
import { useGetGlobeGroups } from '../../hooks/globeHooks';
import LogoLoading from '../animations/LogoLoading';
import Table from './Table';

const GlobeGroupTable = () => {
    const googleMapsScriptSrc = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCU4J23OYV1Y2FervUwR-TUzAnBLHrrYPs&libraries=places&callback=initMap"
    useScript(googleMapsScriptSrc, "initMap");

    const { data: globeGroup, isLoading, error } = useGetGlobeGroups();

    if (isLoading) {
        return <LogoLoading />;
    }

    return (
        <>
            <Table
                name="Globe Groups"
                columns={globeGroupColumns}
                data={globeGroup.data}
                isLoading={isLoading}
                error={error}
                keyField="groupId"
                nameField="groupName"
            />
        </>
    );
};

export default GlobeGroupTable;