import Profile from '../../components/Projects/Profile';
import RJ from "../../assets/images/rj-profile.webp";
import RJBanner from "../../assets/images/rj-banner.webp";
import SecureHub from "../../assets/images/secure_hub.webp";
import WhatIsIt from '../../components/Projects/WhatIsIt';
import Diagram from '../../assets/images/secure_diagram.webp';
import Background from '../../assets/images/secure-bg.webp';
import ParallaxHeader from '../../components/ParallaxHeader';
import FactGrid from '../../components/FactGrid';
import Cross from '../../assets/svgs/cross.svg';
import Cogs from '../../assets/svgs/cogs.svg';
import Speed from '../../assets/svgs/speed.svg';
import SectionSlant from '../../components/SectionSlant';
import Report from '../../assets/images/secure-cover.webp';
import Hands from '../../assets/svgs/helping-hands.svg';
import RegularFade from '../../components/animations/RegularFade';
import RibbonCard from '../../components/RibbonCard';
import Vision from "../../assets/images/vision_formula.png";
import Mission from "../../assets/images/mission_formula.png";
import Puzzle from "../../assets/svgs/extension-puzzle-outline.svg";

const facts = [
    { fact: "Enhance public-private partnerships between industry, academia, and government.", imageSrc: Hands },
    { fact: "Eliminate barriers to entry and engagement for small businesses, entrepreneurs, and startups in the Federal defense ecosystem.", imageSrc: Cross },
    { fact: "Advance innovative and disruptive technologies by providing a platform to incubate, accelerate, and decrease ‘time to market’ for technologies that support our the Federal Government’s mission.", imageSrc: Cogs },
    { fact: "Support rapid innovation and small business development through targeted programming, workforce training and utilization of the shared infrastructure.", imageSrc: Speed },
];

const SecureSpaceCampusPage: React.FC = () => {
    return (
        <div className="z-50 flex flex-col mb-32">
            <ParallaxHeader
                title="Secure Space Campus"
                description='A Secure Space for Cybersecurity Research'
                backgroundImage={Background}
                className="!bg-bottom"
            />
            <div className="container z-20 mt-16 p-0">
                <RibbonCard
                    title="Problem Statement"
                    subtitle="While the region’s share of federally funded, unclassified cybersecurity research has been woefully limited, the
                    percentage of classified cybersecurity research dollars is even smaller. This is due, in large part, to the fact that most
                    of the region’s enterprises and academic institutions do not have access to a commercial Sensitive Compartmented
                    Information Facility (SCIF). To conduct more classified work, including classified research, a SCIF is mandatory."
                    ribbon="Problem Statement"
                    image={Puzzle}
                />
            </div>
            <WhatIsIt
                tagline="Research Hub"
                title='What Is It?'
                subtitle="This project is to determine the feasibility for a state-of-the-art research hub that includes commercially available secure space to enhance and advance cybersecurity research, education, and National Security. The concept for the research hub is that it would serve as an intellectual and operational catalyst for increased federally funded cybersecurity research, innovation and commercialization and will bring together government, industry, and academic partners. This project will examine the market need, identify potential partners, and look at different business models in support of the campus."
                image={SecureHub}
                side="left"
            />
            <WhatIsIt
                tagline="Cybersecurity Training"
                title="Why It's Important"
                subtitle={<p>Innovation is the catalyst for economic growth. In 2019, the U.S. spent more than $580 billion on research and development — more than any other country in the world and is ranked sixth on the Global Innovation Index. Most experts agree that the key drivers to innovation growth in a region center on investments in research, education, business creation and business density. Neither Kansas nor Missouri have kept pace in terms of innovation relative to other states, ranking in the bottom half at 37th and 35th respectively based on a recent study by WalletHub.<br /><br />While the region’s share of federally funded, unclassified cybersecurity research has been woefully limited, the percentage of classified cybersecurity research dollars is even smaller. This is due, in large part, to the fact that most of the region’s enterprises and academic institutions do not have access to a commercial Sensitive Compartmented Information Facility (SCIF). To conduct more classified work, including classified research, a SCIF is mandatory.</p>}
                side="right"
                image={Diagram}
            />
            <SectionSlant
                tagline=""
                title="Secure Space Campus"
                subtitle="Dive deeper into the details."
                image={Report}
                topColor='from-white'
                bottomColor='to-white'
                buttonText="Download the Narrative"
            />
            <FactGrid
                title="For the Region"
                subtitle="The Secure Space Campus will provide the region with a collaborative ecosystem where government, industry, small business, workforce training, entrepreneurs, startups, and venture capital intersect to create community, spark innovation, and stimulate business growth. EnterpriseKC believes that the results produced by a Secure Space Campus will have a direct impact on creating new companies and filling high-value, high-opportunity cybersecurity jobs. In addition, the creation of a Secure Space Campus provides the following benefits to the region:"
                facts={facts}
                className="bg-white !text-black !pb-8"
            />
            <div className="container z-20 mb-32 p-0">
                <div className='flex flex-col justify-start items-start gap-20'>
                    <RibbonCard
                        title="Mission"
                        subtitle="To provide a secure and innovative environment where individuals can research, collaborate, and innovate to develop best-of-breed solutions. The hub will provide lab space for classified and unclassified research, real-world cyber-attack simulation and cyber training exercises. It will also include office and auditorium space for industry, education, and government partners to exchange product and research ideas, perform cybersecurity data analysis and enhance student learning. The hub will also serve as a resource for federal, state, and local law enforcement to reduce the impact and influence of cybercrime and domestic cyber terrorist activities."
                        ribbon="Mission"
                        image={Mission}
                    />
                    <RibbonCard
                        title="Vision"
                        subtitle="Imagine a cutting-edge research, collaboration, and commercialization hub between government, industry, and academia to conduct classified and unclassified research to substantially increase federally and non-federally funded projects awarded to the region."
                        ribbon="Vision"
                        image={Vision}
                    />
                </div>
            </div>
            <Profile
                name="RJ Pericola"
                role="Pillar Leader"
                imageUrl={RJ}
                linkedinUser="rjpericola"
                email="rpericola@enterprise-kc.com"
                phone="913-728-0133"
                bannerUrl={RJBanner}
            />
        </div>
    )
}

export default RegularFade(SecureSpaceCampusPage);