import ContentLayout from '../../components/dashboard/ContentLayout';

const DashboardPage = () => {
    return (
        <div className='bg-white h-screen'>
            <ContentLayout>
                <>
                    <h1 className="text-3xl font-semibold text-gray-800 dark:text-white">Dashboard</h1>
                    <p className="mt-4 text-gray-600 dark:text-gray-400">Welcome to the dashboard. Here you can view important information about your business.</p>
                </>
            </ContentLayout>
        </div>
    )
};

export default DashboardPage;