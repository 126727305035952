import useTable from '../../hooks/useTable';
import { useDeleteGlobeGroup, useDeleteGlobePoints, useDeleteGlobeType, useDeleteGlobeCluster } from '../../hooks/globeHooks';
import { useDeleteResources } from '../../hooks/resourceHooks';

const ModalDelete = () => {
    const { selectedRowId, handleModalDeleteToggle, openSnackbar, tableName } = useTable();
    const deleteGlobePoints = useDeleteGlobePoints();
    const deleteGlobeGroup = useDeleteGlobeGroup();
    const deleteGlobeType = useDeleteGlobeType();
    const deleteResource = useDeleteResources();
    const deleteGlobeCluster = useDeleteGlobeCluster();

    const handleDelete = () => {

        if (selectedRowId !== null) {
            const apiKey = 'ZcgMq-tTnVqXkf97lYSEfdQpxJ_FuMO4QuDUT9vB0Vc';

            if (tableName === "Globe Clusters") {
                deleteGlobeCluster.mutate({
                    clusterIds: [selectedRowId],
                    apiKey: apiKey
                }, {
                    onSuccess: () => {
                        handleModalDeleteToggle(null);
                        openSnackbar('Globe cluster deleted successfully');
                    },
                    onError: (error) => {
                        openSnackbar('Error deleting globe cluster');
                    }
                });
            }

            else if (tableName === 'Globe Points') {
                deleteGlobePoints.mutate({
                    pointIds: [selectedRowId],
                    apiKey: apiKey
                }, {
                    onSuccess: () => {
                        handleModalDeleteToggle(null);
                        openSnackbar('Globe point deleted successfully');
                    },
                    onError: (error) => {
                        openSnackbar('Error deleting globe point');
                    }
                });
            }
            else if (tableName === 'Globe Groups') {
                deleteGlobeGroup.mutate({
                    groupId: selectedRowId,
                    apiKey: apiKey
                }, {
                    onSuccess: () => {
                        handleModalDeleteToggle(null);
                        openSnackbar('Globe group deleted successfully');
                    },
                    onError: (error) => {
                        openSnackbar('Error deleting globe group, please delete all points in the group first');
                    }
                });
            }
            else if (tableName === "Globe Types") {
                deleteGlobeType.mutate({
                    typeId: selectedRowId,
                    apiKey: apiKey
                }, {
                    onSuccess: () => {
                        handleModalDeleteToggle(null);
                        openSnackbar('Globe type deleted successfully');
                    },
                    onError: (error) => {
                        openSnackbar('Error deleting globe type');
                    }
                });
            }
            else if (tableName === "Resources") {
                deleteResource.mutate({
                    resourceIds: [selectedRowId],
                    apiKey: apiKey
                }, {
                    onSuccess: () => {
                        handleModalDeleteToggle(null);
                        openSnackbar('Resource deleted successfully');
                    },
                    onError: (error) => {
                        openSnackbar('Error deleting resource');
                    }
                });
            }

        }
    };

    return (
        <>
            <div id="delete-modal" tabIndex={-1} className="fixed inset-0 z-50 p-4 overflow-x-hidden overflow-y-auto flex items-center justify-center">
                <div className="relative w-full max-w-md h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button type="button" onClick={() => handleModalDeleteToggle(null)} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="delete-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-6 text-center">
                            <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this item?</h3>
                            <button onClick={handleDelete} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">Yes, I'm sure</button>
                            <button onClick={() => handleModalDeleteToggle(null)} className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalDelete;