import React, { useContext, useState } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../assets/svgs/EKC_blue.svg";

const RegisterPage = () => {
    const [userInfo, setUserInfo] = useState({ username: '', password: '', adminPassword: '' });
    const { register, error } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await register(userInfo).then(() => {
            navigate('/dashboard', { replace: true });
        }).catch(() => {
            console.log('Registration Error');
        });
    };

    return (
        <div className="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8 bg-white font-jakarta">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img className="mx-auto h-10 w-auto" src={Logo} alt="Your Company" />
                {error && <p className="text-center text-red-500">{error}</p>}
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md shadow p-16">
                <h2 className="mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-custom-black">Register for an account</h2>
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-custom-black">Username</label>
                        <div className="mt-2">
                            <input
                                id="username"
                                name="username"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-custom-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ekc-blue sm:text-sm sm:leading-6"
                                value={userInfo.username}
                                onChange={(e) => setUserInfo({ ...userInfo, username: e.target.value })}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-custom-black">Password</label>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-custom-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ekc-blue sm:text-sm sm:leading-6"
                                value={userInfo.password}
                                onChange={(e) => setUserInfo({ ...userInfo, password: e.target.value })}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="adminPassword" className="block text-sm font-medium leading-6 text-custom-black">Admin Password</label>
                        <div className="mt-2">
                            <input
                                id="adminPassword"
                                name="adminPassword"
                                type="password"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-custom-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ekc-blue sm:text-sm sm:leading-6"
                                value={userInfo.adminPassword}
                                onChange={(e) => setUserInfo({ ...userInfo, adminPassword: e.target.value })}
                            />
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="flex w-full justify-center rounded-md bg-ekc-blue hover:bg-opacity-75 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ekc-blue">Register</button>
                    </div>

                    <div>
                        <p className="text-sm text-center text-gray-600">Already have an account? <Link to="/admin/" className="text-ekc-blue hover:text-opacity-75">Login</Link></p>
                    </div>
                </form>
            </div>
        </div>
    );

};

export default RegisterPage;
