import RegularFade from './../components/animations/RegularFade';
import TopView from '../components/approach/TopView';
import FrontView from '../components/approach/FrontView';
import Pillars from '../components/approach/Pillars';
import Report from '../assets/images/cylinder-narrative-cover.webp';
import ClusterPaper from '../components/clusters/ClusterPaper';
import EnterpriseFocus from '../components/approach/EnterpriseFocus';
import Imagine from '../components/approach/Imagine';
import VisionToValue from '../components/approach/VisionToValue';
import Principles from '../components/approach/Principles';
import ParallaxHeader from '../components/ParallaxHeader';
import BackgroundImage from '../assets/images/approach_background.webp';
import CylinderModel from '../components/approach/CylinderModel';

const OurApproachPage: React.FC = () => {
    return (
        <div>
            <div className="z-50 flex flex-col">
                <ParallaxHeader
                    title="Our Approach"
                    description='A scalable, repeatable, and measurable system for regional economic growth and a bridge to prosperity'
                    backgroundImage={BackgroundImage}
                    className="!bg-bottom"
                />
                <Imagine />
                <EnterpriseFocus
                    tagline="Accelerating Regional Prosperity through Enterprise Growth"
                    title="Focus on the Enterprise"
                />
                <VisionToValue />
                <CylinderModel />
                <FrontView />
                <TopView
                    title="The Top"
                    subtitle="The top view of the Cylinder identifies a series of eight (8) strategies or 'pillars' for any given cluster as well as a governance structure to support growth strategies across all clusters. The Pillars make up the critical elements necessary for sustained enterprise growth and regional prosperity.​"
                />
                <Pillars />
                <Principles />
                <ClusterPaper
                    className='bg-white'
                    title="In-Depth Analysis Report"
                    subtitle=" To learn more about EnterpriseKC’s approach to accelerated economic development and high impact, high opportunity job growth, click the button below to download The Cylinder Narrative: An Enterprising Approach to Economic Development, Innovation, and Regional Prosperity."
                    imageUrl={Report}
                    buttonText='Download the Narrative'
                    reportName='The Cylinder Model Narrative'
                />
            </div>
        </div>
    );
}

export default RegularFade(OurApproachPage);