// In Digital Health.tsx
import React from 'react';

import DescriptionImage from '../../assets/svgs/digital-health-logo.svg';
import BottomLogo from '../../assets/svgs/pptx_digitalhealth_logo.svg';
import Background from '../../assets/images/digital-health-bg.webp';
import ClusterContact from '../../components/clusters/ClusterContact';
import ClusterDescription from '../../components/clusters/ClusterDescription';
import ClusterContribute from '../../components/clusters/ClusterContribute';
import ClusterHeader from '../../components/clusters/ClusterHeader';
import ClusterDYK from '../../components/clusters/ClusterDYK';
import DYKLogo1 from '../../assets/svgs/holding-us-dollar.svg';
import DYKLogo2 from '../../assets/svgs/health-cross.svg';
import DYKLogo3 from '../../assets/svgs/money-bill.svg';
import DYKLogo4 from '../../assets/svgs/heart.svg';
import RegularFade from '../../components/animations/RegularFade';
import { statsDigitalHealth } from '../../common/constants';
import { clusterStatId } from '../../common/constants';

const facts = [
    {
        fact: "Cerner, prior to acquisition, created an economic impact of more than $1.3 billion annually employing over 13,000 Associates. The Cerner ecosystem spawned multiple Digital Health enterprises including Netsmart and BlueSky.",
        imageSrc: DYKLogo1,
    },
    {
        fact: "KU is one of only 26 universities in the US to be recognized with national designation by the National Institutes of Health for its cancer center, Alzheimer’s disease center and clinical and translational science institute.",
        imageSrc: DYKLogo2,
    },
    {
        fact: "The Digital Health market size exceeds $141 billion and is expected to grow at a CAGR of 17.4% through 2027.",
        imageSrc: DYKLogo3,
    },
    {
        fact: "Digital Health KC is a community initiative that operates as the focal point of the Digital Health cluster and unites over 100 companies for the purpose of solving healthcare’s biggest challenges.",
        imageSrc: DYKLogo4,
    }
]

const DigitalHealthPage: React.FC = () => {
    return (
        <div>
            <ClusterHeader
                name="Digital Health"
                motto="Securing The Digital Frontier"
                backgroundImage={Background}
                industry="digital-health"
            />
            <ClusterDescription
                title="Digital Health"
                subtitle="The Digital Health Cluster is made up of companies that earn most or all of their revenue from the treatment of patients with curative, preventive, rehabilitative, and palliative care, including hospitals, medical and dental practices, mobile health, wearable devices, telemedicine, personalized medicine, pharmacies, rehabilitative services and health information technology."
                imageUrl={DescriptionImage}
                stats={statsDigitalHealth}
                clusterId={clusterStatId.DIGITAL_HEALTH}
            />
            <ClusterDYK
                facts={facts}
            />
            <ClusterContribute />
            <ClusterContact
                icon={BottomLogo}
                name="Digital Health"
            />
        </div>
    );
};

export default RegularFade(DigitalHealthPage);
