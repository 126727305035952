import ParallaxHeader from "../../components/ParallaxHeader";
import BackgroundImage from "../../assets/images/cyberrange-background.webp";
import WhatIsIt from "../../components/Projects/WhatIsIt";
import TalentImaginePic from "../../assets/images/cyberrange_diagram.webp";
import CyberRangeImage from '../../assets/images/Cybersecurity-icon.webp';
import Profile from "../../components/Projects/Profile";
import RJ from "../../assets/images/rj-profile.webp";
import RJBanner from "../../assets/images/rj-banner.webp";
import CyberRangeTimeline from "../../components/Projects/CyberRangeTimeline";
import Users from "../../assets/svgs/users.svg";
import FactGrid from "../../components/FactGrid";
import Idea from '../../assets/svgs/lightbulb.svg';
import Rocket from '../../assets/svgs/rocket.svg';
import Shield from "../../assets/svgs/shield.svg";
import Cogs from "../../assets/svgs/cogs.svg";
import SectionSlant from '../../components/SectionSlant';
import Report from '../../assets/images/cyber-range-report-cover.webp';
import LayeredPanel from "../../components/LayeredPanel";
import CyberRangeSite from "../../assets/images/cyber-range-site.webp";
import { useNavigate } from "react-router-dom";
import { BiGlobe } from "react-icons/bi";
import Modules from "../../assets/images/cyberramge-modules.webp";
import ToolShowcase from '../../components/Projects/ToolShowcase';
import ToolDefinition from '../../components/Projects/ToolDefinition';
import Courses from '../../assets/images/cyberrange-courses.webp';
import RegularFade from '../../components/animations/RegularFade';
import RibbonCard from '../../components/RibbonCard';
import Vision from "../../assets/images/vision_formula.png";
import Mission from "../../assets/images/mission_formula.png";
import Puzzle from "../../assets/svgs/extension-puzzle-outline.svg";
import chuck from "../../assets/images/chuck.webp";
import chuckBanner from "../../assets/images/chuckBanner.webp";

const facts = [
    { fact: "Offers a diverse, inclusive, and equitable experience for anyone with an internet connection, creating a new pipeline of opportunity in rural, urban, and underserved communities.", imageSrc: Users },
    { fact: "Simulates on-the-job, real-world experience, so students are workforce ready.", imageSrc: Cogs },
    { fact: "Provide performance-based learning, assessment, and real-time feedback to speed the learning process and ensure competency.", imageSrc: Rocket },
    { fact: "Provide a resource for companies in all clusters to test their defense postures against the latest cyber threats.", imageSrc: Shield },
    { fact: "Increases awareness and reduces risk of cyber-attacks for all citizens across the region.", imageSrc: Idea },
];

const CyberRangePage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="z-50 flex flex-col mb-32">
            <ParallaxHeader
                title="Heartland Cyber Range"
                description='A virtual training ground for cybersecurity'
                backgroundImage={BackgroundImage}
                className="!bg-bottom"
            />
            <div className="container z-20 mt-16 p-0">
                <RibbonCard
                    title="Problem Statement"
                    subtitle="The risks we face from cyber-attacks continue to intensify, and no one is
                    immune. The cybersecurity threat landscape touches every industry, every
                    corner of the region, and, as a result, every resident in the region.
                    Because in a war of 1s and 0s, anything that is controlled digitally is a
                    potential target – our power grid, our water supplies, our financial systems,
                    our food supplies, our factories, our healthcare systems, our homes, the
                    cars we drive, and the cars that will eventually drive us."
                    ribbon="Problem Statement"
                    image={Puzzle}
                />
            </div>
            <WhatIsIt
                tagline="A Virtual Training Ground"
                title='What Is It?'
                subtitle="The Heartland Cyber Range is a virtual, interactive environment designed to simulate real-world networks, systems, and applications for cybersecurity education, cyber defense training, and cyber technology research and development. The Heartland Cyber Range provides a safe, secure environment where students, researchers, industry professionals, and government can strengthen theoretical learning alongside practical training on the latest threats and tactics to be better prepared for the realities of defending a network or against an actual attack. The Heartland Cyber Range will also be used to train and certify cybersecurity professionals."
                image={TalentImaginePic}
                side="left"
            />
            <WhatIsIt
                tagline="Cybersecurity Training"
                title="Why It's Important"
                subtitle={<p>The risks we face from cyber-attacks continue to intensify, and no one is immune. The cybersecurity threat landscape touches every industry, every corner of the region, and, as a result, every resident in the region. More troubling is the fact that this country and this region faces a severe shortage of talented workers to combat the threat. Over 750,000 open cybersecurity jobs remain unfilled nationally. Kansas has over 8,500 open cybersecurity jobs across the state, and when we include all of Kansas together with Western Missouri, the number of open cybersecurity jobs jumps to more than 14,000. One-third of those cybersecurity jobs do not require a 2-year or 4-year degree.<br /><br />In addition, lack of real-world work experience is the number one challenge identified by industry when hiring new cybersecurity graduates across all education pathways. Graduates – even those holding cybersecurity certificates may have the theory down, but actually putting it into practice is something else entirely.</p>}
                side="right"
                image={CyberRangeImage}
            />
            <ToolDefinition
                tagline="Learn and Engage"
                title="Modules"
                subtitle="Users can access a variety of modules to learn about the Heartland Cyber Range and its capabilities. These modules are designed to provide a comprehensive overview of the Heartland Cyber Range and its benefits. Users can also learn about the different features and tools available on the Heartland Cyber Range."
                image={Modules}
            />
            <ToolShowcase
                title="Courses"
                subtitle="The Heartland Cyber Range offers a variety of courses for users to learn about cybersecurity. These courses are designed to provide users with a comprehensive understanding of cybersecurity concepts and principles. Users can access these courses to gain valuable knowledge and skills in the field of cybersecurity."
                image={Courses}
            />
            <LayeredPanel
                title="Heartland Cyber Range"
                subtitle="Learn more about the Heartland Cyber Range."
                buttonText="Visit Site"
                onButtonClick={() => navigate('/defend-tomorrow')}
                buttonIcon={<BiGlobe className='text-lg fill-ekc-green' />}
                image={CyberRangeSite}
                className="bg-white"
            />
            <SectionSlant
                tagline=""
                title="Heartland Cyber Range"
                subtitle="Dive deeper into the details."
                image={Report}
                topColor='from-white'
                bottomColor='to-white'
                buttonText="Download the Narrative"
            />
            <FactGrid
                title="For the Region"
                subtitle="The Heartland Cyber Range will have a direct impact on filling high-value, high-opportunity cybersecurity jobs and will help fortify cybersecurity awareness and defenses to better protect our region’s citizens, enterprises, and critical infrastructure. In addition, the creation of a cyber range provides the following benefits to the region:"
                facts={facts}
                className="bg-white !text-black !pb-8"
            />
            <div className="container z-20 mb-32 p-0">
                <div className='flex flex-col justify-start items-start gap-20'>
                    <RibbonCard
                        title="Mission"
                        subtitle="to provide real-world, hand-on cybersecurity experiences to create the most agile, educated, and experientially trained cybersecurity workforce in the country and to provide a configurable environment to simulate real-world cybersecurity threats to test defense strategies and conduct research and testing on new solutions to mitigate the risk of cyber-attacks. The Heartland Cyber Range will allow for multiple user groups to not only gain access to up-to-date curriculum and theory, but also to practice in a simulated real-world environment"
                        ribbon="Mission"
                        image={Mission}
                    />
                    <RibbonCard
                        title="Vision"
                        subtitle="Imagine a regional interactive learning and assessment environment for cybersecurity education and real-world job experience that serves to create the most agile, educated, and experientially trained cybersecurity workforce in the country. Imagine a regional threat simulation environment for cybersecurity research, new product development and product testing that help fortify our cybersecurity defenses so we can better protect our region’s citizens, enterprises, and critical infrastructure."
                        ribbon="Vision"
                        image={Vision}
                    />
                </div>
            </div>
            <CyberRangeTimeline />
            <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
                <Profile
                    name="RJ Pericola"
                    role="Pillar Leader"
                    imageUrl={RJ}
                    linkedinUser="rjpericola"
                    email="rpericola@enterprise-kc.com"
                    phone="913-728-0133"
                    bannerUrl={RJBanner}
                />
                {/* Profile for Chuck Gardner, role "Director", email cgardner@enterprise-kc.com */}
                <Profile
                    name="Chuck Gardner"
                    role="Director"
                    imageUrl={chuck}
                    linkedinUser="coastalchuck"
                    email="cgardner@enterprise-kc.com"
                    phone="504-952-2195"
                    bannerUrl={chuckBanner}
                />
            </div>
        </div>
    )
}

export default RegularFade(CyberRangePage);