import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import SimpleButton from '../components/SimpleButton';
import { ReactComponent as RightArrow } from '../assets/svgs/rightArrow.svg';

import '../styles/swiper.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface HeroHeaderProps {
    id: string;
    title: string | string[];
    subtitle: string;
    images: string[];
    className?: string;
    buttonText?: string;
    opacity: string;
    leftTitle?: string;
    leftSubtitle?: string;
}

const HeroHeader: React.FC<HeroHeaderProps> = ({ id, title, subtitle, images, className = "", opacity, leftSubtitle = "", leftTitle = "", buttonText }) => {
    const renderTitle = (title: string | string[]) => {
        if (Array.isArray(title)) {
            return title.map((line, index) => (
                <div key={index} className={`${index > 0 ? '3xl:whitespace-nowrap' : ''} text-4xl md:text-5xl lg:text-7xl text-white font-semibold font-jakarta text-center`}>
                    {line}
                </div>
            ));
        } else {
            return <h1 className="text-4xl md:text-5xl lg:text-7xl text-white font-jakarta">{title}</h1>;
        }
    };
    const renderButton = () => {
        if (buttonText) {
            return (
                <SimpleButton
                    size="large"
                    label={buttonText}
                    textColor='text-white'
                    bgColor="bg-gradient-[105deg] from-custom-blue from-40% to-custom-green to-130%"
                    Icon={RightArrow}
                    onClick={() => {
                        const element = document.getElementById("Sign Up");
                        element?.scrollIntoView({ behavior: 'smooth' });
                    }}
                />
            );
        }
        return null;
    };
    return (
        <div id={id} className="w-full shadow-md lg:mx-auto text-center relative font-jakarta rounded-lg">
            <Swiper
                pagination={{
                    clickable: true,
                }}
                className={className}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false
                }}
                speed={1000}
                loop={true}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image} alt={`Slide ${index}`} className='w-full h-full object-cover object-top' />
                        <div className={`absolute inset-0 ${opacity} bg-black`} />
                        <div className="absolute inset-0 flex flex-col justify-center items-center text-center mx-auto px-4 md:px-0 gap-10">
                            <h1>
                                {renderTitle(title)}
                            </h1>
                            <p className="max-w-3xl text-gray-300 text-lg">{subtitle}</p>
                            {renderButton()}
                        </div>
                        <div className="absolute bottom-0 left-0 p-8 hidden sm:block">
                            <h2 className="text-left md:text-4xl text-white font-bold">{leftTitle}</h2>
                            <p className="text-left text-sm md:text-2xl text-gray-400 font-bold">{leftSubtitle}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div >
    );
};

export default HeroHeader;