import React from 'react';
import { motion, useMotionTemplate, useMotionValue, MotionValue } from "framer-motion";
import { GridPattern } from './GridPattern';
import Subtitle from './typography/Subtitle';
import FadeUp from './animations/FadeUp';

interface RibbonCardProps {
    title: string;
    subtitle: string | JSX.Element;
    ribbon: string;
    image: string;
}

function Effect({ mouseX, mouseY, ...gridProps }: { mouseX: MotionValue<number>, mouseY: MotionValue<number>, [key: string]: any }) {
    let maskImage = useMotionTemplate`radial-gradient(300px at ${mouseX}px ${mouseY}px, white, transparent)`;
    let style = { maskImage, WebkitMaskImage: maskImage };

    return (
        <div className="pointer-events-none">
            <div className="pointer-events-none">
                <div className="absolute inset-0 rounded-lg transition duration-300 [mask-image:linear-gradient(white,transparent)] group-hover:opacity-100">
                    <GridPattern
                        width={72}
                        height={56}
                        x="50%"
                        y={gridProps.y}
                        className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/[0.02] stroke-black/5"
                        squares={gridProps.squares}
                    />
                </div>
                <motion.div
                    className="absolute inset-0 rounded-lg bg-gradient-to-r from-[#D7EDEA] to-[#F4FBDF] opacity-0 transition duration-300 group-hover:opacity-100"
                    style={style}
                />
                <motion.div
                    className="absolute inset-0 rounded-lg opacity-0 mix-blend-overlay transition duration-300 group-hover:opacity-100"
                    style={style}
                >
                    <GridPattern
                        width={72}
                        height={56}
                        x="50%"
                        y={gridProps.y}
                        className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/[0.02] stroke-black/5"
                        squares={gridProps.squares}
                    />
                </motion.div>
            </div>
        </div>
    );
}

const RibbonCard: React.FC<RibbonCardProps> = ({ title, ribbon, subtitle, image }) => {
    let mouseX = useMotionValue(0);
    let mouseY = useMotionValue(0);
    const pattern = {
        y: 16,
        squares: [
            [-1, 1],
            [0, 3],
            [1, 5],
        ],
    };

    function onMouseMove({ currentTarget, clientX, clientY }: React.MouseEvent<HTMLDivElement>) {
        let { left, top } = currentTarget.getBoundingClientRect();
        mouseX.set(clientX - left);
        mouseY.set(clientY - top);
    }

    return (
        <FadeUp>
            <div
                onMouseMove={onMouseMove}
                className='group flex flex-col md:flex-row items-center bg-white border border-gray-200 px-10 py-14 rounded-xl font-jakarta relative mx-8'
            >
                <Effect {...pattern} mouseX={mouseX} mouseY={mouseY} />
                <div className="p-4 h-24 w-24 flex items-center justify-center mr-8">
                    <img src={image} alt="Mission Formula" className="relative" />
                </div>
                <div className="flex-1">
                    <Subtitle className="max-w-none font-medium relative">{subtitle}</Subtitle>
                    <div className="-z-10 absolute top-8 left-[-0.9rem] ekc-image-gradient h-7 w-7 transform rotate-45"></div>
                    <div className="z-20 absolute top-[.36rem] left-[-1.23rem] p-2 inline-block ekc-image-gradient text-white text-center shadow-lg rounded-r-md rounded-tl-md uppercase font-bold whitespace-nowrap">
                        {ribbon}
                    </div>
                </div>
            </div>
        </FadeUp>
    );
}

export default RibbonCard;
