import React from 'react';
import FadeUp from '../animations/FadeUp';
import Subtitle from '../typography/Subtitle';
import InnerTop from "../../assets/images/TopCylinderCenterCircle.webp"
import ClusterImage from '../../assets/images/TopCylinderModelNewNoCenter.webp';

interface TopViewProps {
    title: string;
    subtitle: React.ReactNode;

}

const TopView: React.FC<TopViewProps> = ({ title, subtitle, }) => {
    return (
        <div className="container text-black font-jakarta bg-white">
            <div className="mx-auto px-4 py-16 lg:py-32">
                <FadeUp>
                    <div className="flex flex-col lg:flex-row justify-center items-center gap-12 z-20">
                        <div className="lg:w-1/2 relative">
                            <Subtitle>{subtitle}</Subtitle>
                            <div className='text-[4rem] sm:text-[7rem] 2xl:text-[10rem] uppercase font-extrabold text-black opacity-5 absolute z-10 -left-4 2xl:-left-36 -top-[5.5rem] sm:-top-[6rem] 2xl:-top-[9.5rem] whitespace-nowrap'>
                                Top View
                            </div>
                        </div>
                        <div className="lg:w-1/2 relative">
                            <img src={InnerTop} alt="Cluster" className={`absolute max-w-1/2 w-auto max-h-[40rem] mx-auto rounded-lg z-10`} />
                            <img src={ClusterImage} alt="Cluster" className={`max-w-1/2 w-auto max-h-[40rem] mx-auto rounded-lg animate-slow-spin`} />
                        </div>
                    </div>
                </FadeUp>
            </div>
        </div>
    );
};

export default TopView;
