import React from 'react';

interface TitleProps {
    children: React.ReactNode;
    className?: string;
    variant?: 'h1' | 'h2'; // Add variant prop with 'h1' and 'h2' as options
}

const Title: React.FC<TitleProps> = ({ children, className, variant = 'h1' }) => {
    const fontSize = variant === 'h2' ? 'text-2xl lg:text-4xl' : 'text-3xl lg:text-5xl';
    return <h1 className={`font-jakarta font-semibold ${fontSize} max-w-2xl !leading-relaxed mb-4 ${className}`}>{children}</h1>;
};

export default Title;
