import React from 'react';

const TripleLine: React.FC = () => {
    return (
        <div className="flex space-x-1">
            <span className="block h-[5px] w-[35px] bg-ekc-light-green rounded-full"></span>
            <span className="block h-[5px] w-[35px] bg-ekc-green rounded-full"></span>
            <span className="block h-[5px] w-[35px] bg-ekc-blue rounded-full"></span>
        </div>
    );
};

export default TripleLine;
