import React from "react";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { motion } from 'framer-motion';
import useOnclickOutside from "react-cool-onclickoutside";

interface PlacesAutocompleteProps {
    className?: string;
    setNewLat: (lat: string) => void;
    setNewLng: (lng: string) => void;
}

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({ setNewLat, setNewLng, className }) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        callbackName: "YOUR_CALLBACK_NAME",
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 800,
        cache: 24 * 60 * 60,
    });

    const dropdownVariants = {
        open: { opacity: 1, scale: 1, transition: { staggerChildren: 0.1 } },
        closed: { opacity: 0, scale: 0.95 }
    };

    const itemVariants = {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: -10 }
    };

    const ref = useOnclickOutside(() => {
        clearSuggestions();
    });

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleSelect =
        ({ description }: { description: string }) =>
            () => {
                setValue(description, false);
                clearSuggestions();

                getGeocode({ address: description }).then((results) => {
                    const { lat, lng } = getLatLng(results[0]);
                    setNewLat(lat.toString());
                    setNewLng(lng.toString());
                });
            };

    const renderSuggestions = () => (
        <motion.ul
            initial="closed"
            animate="open"
            exit="closed"
            variants={dropdownVariants}
            className={`${className} max-w-lg absolute text-xs z-10 mt-1 rounded-lg shadow-lg overflow-hidden overflow-y-auto max-h-44 no-scrollbar`}
        >
            {data.map((suggestion) => {
                const {
                    place_id,
                    structured_formatting: { main_text, secondary_text },
                } = suggestion;

                return (
                    <motion.li
                        key={place_id}
                        onClick={handleSelect(suggestion)}
                        variants={itemVariants}
                        className="p-4 cursor-pointer hover:bg-gray-100"
                    >
                        <strong>{main_text}</strong> <small>{secondary_text}</small>
                    </motion.li>
                );
            })}
        </motion.ul>
    );
    return (
        <div ref={ref}>
            <input
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder="Address"
                className={className}
            />
            {status === "OK" && <ul>{renderSuggestions()}</ul>}
        </div>
    );
};

export default PlacesAutocomplete;