import React from 'react';
import websiteSS from '../../assets/images/laptop.webp';
import LayeredPanel from '../LayeredPanel';
import { IoMail } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const ClusterContribute: React.FC = () => {
    const navigate = useNavigate();
    const headerText = "Learn More?";
    const subText = "Contact us to learn more about our strategies and programs and how you can help shape the future of this Cluster.";
    const buttonText = "Contact Us";

    return (
        <LayeredPanel
            title={headerText}
            subtitle={subText}
            buttonText={buttonText}
            onButtonClick={() => navigate('/contact-us')}
            image={websiteSS}
            buttonIcon={<IoMail className="ekc-icon-gradient text-xl fill-ekc-green" />}
            className='!pt-0'
        />
    );
};

export default ClusterContribute;
