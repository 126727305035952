import { useEffect, useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
    const { logout, isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            logout();
        }
        navigate('/admin/login');
    }, [isLoggedIn, logout, navigate]);

    return null;
};

export default LogoutPage;
