import LogoLoading from "./animations/LogoLoading";

const LoadingScreen: React.FC = () => {
    return (
        <div className='fixed top-0 left-0 w-screen h-screen bg-custom-black-shade flex justify-center items-center'>
            <LogoLoading />
        </div>
    );
}

export default LoadingScreen;