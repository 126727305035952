import React from 'react';
import { motion } from 'framer-motion';

type Props = {
    onComplete: () => void;
    text?: string;
};

const Complete: React.FC<Props> = ({ onComplete, text = "" }) => {
    return (
        <div className="complete-screen">
            <svg viewBox="0 0 53.19 53.19">
                <g>
                    <motion.circle
                        stroke="#4fb748"
                        strokeWidth={3}
                        fill="transparent"
                        cx={26.59}
                        cy={26.59}
                        r={24}
                        initial={{
                            scale: 0,
                            opacity: 0,
                        }}
                        animate={{
                            scale: 1,
                            opacity: 1,
                            transition: {
                                duration: 0.5,
                            },
                        }}
                    />
                    <motion.path
                        stroke="#4fb748"
                        strokeWidth={3}
                        strokeLinecap="round"
                        fill="transparent"
                        d="M12.29 26.59l10.98 10.42 17.49-18.23"
                        initial={{
                            pathLength: 0,
                            opacity: 0,
                        }}
                        animate={{
                            pathLength: 1,
                            opacity: 1,
                            transition: {
                                duration: .5,
                                delay: 0.2,
                            },
                        }}
                        onAnimationComplete={onComplete}
                    />
                </g>
            </svg>

            <motion.div
                initial={{
                    y: 30,
                    opacity: 0,
                }}
                animate={{
                    y: 0,
                    opacity: 1,
                }}
                transition={{
                    duration: 0.5,
                    delay: 0.3,
                    repeatDelay: 1,
                }}
                className='text-xl mt-8'
            >
                {text}
            </motion.div>
        </div>
    );
};

export default Complete;