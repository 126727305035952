import { useGetGlobeTypes } from '../../hooks/globeHooks';
import Table from './Table';
import { globeTypeColumns } from '../../common/constants';
import LogoLoading from '../animations/LogoLoading';

const GlobeTypeTable = () => {
    const { data: globeTypes, isLoading, error } = useGetGlobeTypes();

    if (isLoading) {
        return <LogoLoading />;
    }

    return (
        <>
            <Table
                name="Globe Types"
                columns={globeTypeColumns}
                data={globeTypes.data}
                isLoading={isLoading}
                error={error}
                keyField="typeId"
                nameField="typeName"
            />
        </>
    );
};

export default GlobeTypeTable;