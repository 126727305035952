import React from 'react';
import HeroHeader from '../components/HeroHeader';
import GoogleOne from '../assets/images/google_grow1.webp';
import GoogleTwo from '../assets/images/google_grow2.webp';
import GoogleThree from '../assets/images/google_grow3.webp';
import GoogleFour from '../assets/images/google_grow4.webp';
import Title from '../components/typography/Title';
import Subtitle from '../components/typography/Subtitle';
import SimpleButton from '../components/SimpleButton';
import { useNavbarTransparency } from '../hooks/useNavbarTransparency';
import RegularFade from './../components/animations/RegularFade';

const GrowWithGooglePage: React.FC = () => {
    useNavbarTransparency(false);

    return (
        <div className='bg-white font-jakarta mt-24'>
            <div className='container py-12 sm:py-24 flex flex-col items-center justify-center'>
                <HeroHeader
                    id="Grow With Google"
                    title=""
                    subtitle=""
                    images={[GoogleOne, GoogleTwo, GoogleThree, GoogleFour]}
                    opacity="opacity-0"
                    className='max-h-[900px] rounded-lg'
                />
                <Title className="text-center !font-medium max-w-3xl mt-16 !mb-10">
                    Prepare for a career in Cybersecurity with a certificate from Google and EnterpriseKC!
                </Title>
                <Subtitle className='text-center max-w-3xl'>
                    EnterpriseKC, in partnership with Google, is pleased to announce the availability of full scholarships for the Google Cybersecurity Certificate. EnterpriseKC is dedicated to creating workforce-ready talent to address the 9,000+ open cybersecurity jobs in Kansas and Western Missouri. The Google Cybersecurity Certificate can put you on the fast track to a competitively paid job in the high-growth, high opportunity field of cybersecurity.
                </Subtitle>
                <a href='https://grow.google/certificates/cybersecurity/#?modal_active=none' target='_blank' rel='noreferrer'>
                    <SimpleButton
                        size="medium"
                        label="Learn more about what the program has to offer"
                        textColor="text-white font-medium"
                        bgColor="bg-[#1a73e8] hover:bg-[#1567d3] transition duration-200 mt-10 mb-16"
                        roundness=''
                        fullWidth={false}
                        growScale={1}
                    />
                </a>
                <Subtitle className='text-center max-w-3xl'>
                    No cybersecurity experience or a college degree is required to complete this flexible, online training. All you need is the desire, passion, and persistence you already possess to complete the training and begin your journey to a career in cybersecurity. The Google Cybersecurity Certificate helps prepare graduates for the <strong>CompTIA Security+ exam</strong>. Graduates of the Google Cybersecurity Certificate can access the CompTIA Security+ exam and additional training at a <strong>discounted price</strong>.
                </Subtitle>
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSeW1w9NGI2HffgLIY8K1PSIWE_Md_fv6jiLlswEPe-nawI1Gw/viewform' target='_blank' rel='noreferrer'>
                    <SimpleButton
                        size="medium"
                        label="Apply for the Certificate"
                        textColor="text-white font-medium"
                        bgColor="bg-[#1a73e8] hover:bg-[#1567d3] transition duration-200 mt-10 mb-24"
                        roundness=''
                        fullWidth={false}
                        growScale={1}
                    />
                </a>
                <div className='flex items-start justify-center lg:max-w-5xl flex-col lg:flex-row'>
                    <div className='lg:w-1/2 !text-left'>
                        <Title variant='h2' className="!font-medium max-w-3xl lg:!mb-6">
                            Scholarship Requirements
                        </Title>
                        <Subtitle className='max-w-md !mb-10'>
                            Start your journey into cybersecurity, earn a credential, and stand out to employers with a Google Cybersecurity Certificate today!
                        </Subtitle>
                    </div>
                    <ul className='leading-loose list-image-bullet space-y-6 lg:w-1/2'>
                        <li className='pl-3'>
                            Curiosity for Cybersecurity!
                        </li>
                        <li className='pl-3'>
                            Desire to advance the cyber ecosystem and infrastructure in Kansas and Western Missouri
                        </li>
                        <li className='pl-3'>
                            An introductory Career Connection Conversation with an EnterpriseKC Program Manager!
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default RegularFade(GrowWithGooglePage);