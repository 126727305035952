import React from 'react';
import RegularFade from './../components/animations/RegularFade';
import Background from '../assets/images/gray-image.webp';
import Operation287Image from '../assets/images/Kansas_and_Missouri_Color_Pins.webp';
import Triple from '../components/TripleLine';
import RedPin from '../assets/images/pin-red.webp';
import BluePin from '../assets/images/pin-blue.webp';

const Operation287: React.FC = () => {
    return (
        <div className="font-jakarta bg-custom-black">
            {/* Header Section */}
            <div
                className="relative overflow-hidden flex items-center justify-center h-[900px] bg-cover bg-center"
                style={{ backgroundImage: `url(${Background})` }}
            >
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-custom-black via-black/60"></div>
                <div className="relative z-10 text-center p-4 md:p-8 lg:p-12">
                    <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-medium mb-4">Operation 287</h1>
                    <div className='flex justify-center my-8'><Triple /></div>
                </div>
            </div>

            {/* Pin Description Section */}
            <div className="text-center text-white py-8">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
                    <div className="flex items-center">
                        <img src={RedPin} alt="Red Pin" className="w-8 h-10 mr-2" />
                        <span>Installed Machines</span>
                    </div>
                    <div className="flex items-center">
                        <img src={BluePin} alt="Blue Pin" className="w-8 h-10 mr-2" />
                        <span>Planned/Committed Machines</span>
                    </div>
                </div>
            </div>

            {/* Image Section */}
            <div className="w-full max-w-screen-2xl mx-auto">
                <img src={Operation287Image} alt="Operation 287" className="w-full h-full object-cover" />
            </div>
        </div>
    );
}

export default RegularFade(Operation287);
