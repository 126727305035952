import React, { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';

interface DropdownProps {
    label: string;
    clickableLabel?: boolean;
    leftColumn: {
        title?: string;
        items: string[];
    };
    rightColumn?: {
        title?: string;
        items: string[];
    };
}

const Dropdown: React.FC<DropdownProps> = ({ label, leftColumn, rightColumn, clickableLabel = false }) => {
    const [isOpen, setIsOpen] = useState(false);

    const renderLabel = () => {
        const labelContent = (
            <div className='flex justify-center items-center'>
                {label} <IoIosArrowDown className="ml-2" />
            </div>
        );

        return clickableLabel ? (
            <Link to={`/${label.toLowerCase().replace(/\s+/g, '-')}`}
                className="inline-flex items-center px-4 py-2 hover:text-custom-gray focus:outline-none transition-colors duration-500">
                <button onMouseOver={() => setIsOpen(true)} className="focus:outline-none">
                    {labelContent}
                </button>
            </Link>
        ) : (
            <div
                onMouseOver={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                className="inline-flex items-center px-4 py-2 hover:text-custom-gray focus:outline-none transition-colors duration-500">
                <button onMouseOver={() => setIsOpen(true)} className="focus:outline-none cursor-default">
                    {labelContent}
                </button>
            </div>
        );
    };

    const renderColumn = (column: { title?: string, items: string[] }) => (
        <div className="p-4">
            {column.title && <div className="font-bold text-gray-400 mb-2">{column.title}</div>}
            {column.items.map(item => (
                <Link key={item} to={`/${item.toLowerCase().replace(/\s+/g, '-')}`} className="font-jakarta text-gray-600 block hover:text-custom-blue">
                    {item}
                </Link>
            ))}
        </div>
    );

    return (
        <div className="relative inline-block text-left text-white font-bold font-inter"
            onMouseOver={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
        >
            {renderLabel()}

            <div
                onMouseOver={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                className={`absolute ${isOpen ? "opacity-100 visible transition-all transform translate-y-0" : "opacity-0 invisible transition-all transform translate-y-4"} duration-300 ease-in-out pt-4 left-[-16px] leading-10`}
            >
                <div className="bg-white shadow-lg rounded-md border border-gray-200 p-3 flex whitespace-nowrap">
                    {renderColumn(leftColumn)}
                    {rightColumn && renderColumn(rightColumn)}
                </div>
            </div>
        </div>
    );
};

export default Dropdown;
