import React from 'react';
import { MdLibraryBooks } from "react-icons/md";
import FadeUp from './animations/FadeUp';
import TripleLine from './TripleLine';
import SimpleButton from './SimpleButton';
import Title from './typography/Title';
import Subtitle from './typography/Subtitle';
import Tagline from './typography/Tagline';
import { Link } from 'react-router-dom';

interface SectionSlantProps {
    tagline: string;
    title: string;
    subtitle: string;
    image: string | React.ReactNode;
    topColor: string;
    bottomColor: string;
    buttonText: string;
}

const SectionSlant: React.FC<SectionSlantProps> = ({ tagline, title, subtitle, image, topColor, bottomColor, buttonText }) => {
    return (
        <div className={`bg-gradient-to-b ${topColor} from-50% via-50% to-50% ${bottomColor} text-white font-jakarta z-20 relative`}>
            <div className="-skew-y-3 ekc-image-gradient">
                <div className="skew-y-3 container mx-auto px-4 py-28">
                    <FadeUp>
                        <div className="flex flex-col lg:flex-row justify-center items-center gap-12">
                            <div className="max-w-md">
                                <Tagline className='text-custom-gray' >{tagline}</Tagline>
                                <Title>{title}</Title>
                                <div className='my-6'>
                                    <TripleLine />
                                </div>
                                <Subtitle>{subtitle}</Subtitle>
                                <Link to="/resources" className='flex justify-start my-8'>
                                    <SimpleButton
                                        label={buttonText}
                                        textColor="text-white"
                                        bgColor="bg-custom-black"
                                        size="medium"
                                        roundness="rounded-full"
                                        Icon={MdLibraryBooks}
                                    />
                                </Link>
                            </div>
                            <div >
                                {typeof image === 'string' ? (
                                    <img src={image} alt="Cluster" className="max-h-[40rem] rounded-lg" />
                                ) : (
                                    image
                                )}
                            </div>
                        </div>
                    </FadeUp>
                </div>
            </div>
        </div>
    );
};

export default SectionSlant;
