import { useGetGlobePoints } from '../../hooks/globeHooks';
import Table from './Table';
import { globePointColumns } from '../../common/constants';
import LogoLoading from '../animations/LogoLoading';

const GlobePointTable = () => {
    const { data: globePoints, isLoading, error } = useGetGlobePoints();

    if (isLoading) {
        return <LogoLoading />;
    }

    return (
        <>
            <Table
                name="Globe Points"
                columns={globePointColumns}
                data={globePoints.data}
                isLoading={isLoading}
                error={error}
                keyField="pointId"
                nameField="name"
            />
        </>
    );
};

export default GlobePointTable;