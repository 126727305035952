import React from 'react';
import RegularFade from './../components/animations/RegularFade';
import imageOne from '../assets/pdfs/HCR & Talent Platform-1.png';
import imageTwo from '../assets/pdfs/HCR & Talent Platform-2.png';
import imageThree from '../assets/pdfs/HCR & Talent Platform-3.png';

const OnePage: React.FC = () => {
    return (
        <div className="font-jakarta bg-custom-black min-h-screen">
            <div className="flex flex-col">
                <img src={imageOne} alt="Image One" className="w-full h-auto object-cover mb-4" />
                <img src={imageTwo} alt="Image Two" className="w-full h-auto object-cover mb-4" />
                <img src={imageThree} alt="Image Three" className="w-full h-auto object-cover" />
            </div>
        </div>
    );
}

export default RegularFade(OnePage);
