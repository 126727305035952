// In Cybersecurity.tsx
import React from 'react';

import Background from '../../assets/images/cybersecurity-background.webp';
import DYKLogo4 from '../../assets/svgs/money-bill.svg';
import DYKLogo1 from '../../assets/svgs/holding-us-dollar.svg';
import DYKLogo3 from '../../assets/svgs/jobs.svg';
import DescriptionImage from '../../assets/svgs/lock-white.svg';
import DYKLogo2 from '../../assets/svgs/graduation-cap.svg';
import BottomLogo from '../../assets/svgs/pptx_cybersec_logo.svg';
import { statsCybersecurity } from '../../common/constants';
import RegularFade from '../../components/animations/RegularFade';
import ClusterContact from '../../components/clusters/ClusterContact';
import ClusterContribute from '../../components/clusters/ClusterContribute';
import ClusterDescription from '../../components/clusters/ClusterDescription';
import ClusterDYK from '../../components/clusters/ClusterDYK';
import ClusterHeader from '../../components/clusters/ClusterHeader';
import { clusterStatId } from '../../common/constants';

const facts = [
    {
        fact: "The average entry level cybersecurity salary is $70,000 a year, progressing to $100,000 and above in years 2-4.",
        imageSrc: DYKLogo1,
    },
    {
        fact: "The Heartland Cyber Range (HCR) will support the region with free training for K-12 and Higher-Ed, directly contributing to an increase in the talent pipeline and allowing Enterprises and Businesses to hire these high-paying, critical roles.",
        imageSrc: DYKLogo2,
    },
    {
        fact: "CompTIA Security+ is the #1 requested certification in both KS and MO. EnterpriseKC’s scholarships for the Google Cybersecurity Certification are an excellent place to get started on the path to Security+.",
        imageSrc: DYKLogo3,
    },
    {
        fact: "33% of cybersecurity jobs can be done remotely, equating to roughly 3000 available remote jobs out of the 9000+ available in Kansas and Western Missouri.",
        imageSrc: DYKLogo4,
    }
]

const CybersecurityPage: React.FC = () => {
    return (
        <div>
            <ClusterHeader
                name="Cybersecurity"
                motto="Securing The Digital Frontier"
                backgroundImage={Background}
                industry="cybersecurity"
            />
            <ClusterDescription
                title="Cybersecurity"
                subtitle=" The Cybersecurity Cluster is made up of firms that earn most or all their revenue from the development, manufacturing and sale of technology that identifies, prevents and / or mitigates cyber-attacks or earn most or all their revenue from services to identify, prevent and mitigate cyber attacks."
                imageUrl={DescriptionImage}
                stats={statsCybersecurity}
                clusterId={clusterStatId.CYBERSECURITY}
            />
            <ClusterDYK
                facts={facts}
            />
            <ClusterContribute />
            <ClusterContact
                icon={BottomLogo}
                name="Cybersecurity"
            />
        </div>
    );
};

export default RegularFade(CybersecurityPage);
