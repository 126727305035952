import { FaPhone } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import FadeUp from "../animations/FadeUp";
interface ProfileProps {
    name: string;
    role: string;
    imageUrl: string;
    linkedinUser: string;
    bannerUrl?: string;
    email: string;
    phone: string;
}

const Profile: React.FC<ProfileProps> = ({ name, role, imageUrl, linkedinUser, email, phone, bannerUrl }) => {
    return (
        <FadeUp>
            <div className="container">
                <div className="dark:!bg-navy-800 shadow-shadow-500 shadow-3xl rounded-primary relative mx-auto flex h-full w-full max-w-[550px] flex-col items-center bg-white bg-cover bg-clip-border p-[16px] dark:text-white dark:shadow-none font-jakarta shadow-xl rounded-xl">
                    {/* if banner url, use background image, esle ekc-image gradient */}
                    {bannerUrl ?
                        <div className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover" style={{ backgroundImage: `url(${bannerUrl})` }}>
                            <div className="absolute -bottom-12 flex h-[88px] w-[88px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400">
                                <img className="h-full w-full rounded-full" src={imageUrl} alt="" />
                            </div>
                        </div> :
                        <div className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover ekc-image-gradient">
                            <div className="absolute -bottom-12 flex h-[88px] w-[88px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400">
                                <img className="h-full w-full rounded-full" src={imageUrl} alt="" />
                            </div>
                        </div>
                    }
                    <div className="mt-16 flex flex-col items-center">
                        <h4 className="text-bluePrimary text-xl font-bold">{name}</h4>
                        <p className="text-lightSecondary text-base font-normal">{role}</p>
                    </div>
                    <div className="mt-6 mb-3 flex flex-col sm:flex-row gap-4 md:!gap-14">
                        <a href={`https://www.linkedin.com/in/${linkedinUser}`} target="_blank" rel="noreferrer">
                            <div className="flex flex-col items-center justify-center gap-4">
                                <FaLinkedin className="text-bluePrimary w-6 h-auto" />
                                <p className="text-lightSecondary text-sm font-normal">{linkedinUser}</p>
                            </div>
                        </a>
                        <a href={`mailto:${email}`}>
                            <div className="flex flex-col items-center justify-center gap-4">
                                <IoIosMail className="text-bluePrimary w-6 h-auto" />
                                <p className="text-lightSecondary text-sm font-normal">{email}</p>
                            </div>
                        </a>
                        <a href={`tel:${phone}`}>
                            <div className="flex flex-col items-center justify-center gap-4">
                                <FaPhone className="text-bluePrimary w-6 h-auto" />
                                <p className="text-lightSecondary text-sm font-normal">{phone}</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </FadeUp>
    )
};

export default Profile;