// ColorPicker.tsx
import React from 'react';
import { RgbaColorPicker } from "react-colorful";
interface ColorPickerProps {
    selectedColor: { r: number; g: number; b: number; a: number };
    setSelectedColor: React.Dispatch<React.SetStateAction<{ r: number; g: number; b: number; a: number }>>;
    className?: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ selectedColor, setSelectedColor, className }) => {
    const handleColorChange = (colorKey: keyof typeof selectedColor, value: number) => {
        setSelectedColor({ ...selectedColor, [colorKey]: value });
    };
    return (
        <div className="flex">
            <div className='w-1/2'>    
                <div className='custom-color-picker w-full h-full'>
                    <RgbaColorPicker
                        color={selectedColor}
                        onChange={setSelectedColor}
                    />
                </div>
            </div>
            <div className="color-inputs ml-4 grid gap-2 w-1/2">
                <div>
                    <label className="block mb-2">R:</label>
                    <input
                        type="number"
                        value={selectedColor.r}
                        onChange={(e) => handleColorChange('r', parseInt(e.target.value))}
                        className={className}
                    />
                </div>
                <div>
                    <label className="block mb-2">G:</label>
                    <input
                        type="number"
                        value={selectedColor.g}
                        onChange={(e) => handleColorChange('g', parseInt(e.target.value))}
                        className={className}
                    />
                </div>
                <div>
                    <label className="block mb-2">B:</label>
                    <input
                        type="number"
                        value={selectedColor.b}
                        onChange={(e) => handleColorChange('b', parseInt(e.target.value))}
                        className={className}
                    />
                </div>
                <div>
                    <label className="block mb-2">A:</label>
                    <input
                        type="number"
                        value={selectedColor.a}
                        onChange={(e) => handleColorChange('a', parseFloat(e.target.value))}
                        min="0"
                        max="1"
                        step="0.01"
                        className={className}
                    />
                </div>
            </div>
        </div>
    );
};

export default ColorPicker;
