import Tagline from "../typography/Tagline";
import Title from "../typography/Title";
import Subtitle from "../typography/Subtitle";
import { pillars } from "../../common/constants"
import FadeInOnView from './FadeInOnView';
import { useFeatureStore } from '../../common/types';


const Pillars = () => {
    const inViewFeature = useFeatureStore((state: any) => state.inViewFeature);

    return (
        <div className="bg-white font-jakarta">
            <div className="container">
                <div className="sm:flex items-start gap-20">
                    <div className="sticky -top-40 sm:top-10 hidden sm:flex h-screen w-full items-center z-10">
                        <div className="sm:shadow-lg relative aspect-w-1 aspect-h-1 w-full rounded-2xl sm:bg-[#f2f2f2] [&:has(>_.active-card)]:bg-transparent justify-center">
                            {pillars.map((pillar) => (
                                <div
                                    key={pillar.id}
                                    className={`flex flex-col h-full justify-center items-center transition-opacity duration-500 ease-in-out ${inViewFeature === pillar.id ? 'opacity-100 translate-y-0' : 'opacity-0'} `}>
                                    <img src={pillar.image} alt={pillar.title} className="w-20 sm:w-60" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full py-16 sm:py-[370px] relative">
                        <div className="relative">
                            <Subtitle className="mb-40">
                                The Pillars make up the critical elements necessary for sustained enterprise growth and regional prosperity.
                            </Subtitle>
                            <div className='text-[3.5rem] sm:text-[4rem] lg:text-[8rem] 2xl:text-[10rem] uppercase font-extrabold text-black opacity-5 absolute z-10 2xl:left-10 -top-[5rem] sm:-top-[5rem] lg:-top-[9.5rem]'>
                                Pillars
                            </div>
                        </div>
                        <div>
                            {pillars.map((pillar) => (
                                <div key={pillar.id} className="snap-center flex flex-col md:flex-row items-center justify-between pb-20">
                                    <FadeInOnView id={pillar.id}>
                                        <div className="flex flex-col">
                                            <img src={pillar.image} alt={pillar.title} className="sm:hidden w-20 sm:w-60 pb-8" />
                                            <div>
                                                <Tagline className='ekc-text-gradient'>{pillar.title}</Tagline>
                                            </div>
                                            <Title variant="h2">{pillar.tagline}</Title>
                                            <Subtitle>{pillar.description}</Subtitle>
                                        </div>
                                    </FadeInOnView>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pillars;
