import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './slices/searchSlice';
import navbarReducer from './slices/navbarSlice';
const store = configureStore({
    reducer: {
        search: searchReducer,
        navbar: navbarReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
