import React from 'react';

interface ContentLayoutProps {
    children: React.ReactNode;
}

const ContentLayout: React.FC<ContentLayoutProps> = ({ children }) => {
    return (
        <div className="p-4 sm:ml-64 h-full">
            <div className="p-4">
                {children}
            </div>
        </div>
    );
};

export default ContentLayout;
