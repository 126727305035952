import React from 'react';
import { motion } from 'framer-motion';

type AnyComponent = React.ComponentType<any>;

const RegularFade = (Component: AnyComponent) => {
    const WrappedComponent: React.FC = (props) => {
        return (
            <motion.div
                initial={{ opacity: .1 }} // Initial state of the component to be semi-transparent
                animate={{ opacity: 1 }} // Animate to fully opaque
                exit={{ opacity: .1 }}   // Exit to semi-transparent
                transition={{ duration: 0.2, ease: "linear" }}
                className="w-full h-full"
            >
                <Component {...props} />
            </motion.div>
        );
    };

    return WrappedComponent;
};

export default RegularFade;
