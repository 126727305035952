// In AdvancedManufacturing.tsx
import React from 'react';

import AdvManuLogo from '../../assets/svgs/gear-logo.svg';
import BottomLogo from '../../assets/svgs/pptx_advancedmanu_logo.svg';
import Background from '../../assets/images/advman-background.webp';
import ClusterContact from '../../components/clusters/ClusterContact';
import ClusterDescription from '../../components/clusters/ClusterDescription';
import ClusterContribute from '../../components/clusters/ClusterContribute';
import ClusterHeader from '../../components/clusters/ClusterHeader';
import ClusterDYK from '../../components/clusters/ClusterDYK';
import DYKLogo1 from '../../assets/svgs/holding-us-dollar.svg';
import DYKLogo2 from '../../assets/svgs/employee-group.svg';
import DYKLogo3 from '../../assets/svgs/jobs.svg';
import DYKLogo4 from '../../assets/svgs/plane.svg';
import RegularFade from '../../components/animations/RegularFade';
import { statsAdvancedManufacturing } from '../../common/constants';
import { clusterStatId } from '../../common/constants';

const facts = [
    {
        fact: "EnterpriseKC views Advanced Manufacturing as one of the most high-opportunity clusters in the region with an average regional salary of $58,480.",
        imageSrc: DYKLogo1,
    },
    {
        fact: "Panasonic Energy expects to add 4,000 jobs to the region – this will create thousands of additional non-Panasonic jobs across multiple clusters. Based on EKC’s Economic Lift calculation the total impact will be ~9,000 new jobs.",
        imageSrc: DYKLogo2,
    },
    {
        fact: "The KS Dept. of Commerce BASE grant program recently supported groundbreaking on the Grow Hays Microfactory, in Hays, KS. The 30,000 square foot facility will serve as a launching pad for future manufacturing companies in Ellis County and the surrounding areas.",
        imageSrc: DYKLogo3,
    },
    {
        fact: "Kansas leads the world in aviation aircraft manufacturing and is home to two of the world’s premier general aviation aircraft manufacturers – Spirit AeroSystems and Textron Aviation, both in Wichita.",
        imageSrc: DYKLogo4,
    }
]

const AdvancedManufacturingPage: React.FC = () => {
    return (
        <div>
            <ClusterHeader
                name="Advanced Manufacturing"
                motto="Where Precision Meets Progress"
                backgroundImage={Background}
                industry="advanced-manufacturing"
            />
            <ClusterDescription
                title="Advanced Manufacturing"
                subtitle="The Advanced Manufacturing cluster is made up of companies that earn most or all of their revenue from converting raw materials and / or components into finished products by using automation, high precision, mass production, and information technologies integrated into the production system. "
                imageUrl={AdvManuLogo}
                clusterId={clusterStatId.ADVANCED_MANUFACTURING}
                stats={statsAdvancedManufacturing}
            />
            <ClusterDYK
                facts={facts}
            />
            <ClusterContribute />
            <ClusterContact
                icon={BottomLogo}
                name="Advanced Manufacturing"
            />
        </div>
    );
};

export default RegularFade(AdvancedManufacturingPage);
