import UnconventionalTabs from "../UnconventionalTabs";
import { visionToValueTabs } from '../../common/constants';
import Tagline from "../typography/Tagline";
import Title from "../typography/Title";
import Subtitle from "../typography/Subtitle";


const VisionToValue = () => {
    return (
        <div className="ekc-image-gradient">
            <div className="container">
                <div className='flex flex-col justify-between items-center gap-12 py-16 lg:py-32'>
                    <div className="text-white">
                        <Tagline>Our Formula for Success</Tagline>
                        <Title>Vision-to-Value</Title>
                        <Subtitle>The V<sup>2</sup> Algorithm provides a simple, yet highly effective methodology for managing the context and the sequence of a project. The formula includes the following sequence and variables and is highly useful for successfully implementing projects of any size or dimension.</Subtitle>
                    </div>
                    <UnconventionalTabs tabs={visionToValueTabs} />
                </div>
            </div>
        </div>
    );
}

export default VisionToValue;