import React from 'react';
import { createRoot } from 'react-dom/client';
import { Document, Page } from 'react-pdf';

const getPdfImageUrl = (src: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const container = document.createElement('div'); // Create an off-screen container
        const root = createRoot(container); // Create a root for the container

        const onRenderSuccess = () => {
            const canvas = container.querySelector(".react-pdf__Page__canvas") as HTMLCanvasElement;
            if (canvas) {
                const canvasUrl = canvas.toDataURL('image/png');
                resolve(canvasUrl);
            } else {
                reject('Canvas not found');
            }
        };

        // Render the PDF in the off-screen container
        root.render(
            <Document file={src}>
                <Page pageNumber={1} onRenderSuccess={onRenderSuccess} />
            </Document>
        );
    });
};

export default getPdfImageUrl;
