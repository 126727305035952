// ClusterContact.tsx
import React from 'react';
import FadeUp from '../animations/FadeUp';
import Title from '../typography/Title';

// Define ClusterContactProps
interface ClusterContactProps {
    icon: string;
    name: string;
}


const ClusterContact: React.FC<ClusterContactProps> = ({ icon, name }) => {
    return (
        <div className="bg-custom-gray">
            <FadeUp>
                <div className="flex flex-col container mx-auto items-center gap-8 pb-32">
                    <div className="w-32 h-32 bg-custom-black" style={{
                        mask: `url(${icon}) no-repeat center / contain`,
                        WebkitMask: `url(${icon}) no-repeat center / contain`
                    }} aria-label="Gear Logo">
                    </div>
                    <Title variant='h2' className='text-center ekc-text-gradient'>{name}</Title>
                </div>
            </FadeUp>
        </div>
    );
};

export default ClusterContact;
