import { AnimatePresence } from "framer-motion";
import { ColumnConfig } from "../../common/types";
import useTable from '../../hooks/useTable';
import DrawerEditGlobePoint from "./DrawerEditGlobePoint";
import DrawerPreview from "./DrawerPreview";
import ModalDelete from "./ModalDelete";
import DrawerEditGlobeGroup from "./DrawerEditGlobeGroup";
import DrawerEditGlobeType from "./DrawerEditGlobeType";
import DrawerEditResource from "./DrawerEditResource";
import DrawerEditGlobeCluster from "./DrawerEditGlobeCluster";

interface TableRowProps<T> {
    data: T;
    columns: ColumnConfig<T>[];
    keyField: keyof T;
}

const TableRow = <T,>({ data, columns, keyField }: TableRowProps<T>) => {
    const {
        selectedRowId,
        showDrawerEdit,
        showDrawerPreview,
        showModalDelete,
        tableName,
        handleDrawerEditToggle,
        handleDrawerPreviewToggle,
        handleModalDeleteToggle
    } = useTable();

    return (
        <tr className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
            <td className="p-4 w-4">
                <div className="flex items-center">
                    <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                </div>
            </td>
            {columns.map((column, index) => (
                (column.key === 'pointColor' || column.key === 'arcColor') ? (
                    <td key={index} className="px-4 py-3 max-w-36 overflow-x-scroll no-scrollbar">
                        <div className="flex items-center">
                            <div className="w-4 h-4 rounded-full mr-2" style={{ backgroundColor: String(data[column.key]) }}></div>{String(data[column.key])}
                        </div>
                    </td>
                ) : (column.key === "imageLink") ? (
                    <td key={index} className="px-4 py-3 overflow-x-scroll no-scrollbar">
                        <img src={String(data[column.key])} alt={String(data[column.key])} className="h-40" />
                    </td>
                ) : (
                    <td key={index} className="px-4 py-3 max-w-36 overflow-x-scroll no-scrollbar">
                        {String(data[column.key])}
                    </td>
                )
            ))}

            <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <div className="flex items-center space-x-4">
                    <button type="button" onClick={() => handleDrawerEditToggle(data[keyField])} className="py-2 px-3 flex items-center text-sm font-medium text-center text-white bg-ekc-blue rounded-lg hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-ekc-blue dark:focus:ring-primary-800">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 -ml-0.5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                        </svg>
                        Edit
                    </button>
                    <button disabled type="button" onClick={() => handleDrawerPreviewToggle(data[keyField])} className="py-2 px-3 flex items-center text-sm font-medium text-center text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-ekc-blue focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2 -ml-0.5">
                            <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" />
                        </svg>
                        Preview
                    </button>
                    <button type="button" onClick={() => handleModalDeleteToggle(data[keyField])} className="flex items-center text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 -ml-0.5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                        Delete
                    </button>
                </div>
                <AnimatePresence>
                    {selectedRowId === data[keyField] && showDrawerEdit && (
                        tableName === 'Globe Points' ? <DrawerEditGlobePoint /> :
                            tableName === 'Globe Groups' ? <DrawerEditGlobeGroup /> :
                                tableName === 'Globe Types' ? <DrawerEditGlobeType /> :
                                    tableName === 'Resources' ? <DrawerEditResource /> :
                                        tableName === 'Globe Clusters' ? <DrawerEditGlobeCluster /> :
                                        null
                    )}
                </AnimatePresence>
                {selectedRowId === data[keyField] && showDrawerPreview && <DrawerPreview />}
                {selectedRowId === data[keyField] && showModalDelete && <ModalDelete />}
            </td>
        </tr>
    );
}

export default TableRow;