import React from 'react';
import { motion } from 'framer-motion';

interface GrowHoverProps {
    children: React.ReactNode;
    scale: number;
    originX?: number;
    duration?: number;
    bounce?: number;
    className?: string;
}

const GrowHover: React.FC<GrowHoverProps> = ({
    children, scale, originX = 0.5, duration = 0.75, bounce = 0.5, className
}) => {
    const hoverAnimation = {
        initial: { scale: 1, originX: originX },
        whileHover: { scale: scale, originX: originX },
        transition: { type: 'spring', duration: duration, bounce: bounce }
    };

    return (
        <motion.div {...hoverAnimation} className={`${className}`}>
            {children}
        </motion.div>
    );
};

export default GrowHover;
