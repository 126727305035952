import { useEffect } from 'react';

const usePreventBodyScroll = () => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);
};

export default usePreventBodyScroll;
