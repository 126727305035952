// In FinancialServicesPage.tsx
import React from 'react';

import DescriptionImage from '../../assets/svgs/dollar-symbol.svg';
import BottomLogo from '../../assets/svgs/pptx_financeinsur_logo.svg';
import Background from '../../assets/images/financial-services-bg.webp';
import ClusterContact from '../../components/clusters/ClusterContact';
import ClusterDescription from '../../components/clusters/ClusterDescription';
import ClusterContribute from '../../components/clusters/ClusterContribute';
import ClusterHeader from '../../components/clusters/ClusterHeader';
import ClusterDYK from '../../components/clusters/ClusterDYK';
import DYKLogo1 from '../../assets/svgs/holding-us-dollar.svg';
import DYKLogo2 from '../../assets/svgs/arrow-growth.svg';
import DYKLogo3 from '../../assets/svgs/piggy.svg';
import DYKLogo4 from '../../assets/svgs/risk.svg';
import RegularFade from '../../components/animations/RegularFade';
import { statsFinancialServices } from '../../common/constants';
import { clusterStatId } from '../../common/constants';

const facts = [
    {
        fact: "The global financial services market was valued at $25.1 trillion in 2022 and is expected to surpass $58.69 trillion by 2031, expanding at a CAGR of 9.7% during the forecast period, 2023-2031.",
        imageSrc: DYKLogo1,
    },
    {
        fact: "Growth for financial services and insurance occupations are expected to accelerate across the region with a projected demand (replacement and growth) estimated at 35,000 additional jobs over the next 5 years.",
        imageSrc: DYKLogo2,
    },
    {
        fact: "The Federal Reserve Bank of Kansas City is one of 12 regional Reserve Banks that make up our nation’s central bank in support of national and regional economic and financial stability. They employ roughly 2,000 people in our region.",
        imageSrc: DYKLogo3,
    },
    {
        fact: "Nearly one quarter of the companies in the insurance, asset and wealth management sector will be at risk of disruption due to new business models brought about by fintech and insurtech.",
        imageSrc: DYKLogo4,
    }
]

const FinancialServices: React.FC = () => {
    return (
        <div>
            <ClusterHeader
                name="Financial Services - Insurance"
                motto="Safeguarding Wealth, Securing Tomorrows"
                backgroundImage={Background}
                industry="financial-services"
            />
            <ClusterDescription
                title="Financial Services - Insurance"
                subtitle={
                    <>
                        <p>The Financial Services and Insurance cluster is combined based on common synergies. </p>
                        <p><strong>Financial Services:</strong> Comprising companies primarily focused on managing money, including banks, credit unions, investment firms, and fintech companies offering digital financial solutions. </p>
                        <p><strong>Insurance:</strong> Comprising companies specializing in insurance and risk management, such as carriers, brokers, and Insurtech firms improving digital claims processing and risk evaluation. </p>
                    </>
                } imageUrl={DescriptionImage}
                stats={statsFinancialServices}
                clusterId={clusterStatId.FINANCIAL_SERVICES}
            />
            <ClusterDYK
                facts={facts}
            />
            <ClusterContribute />
            <ClusterContact
                icon={BottomLogo}
                name="Financial Services - Insurance"
            />
        </div>
    );
};


export default RegularFade(FinancialServices);
