// In PlantAnimalSciencesPage.tsx
import React from 'react';

import DescriptionImage from '../../assets/svgs/leaf-bird.svg';
import BottomLogo from '../../assets/svgs/pptx_plantanimal_logo.svg';
import Background from '../../assets/images/plant-and-animal-sciences-bg.webp';
import ClusterContact from '../../components/clusters/ClusterContact';
import ClusterDescription from '../../components/clusters/ClusterDescription';
import ClusterContribute from '../../components/clusters/ClusterContribute';
import ClusterHeader from '../../components/clusters/ClusterHeader';
import ClusterDYK from '../../components/clusters/ClusterDYK';
import DYKLogo1 from '../../assets/svgs/holding-us-dollar.svg';
import DYKLogo2 from '../../assets/svgs/cow.svg';
import DYKLogo3 from '../../assets/svgs/arrow-growth.svg';
import DYKLogo4 from '../../assets/svgs/seedling.svg';
import RegularFade from '../../components/animations/RegularFade';
import { statsPlantAnimalSciences } from '../../common/constants';
import { clusterStatId } from '../../common/constants';

const facts = [
    {
        fact: "Dairy Farmers of America have more than 6,000 family farms located across the United States. Their headquarters are in Kansas City, Kansas and they generate over $20 billion dollars in revenue annually.",
        imageSrc: DYKLogo1,
    },
    {
        fact: "The KC Animal Health Corridor, anchored by Manhattan and Columbia is home to more than 300 animal health companies, representing more than half of the world’s animal health, diagnostics, and pet food sales.",
        imageSrc: DYKLogo2,
    },
    {
        fact: "The gross production value of the US Plant and Animal Sciences Cluster is expected to reach $298.3 billion, with a forecasted annual growth rate of 2.81% through 2028.",
        imageSrc: DYKLogo3,
    },
    {
        fact: "The US Department of Agriculture (USDA) has two significant facilities based in the region: The Economic Research Service and the National Institute of Food and Agriculture.",
        imageSrc: DYKLogo4,
    }
]

const PlantAnimalSciences: React.FC = () => {
    return (
        <div>
            <ClusterHeader
                name="Plant and Animal Sciences"
                motto="Harvesting Innovation and Sustainability"
                backgroundImage={Background}
                industry="plant-and-animal-sciences"
            />
            <ClusterDescription
                title="Plant and Animal Sciences"
                subtitle="The Plant & Animal Science cluster is made up of companies that earn most, or all of their revenue from the practice of agriculture, the support of the practice of agriculture, food processing and beverage processing for preparation and consumption. In addition, the cluster includes firms that earn most, or all their revenue from the science and business of producing domestic livestock species or applying principles of the biological, physical, and social sciences to the problems associated with livestock and crop production and management."
                imageUrl={DescriptionImage}
                stats={statsPlantAnimalSciences}
                clusterId={clusterStatId.PLANT_ANIMAL_SCIENCES}
            />
            <ClusterDYK
                facts={facts}
            />
            <ClusterContribute />
            <ClusterContact
                icon={BottomLogo}
                name="Plant and Animal Sciences"
            />
        </div>
    );
};

export default RegularFade(PlantAnimalSciences);
