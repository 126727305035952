import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
    addGlobeGroup,
    addGlobePoint,
    addGlobeType,
    addGlobeCluster,
    getGlobeGroups,
    getGlobePoints,
    getGlobeTypes,
    getGlobeClusters,
    updateGlobeGroup,
    updateGlobePoint,
    updateGlobeType,
    updateGlobeCluster,
    deleteGlobeGroup,
    deleteGlobePoints,
    deleteGlobeType,
    deleteGlobeCluster,
    getClusterStats
} from '../utils/api';

/***********************************************************************************************
GET HOOKS
***********************************************************************************************/

export const useGetGlobePoints = () => {
    return useQuery("globePoints", getGlobePoints, {
        refetchOnWindowFocus: false,
    });
}

export const useGetGlobeGroups = () => {
    return useQuery("globeGroups", getGlobeGroups,
        {
            refetchOnWindowFocus: false,
        });
}

export const useGetGlobeTypes = () => {
    return useQuery("globeTypes", getGlobeTypes,
        {
            refetchOnWindowFocus: false,
        });
}

export const useGetGlobeClusters = () => {
    return useQuery("globeClusters", getGlobeClusters,
        {
            refetchOnWindowFocus: false,
        });
}

export const useGetClusterStats = (clusterId: number) => {
    return useQuery(["clusterStats", clusterId], () => getClusterStats(clusterId),
        {
            refetchOnWindowFocus: false,
        });
}

/***********************************************************************************************
ADD HOOKS
***********************************************************************************************/

export const useAddGlobePoint = () => {
    const queryClient = useQueryClient();
    return useMutation(addGlobePoint, {
        onSuccess: () => {
            queryClient.invalidateQueries('globePoints');
        },
    });
};

export const useAddGlobeGroup = () => {
    const queryClient = useQueryClient();
    return useMutation(addGlobeGroup, {
        onSuccess: () => {
            queryClient.invalidateQueries('globeGroups');
        },
    });
};

export const useAddGlobeType = () => {
    const queryClient = useQueryClient();
    return useMutation(addGlobeType, {
        onSuccess: () => {
            queryClient.invalidateQueries('globeTypes');
        },
    });
};

export const useAddGlobeCluster = () => {
    const queryClient = useQueryClient();
    return useMutation(addGlobeCluster, {
        onSuccess: () => {
            queryClient.invalidateQueries('globeClusters');
        },
    });
};


/***********************************************************************************************
UPDATE HOOKS
***********************************************************************************************/

export const useUpdateGlobePoint = () => {
    const queryClient = useQueryClient();
    return useMutation(updateGlobePoint, {
        onSuccess: () => {
            queryClient.invalidateQueries('globePoints');
        },
    });
};

export const useUpdateGlobeGroup = () => {
    const queryClient = useQueryClient();
    return useMutation(updateGlobeGroup, {
        onSuccess: () => {
            queryClient.invalidateQueries('globeGroups');
        },
    });
};

export const useUpdateGlobeType = () => {
    const queryClient = useQueryClient();
    return useMutation(updateGlobeType, {
        onSuccess: () => {
            queryClient.invalidateQueries('globeTypes');
        },
    });
};

export const useUpdateGlobeCluster = () => {
    const queryClient = useQueryClient();
    return useMutation(updateGlobeCluster, {
        onSuccess: () => {
            queryClient.invalidateQueries('globeClusters');
        },
    });
};


/***********************************************************************************************
DELETE HOOKS
***********************************************************************************************/

export const useDeleteGlobePoints = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteGlobePoints, {
        onSuccess: () => {
            queryClient.invalidateQueries('globePoints');
        },
    });
};

export const useDeleteGlobeGroup = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteGlobeGroup, {
        onSuccess: () => {
            queryClient.invalidateQueries('globeGroups');
        },
    });
};

export const useDeleteGlobeType = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteGlobeType, {
        onSuccess: () => {
            queryClient.invalidateQueries('globeTypes');
        },
    });
};
export const useDeleteGlobeCluster = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteGlobeCluster, {
        onSuccess: () => {
            queryClient.invalidateQueries('globeClusters');
        },
    });
};
