import { useGetGlobeClusters } from '../../hooks/globeHooks';
import Table from './Table';
import { globeClusterColumns } from '../../common/constants';
import LogoLoading from '../animations/LogoLoading';

const GlobeClusterTable = () => {
    const { data: globeCluster, isLoading, error } = useGetGlobeClusters();

    if (isLoading) {
        return <LogoLoading />;
    }

    return (
        <>
            <Table
                name="Globe Clusters"
                columns={globeClusterColumns}
                data={globeCluster.data}
                isLoading={isLoading}
                error={error}
                keyField="clusterId"
                nameField="name"
            />
        </>
    );
};

export default GlobeClusterTable;