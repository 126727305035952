import Table from './Table';
import { resourceColumns } from '../../common/constants';
import LogoLoading from '../animations/LogoLoading';
import { useGetResources } from '../../hooks/resourceHooks';

const ResourceTable = () => {
    const { data: resources, isLoading, error } = useGetResources();

    if (isLoading) {
        return <LogoLoading />;
    }

    return (
        <>
            <Table
                name="Resources"
                columns={resourceColumns}
                data={resources.data}
                isLoading={isLoading}
                error={error}
                keyField="id"
                nameField="title"
            />
        </>
    );
};

export default ResourceTable;