import React from 'react';
import GrowHover from './animations/GrowHover';

interface SimpleButtonProps {
    label: string;
    textColor: string;
    bgColor: string;
    Icon?: React.ElementType;
    onClick?: () => void;
    size: 'small' | 'medium' | 'large';
    roundness?: string;
    fullWidth?: boolean;
    growScale?: number;
}

const sizeClasses = {
    small: 'py-3 px-4 h-full text-sm',
    medium: 'py-3 px-8 h-full text-base',
    large: 'py-3 px-10 h-full text-lg',
};

const SimpleButton: React.FC<SimpleButtonProps> = ({
    bgColor, textColor, label, Icon, onClick, size, roundness = "rounded-full", fullWidth = false, growScale = 1.1
}) => (
    <GrowHover scale={growScale} className={fullWidth ? "w-full" : ""}>
        <button
            className={`w-full font-jakarta flex items-center justify-center gap-3 ${bgColor} font-bold ${roundness} hover:outline-none rounded ${sizeClasses[size]} cursor-pointer`}
            onClick={onClick}
        >
            <span className={`${textColor}`}>{label}</span>
            {Icon && <Icon className='text-white h-5 text-xl' />}
        </button>
    </GrowHover>
);

export default SimpleButton;
