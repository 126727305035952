import classNames from "classnames";
import { useFeatureStore } from "../../common/types";
import Image1 from '../../assets/images/CylinderModel-Part1.webp';
import Image2 from '../../assets/images/CylinderModel-Part2.webp';
import Image3 from '../../assets/images/CylinderModel-Part3.webp';

type FeatureCardProps = {
    image: string;
    children: React.ReactNode;
} & CardProps;

type CardProps = {
    id: string;
};

const FeatureCard = ({ image, children, id }: FeatureCardProps) => {
    const inViewFeature = useFeatureStore((state: any) => state.inViewFeature);
    return (
        <div
            className={classNames(
                "absolute inset-0 h-full w-full rounded-2xl transition-opacity -mb-[5vh]",
                inViewFeature === id
                    ? "active-card opacity-100"
                    : "pointer-events-none opacity-0"
            )}
            style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
        >
            {children}

        </div>
    );
};

export const Top = ({ id }: CardProps) => {
    return (
        <FeatureCard id={id} image={Image1}>
            <img src={Image1} alt="" />
        </FeatureCard>
    );
};

export const Middle = ({ id }: CardProps) => {
    return (
        <FeatureCard id={id} image={Image3}>
            <img src={Image2} alt="" />
        </FeatureCard>
    );
};

export const Bottom = ({ id }: CardProps) => {
    return (
        <FeatureCard id={id} image={Image2}>
            <img src={Image3} alt="" />
        </FeatureCard>
    );
};

export const Main = ({ id }: CardProps) => {

    return (
        <FeatureCard id={id} image={Image1}>
            <img
                className={classNames(
                    "absolute left-[10%] top-[10%] w-[20%] rounded-xl shadow-lg transition-transform",
                )}
                src={Image1} alt=""
            />
            <img
                className={classNames(
                    "absolute left-[70%] top-[20%] w-[25%] rounded-xl shadow-lg transition-transform",
                )}
                src={Image2} alt=""
            />
            <img
                className={classNames(
                    "absolute left-[20%] top-[60%] w-[30%] rounded-xl shadow-lg transition-transform",

                )}
                src={Image3} alt=""
            />
        </FeatureCard>
    );
};

