import { useEffect } from 'react';
import { useAppDispatch } from '../redux/hooks';
import { setNavbarTransparency } from '../redux/slices/navbarSlice';


// Custom hook to toggle navbar transparency
export const useNavbarTransparency = (isTransparent: boolean) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setNavbarTransparency(isTransparent));
        return () => {
            dispatch(setNavbarTransparency(true));
        }
    }, [dispatch, isTransparent]);
};
