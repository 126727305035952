import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import HeroText from '../HeroText';
import { FaFlask, FaRocket } from "react-icons/fa";
import { GrDocumentTest, GrDocumentConfig } from "react-icons/gr";
import { AiOutlineTeam } from "react-icons/ai";

const CyberRangeTimeline: React.FC = () => {
    return (
        <div className='!font-jakarta container pb-16 lg:pb-32'>
            <HeroText
                tagline='PAST • PRESENT • FUTURE'
                title='Project Milestones'
                subtitle='Analysis, Design, Development, Testing, Deployment, Operations, Support'
                className='mb-8 flex flex-col items-center justify-center text-center'
            />
            <Timeline position='alternate'>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        Sep 2022
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaFlask className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Research and Analysis
                        </Typography>
                        <Typography className='!font-jakarta'>
                            Research and market analysis of various leading off-shelf and statewide cyber ranges.
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        Oct 2022
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaFlask className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Research and Meetings
                        </Typography>
                        <Typography className='!font-jakarta'>
                            Research and meetings with industry cyber professionals, academic institutions, and government entities.
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        Dec 2022
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <GrDocumentTest className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Market Requirements Document (MRD)
                        </Typography>
                        <Typography className='!font-jakarta'>
                            Completion of Market Requirements Document (MRD). The MRD describes the overall market opportunity, the types of customers targeted, and the competitors in the space.
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        Jan 2023
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <GrDocumentConfig className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Product Requirements Document (PRD)
                        </Typography>
                        <Typography className='!font-jakarta'>
                            Completion of Product Requirements Document (PRD). The PRD communicates what capabilities and functional requirements are required for the product.
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        Mar 2023
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-green'>
                            <AiOutlineTeam className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Vendor Selection
                        </Typography>
                        <Typography className='!font-jakarta'>
                            Finalized vendor selection for Cyber Range Buildout & Content Development. Project Kickoff with key stakeholders, assessment team, and development team.
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                {/* New Timeline Item for Beta Testing Launch */}
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        Dec 2024
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaRocket className='w-6 h-auto' />
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Beta Testing Launch
                        </Typography>
                        <Typography className='!font-jakarta'>
                            The Beta version of the Cyber Range is scheduled to launch for testing and feedback in December 2024.
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </div>
    );
}

export default CyberRangeTimeline;