import React, { FormEvent, useEffect, useState } from 'react';
import LogoLoading from './animations/LogoLoading';

type SalesforceFormProps = {
    debug: boolean;
    page: string;
    children: React.ReactNode;
    button: React.ReactNode;
    formStyle?: string;
    enableReCAPTCHA?: boolean;
    submissionCheck?: (isSubmitted: boolean) => void;
    loadingCallback?: () => void;
    isCase?: boolean;
};

const SalesforceForm: React.FC<SalesforceFormProps> = ({ debug, page, children, button, formStyle = "", enableReCAPTCHA = true, submissionCheck, loadingCallback, isCase = true }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const formRef = React.useRef<HTMLFormElement>(null);
    const formActionUrl = `https://webto.salesforce.com/servlet/servlet.${isCase ? "WebToCase" : "WebToLead"}?encoding=UTF-8`;

    const validateForm = () => {
        let isValid = true;

        // Validate form fields
        if (formRef.current && !formRef.current.checkValidity()) {
            isValid = false;
        }

        // Validate Google reCAPTCHA only if enabled
        if (enableReCAPTCHA) {
            const recaptchaValue = document.getElementById('g-recaptcha-response') as HTMLInputElement;
            if (!recaptchaValue || recaptchaValue.value.trim() === '') {
                isValid = false;
                setErrorMessage('Please complete the reCAPTCHA.');
            } else {
                setErrorMessage(null);
            }
        }

        return isValid;
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        // Validate the form
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        // Set the form to submitted after some time
        setTimeout(() => {
            setIsSubmitted(true);
            setIsLoading(false);
            if (submissionCheck) {
                submissionCheck(true);
            }
        }, 750);

        // Manually submit the form
        if (formRef.current) {
            formRef.current.submit();
        }
    };

    // Add the Google reCAPTCHA script to the head of the page
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js';
        script.async = true;
        document.head.appendChild(script);

        const timestamp = () => {
            const response = document.getElementById('g-recaptcha-response') as HTMLInputElement;
            const captchaSettings = document.getElementsByName('captcha_settings')[0] as HTMLInputElement;

            if (captchaSettings) { // Add this check
                if (response == null || response.value.trim() === '') {
                    const elems = JSON.parse(captchaSettings.value);
                    elems['ts'] = JSON.stringify(new Date().getTime());
                    captchaSettings.value = JSON.stringify(elems);
                }
            }
        };

        const intervalId = setInterval(timestamp, 500);

        return () => {
            clearInterval(intervalId);
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (!isLoading && loadingCallback) {
            loadingCallback();
        }
    }, [isLoading, loadingCallback]);

    return (
        <div className="w-full md:h-full flex justify-center">
            <iframe name="hiddenFrame" title="Form Submission Frame" className="hidden"></iframe>
            {isSubmitted ? (
                <div className="text-center">
                    <h1 className="text-5xl text-white">Thank You!</h1>
                    <p className="text-xl text-white">Your form has been successfully submitted.</p>
                </div>
            ) : (
                <form
                    ref={formRef}
                    target="hiddenFrame"
                    action={formActionUrl}
                    method="POST"
                    onSubmit={handleFormSubmit}
                    className={formStyle}
                >
                    <input type="hidden" name="orgid" value="00D8V0000037hnj" />
                    <input type="hidden" name="oid" value="00D8V0000037hnj" />
                    <input type="hidden" name="retURL" value="http://enterprise-kc.com/defendtomorrow" />
                    {debug && (
                        <>
                            <input type="hidden" name="debug" value="1" />
                            <input type="hidden" name="debugEmail" value="tnguyen@enterprise-kc.com" />
                        </>
                    )}
                    <input type="hidden" name="00N8V00000OtMGK" value={page} />
                    {children}
                    {enableReCAPTCHA ? (
                        <>
                            <input type="hidden" name="captcha_settings" value="{&quot;keyname&quot;:&quot;WebsiteFormV2&quot;,&quot;fallback&quot;:&quot;true&quot;,&quot;orgId&quot;:&quot;00D8V0000037hnj&quot;,&quot;ts&quot;:&quot;&quot;}" />
                            <div className="g-recaptcha my-4" data-sitekey="6LeDb78kAAAAAEmfAc4pMN8X4uZMKiRCAYhSMMME"></div>
                            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
                        </>
                    ) : (
                        <div className='my-2' />
                    )}
                    <div className="flex justify-center w-full">
                        {button}
                    </div>
                </form>)}
            {isLoading && (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
                    <LogoLoading />
                </div>
            )}
        </div>
    );
};

export default SalesforceForm;
