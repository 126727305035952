// In ArchitectureEngineeringPage.tsx
import React from 'react';

import DescriptionImage from '../../assets/svgs/arch-engineer-logo.svg';
import BottomLogo from '../../assets/svgs/pptx_archeng_logo.svg';
import Background from '../../assets/images/architecture-engineering-bg.webp';
import ClusterContact from '../../components/clusters/ClusterContact';
import ClusterDescription from '../../components/clusters/ClusterDescription';
import ClusterContribute from '../../components/clusters/ClusterContribute';
import ClusterHeader from '../../components/clusters/ClusterHeader';
import ClusterDYK from '../../components/clusters/ClusterDYK';
import DYKLogo1 from '../../assets/svgs/stadium.svg';
import DYKLogo2 from '../../assets/svgs/seedling.svg';
import DYKLogo3 from '../../assets/svgs/building.svg';
import DYKLogo4 from '../../assets/svgs/graduation-cap.svg';
import RegularFade from '../../components/animations/RegularFade';
import { statsArchitectureEngineering } from '../../common/constants';
import { clusterStatId } from '../../common/constants';

const facts = [
    {
        fact: "The region among the largest concentrations of architecture and engineering employment in the nation and is considered to be the “sports architecture capital of the world” originating 75% of American stadium and arena venues.",
        imageSrc: DYKLogo1,
    },
    {
        fact: "The AE cluster is expected to grow from $1.60 trillion in 2023 to $1.96 trillion by 2028, at a CAGR of 4.20% during the forecast period (2023-2028).",
        imageSrc: DYKLogo2,
    },
    {
        fact: "The region is home to many Primary Enterprises in the Architecture – Engineering cluster. These firms export design services around the globe including Black & Veatch, Burns & McDonnell, and Populous providing an immense amount of economic lift to our region.",
        imageSrc: DYKLogo3,
    },
    {
        fact: "In 2022, Kansas and Western Missouri produced 6,710 Bachelor’s degree graduates in programs related to the Architecture – Engineering Cluster. K-State and Missouri S&T were the top producers in each state.",
        imageSrc: DYKLogo4,
    }
]

const ArchitectureEngineering: React.FC = () => {
    return (
        <div>
            <ClusterHeader
                name="Architecture Engineering"
                motto="Designing Dreams, Building Realities"
                backgroundImage={Background}
                industry="architecture-and-engineering"
            />
            <ClusterDescription
                title="Architecture Engineering"
                subtitle={
                    <>
                        <p>The Architecture and Engineering cluster is combined based on common synergies.</p>
                        <p><strong>Architecture Cluster:</strong> Comprising companies employing licensed architects, primarily earning revenue from architectural practice.</p>
                        <p><strong>Engineering Cluster:</strong> Comprising companies employing professional engineers, predominantly earning revenue from engineering practice, including construction, transportation, and environmental services.</p>
                    </>
                }
                imageUrl={DescriptionImage}
                stats={statsArchitectureEngineering}
                clusterId={clusterStatId.ARCHITECTURE_ENGINEERING}
            />
            <ClusterDYK
                facts={facts}
            />
            <ClusterContribute />
            <ClusterContact
                icon={BottomLogo}
                name="Architecture - Engineering"
            />
        </div>
    );
};

export default RegularFade(ArchitectureEngineering);
