import React from 'react';
import ContactForm from '../components/ContactForm';
import RegularFade from './../components/animations/RegularFade';

const ContactPage: React.FC = () => {
    return (
        <>
            <ContactForm pageName="Contact Us" />
        </>
    );
}

export default RegularFade(ContactPage);