import ImaginePic from "../../assets/images/talent-triangle.webp";
import FadeLeft from "../animations/FadeLeft";
import FadeRight from "../animations/FadeRight";
import HeroText from "../HeroText";

interface WhatIsItProps {
    tagline: string;
    title: string;
    subtitle: string | JSX.Element;
    image?: string;
    side?: string;
}

const WhatIsIt: React.FC<WhatIsItProps> = ({ tagline, title, subtitle, image = ImaginePic, side = 'left' }) => {
    const isLeftSide = side === 'left';

    return (
        <div className="container">
            <div className={`flex flex-col lg:flex-row justify-between items-center gap-12 py-16 lg:py-32 ${isLeftSide ? '' : 'flex-row-reverse'}`}>
                {isLeftSide ? (
                    <>
                        <HeroText
                            tagline={tagline}
                            title={title}
                            subtitle={subtitle}
                        />
                        <FadeRight>
                            <img src={image} alt="placeholder" className="w-[30rem] rounded-3xl" />
                        </FadeRight>
                    </>
                ) : (
                    <>
                        <FadeLeft>
                            <img src={image} alt="placeholder" className="w-[30rem] rounded-3xl" />
                        </FadeLeft>
                        <HeroText
                            tagline={tagline}
                            title={title}
                            subtitle={subtitle}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default WhatIsIt;