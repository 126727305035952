import React from 'react';

import BlobWrapper from '../BlobWrapper';
import Title from '../typography/Title';
import Subtitle from '../typography/Subtitle';
import FadeLeft from '../animations/FadeLeft';

interface ToolShowcaseProps {
    title: string;
    subtitle: string;
    image: string;
}

const ToolShowcase: React.FC<ToolShowcaseProps> = ({ title, subtitle, image }) => {
    return (
        <div className="container z-20">
            <div className='flex flex-col lg:flex-row justify-between items-center gap-12 pb-16 lg:pb-32'>
                <FadeLeft>
                    <BlobWrapper backgroundColor="ekc-image-gradient" maxHeight='200px'>
                        <img src={image} alt="Assessment Tool" className="lg:w-[40rem] w-full h-auto rounded-xl shadow-lg relative z-30 bg-white" />
                    </BlobWrapper>
                </FadeLeft>
                <div className='flex flex-col order-first lg:order-last' >
                    <Title variant='h2'>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </div>
            </div>
        </div >
    );
}

export default ToolShowcase;
