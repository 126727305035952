import classNames from "classnames";
import OtherVisualImage from "../../assets/images/Gear-Logo.webp";

type Props = {
    id: string;
};

type VisualProps = {
    children: React.ReactNode;
} & Props;

const Visual = ({ children, id }: VisualProps) => {
    return (
        <div
            className={classNames(
                "pointer-events-none fixed inset-0 flex items-center justify-center opacity-0",
                `visual-${id}`
            )}
        >
            <div className="max-w-6xl px-4">{children}</div>
        </div>
    );
};
export const OtherVisual = ({ id }: Props) => {
    return (
        <Visual id={id}>
            <img src={OtherVisualImage} alt="" />
        </Visual>
    );
};