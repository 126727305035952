import React from 'react';
import TripleLine from '../TripleLine';
import FadeUp from '../animations/FadeUp';
import SimpleButton from '../SimpleButton';
import { MdLibraryBooks } from "react-icons/md";
import { Link } from 'react-router-dom';
import Title from '../typography/Title';
import Subtitle from '../typography/Subtitle';

interface ClusterPaperProps {
    title: string;
    subtitle: string;
    imageUrl: string;
    className?: string;
    buttonText?: string;
    reportName?: string;
}

const ClusterPaper: React.FC<ClusterPaperProps> = ({ className, title, subtitle, imageUrl, buttonText = "Unlock Reports", reportName }) => {
    return (
        <div className={`py-32 lg:py-52 bg-custom-gray font-jakarta z-20 relative px-4 ${className}`}>
            <FadeUp>
                <div className="flex flex-col lg:flex-row justify-center items-center gap-12 container">
                    <div className="lg:w-1/2">
                        <img src={imageUrl} alt="Cluster" className="max-w-1/2 w-auto max-h-[40rem] mx-auto rounded-lg shadow-2xl" />
                    </div>
                    <div className="lg:w-1/2">
                        <Title variant='h2' >{title}</Title>
                        <div className='my-6'>
                            <TripleLine />
                        </div>
                        <Subtitle>{subtitle}</Subtitle>
                        <div className='flex justify-start my-8'>
                            <Link to={`/resources?search=${reportName}`}>
                                <SimpleButton
                                    label={buttonText}
                                    textColor="text-white"
                                    bgColor="bg-custom-black"
                                    size="medium"
                                    roundness="rounded-full"
                                    Icon={MdLibraryBooks}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </FadeUp>
        </div>
    );
};

export default ClusterPaper;
