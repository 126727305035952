import ParallaxHeader from "../../components/ParallaxHeader";
import BackgroundImage from "../../assets/images/talent_background.webp";
import WhatIsIt from "../../components/Projects/WhatIsIt";
import ToolDefinition from "../../components/Projects/ToolDefinition";
import ToolShowcase from "../../components/Projects/ToolShowcase";
import FactGrid from '../../components/FactGrid';
import Reduce from "../../assets/svgs/reduce.svg";
import Intersect from "../../assets/svgs/intersect.svg";
import Speed from "../../assets/svgs/speed.svg";
import Users from "../../assets/svgs/users.svg";
import GraduationCap from "../../assets/svgs/graduation-cap-thick.svg";
import TalentTimeline from '../../components/Projects/TalentTimeline';
import AssessmentSkills from '../../components/Projects/AssessmentSkills';
import AssessmentDashboard from '../../assets/images/my-assessment.webp';
import TalentDashboard from '../../assets/images/talent-exchange-dashboard.webp';
import AssessmentQuestion from '../../assets/images/my-assessment-question.webp';
import TalentOpp from '../../assets/images/talent-exchange-opp.webp';
import Profile from '../../components/Projects/Profile';
import Idea from '../../assets/svgs/lightbulb.svg';
import ArrowDown from '../../assets/svgs/arrow-down-filled.svg';
import Report from '../../assets/images/talent-platform-cover.webp';
import SectionSlant from '../../components/SectionSlant';
import Expand from '../../assets/svgs/full-alt-1.svg';
import TalentImaginePic from "../../assets/images/talent-triangle.webp";
import Eric from "../../assets/images/eric-profile.webp";
import RegularFade from '../../components/animations/RegularFade';
import Vision from "../../assets/images/vision_formula.png";
import Mission from "../../assets/images/mission_formula.png";
import RibbonCard from "../../components/RibbonCard";
import Puzzle from "../../assets/svgs/extension-puzzle-outline.svg";
import TalentDiagram from "../../assets/images/talent_diagram.webp";

const facts = [
    { fact: "Expand talent supply chain producing the most agile, educated, experientially trained, and diverse workforce in the country to meet enterprise demand.", imageSrc: Expand },
    { fact: "Materially reduce the number of open cybersecurity positions and eventually other high demand job openings in the region.", imageSrc: ArrowDown },
    { fact: "Enhance the talent acquisition experience for individuals seeking employment and for industry filling demand.", imageSrc: Users },
    { fact: "Accelerate Enterprise growth through recognition of regional talent as an asset.", imageSrc: Speed },
    { fact: "Increase the “collisions” between talent and industry through real world learning opportunities and mentoring.", imageSrc: Intersect },
    { fact: "Increase enrollment in regional cybersecurity and eventually other high demand career education programs.", imageSrc: GraduationCap },
    { fact: "Minimize the risk of new hires into the cybersecurity profession from all defined pathways.", imageSrc: Reduce },
    { fact: "Increase the awareness of the value that well-potentialed and well-suited talent can bring to accelerated enterprise growth.", imageSrc: Idea },
];

const TalentPlatformPage: React.FC = () => {
    return (
        <div className="z-50 flex flex-col mb-32">
            <ParallaxHeader
                title="Talent Platform"
                description='An interconnected ecosystem for cybersecurity talent'
                backgroundImage={BackgroundImage}
                className="!bg-bottom"
            />
            <div className="container z-20 mt-16 p-0">
                <RibbonCard
                    title="Problem Statement"
                    subtitle="Failing to meet the demand for cybersecurity job openings, will leave organizations vulnerable to increased cyber attacks and data breaches. The demand gap for cybersecurity talent has become a National Security problem in desperate need of innovative strategies to combat emerging threats. As a nation, we spend a great deal of time, effort, and money identifying and training star athletes or music prodigies from an early age. It is time to expend at least the same amount of energy identifying and training cyber talent of the future who can help safeguard our country and way of life."
                    ribbon="Problem Statement"
                    image={Puzzle}
                />
            </div>
            <WhatIsIt
                tagline="Bridging Talent with Opportunity"
                title='What Is It?'
                subtitle="The Talent Platform is the outcome of two discrete, yet complementary projects – The Assessment Tool and the Talent Exchange. Together, they form an interconnected ecosystem for cybersecurity talent to accelerate connections between cybersecurity talent and the demand sources for that talent. The Talent Platform’s purpose is to increase the supply of cybersecurity talent (and eventually other talent) and connect that talent to high-demand, high opportunity jobs right here in our region."
                image={TalentImaginePic}
                side="left"
            />
            <WhatIsIt
                tagline="Identify and Connect"
                title="Why It's Important"
                subtitle="The Talent Platform is the outcome of two discrete, yet complementary projects – The Assessment Tool and the Talent Exchange. Together, they form an interconnected ecosystem for cybersecurity talent to accelerate connections between cybersecurity talent and the demand sources for that talent. The Talent Platform’s purpose is to increase the supply of cybersecurity talent (and eventually other talent) and connect that talent to high-demand, high opportunity jobs right here in our region."
                image={TalentDiagram}
                side="right"
            />
            <ToolDefinition
                tagline="Evaluate and Engage"
                title='Assessment Tool'
                subtitle="The Assessment Tool is a psychometrically valid, industry guided software application measuring aptitude and interest. The tool will challenge the status quo by assessing how an individual thinks and what they value over simply what an individual knows. Early and timely identification will allow talent to mature through a vibrant cybersecurity infrastructure. Talent will be empowered to take the next step in their journey with insights into how their attributes align to cyber careers"
                image={AssessmentDashboard}
            />
            <ToolShowcase
                title="Engage with Interactive Questions"
                subtitle="Users can engage with the assessment tool through a series of interactive questions that measure aptitude and interest. Behind the scenes, responses are calculated and attributes are mapped to high growth, high demand career opportunities."
                image={AssessmentQuestion}
            />
            <AssessmentSkills />
            <ToolDefinition
                tagline="Connect and Grow"
                title='Talent Exchange'
                subtitle="The Talent Exchange is a platform that connects talent with industry through a series of events, mentorship, internships, and job opportunities. The Talent Exchange is a place where talent can grow, learn, and connect with industry professionals."
                image={TalentDashboard}
            />
            <ToolShowcase
                title="Connect with Industry Professionals"
                subtitle="Creating a space for personalized connection in highly transaction marketplace creates relationships that can scale into employment opportunities."
                image={TalentOpp}
            />
            <SectionSlant
                tagline=""
                title="Talent Platform"
                subtitle="Dive deeper into the details."
                image={Report}
                topColor='from-white'
                bottomColor='to-white'
                buttonText="Download the Narrative"
            />
            <FactGrid
                title="For the Region"
                subtitle="The Talent Platform will enhance today’s talent acquisition practices by fostering a more talent-centric approach within the Candidate Experience Journey. The Platform will define success based on its ability to..."
                facts={facts}
                className="bg-white !text-black !pb-8"
            />
            <div className="container z-20 mb-32 p-0">
                <div className='flex flex-col justify-start items-start gap-20'>
                    <RibbonCard
                        title="Mission"
                        subtitle="To identify individuals who are well-suited and well-potentialed for careers in cybersecurity, expand the talent pool for cybersecurity job openings, keep talent in the region, and create meaningful connections between talent and industry."
                        ribbon="Mission"
                        image={Mission}
                    />
                    <RibbonCard
                        title="Vision"
                        subtitle="Imagine an integrated and uniquely relational Talent Platform experience that will serve to increase the supply of cybersecurity talent and connect that talent to high-demand, high-opportunity jobs. Imagine a new approach to talent acquisition that creates “Magic Moments” and “Collisions” throughout the talent supply chain by presenting opportunities for industry to truly know talent and for talent to showcase their attributes in creative ways."
                        ribbon="Vision"
                        image={Vision}
                    />
                </div>
            </div>
            <TalentTimeline />
            <Profile
                name="Eric Mooney"
                role="Pillar Leader"
                imageUrl={Eric}
                linkedinUser="ericmooneyekc"
                email="emooney@enterprise-kc.com"
                phone="913-728-0133"
            />
        </div>
    )
}

export default RegularFade(TalentPlatformPage);