import React, { useContext, useState } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../assets/svgs/EKC_blue.svg";
import { Snackbar } from '@mui/material';

const LoginPage = () => {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const { login, error } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await login(credentials).then(() => {
            navigate('/dashboard');
        }).catch(() => {
            console.log('Error');
        });
    };

    return (
        <div className="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8 bg-white font-jakarta">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img className="mx-auto h-10 w-auto" src={Logo} alt="Your Company" />
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md shadow p-16">
                <h2 className="mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-custom-black">Sign in to your account</h2>
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-custom-black">Username</label>
                        <div className="mt-2">
                            <input
                                id="username"
                                name="username"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-custom-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ekc-blue sm:text-sm sm:leading-6"
                                value={credentials.username}
                                onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-custom-black">Password</label>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-custom-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ekc-blue sm:text-sm sm:leading-6"
                                value={credentials.password}
                                onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                            />
                        </div>
                    </div>

                    <div>
                        <button type="submit" className="flex w-full justify-center rounded-md bg-ekc-blue hover:bg-opacity-75 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ekc-blue">Sign in</button>
                    </div>

                    <div>
                        <p className="text-sm text-center text-gray-600">Don't have an account? <Link to="/admin/register" className="text-ekc-blue hover:text-opacity-75">Register</Link></p>
                    </div>
                </form>
                <Snackbar
                    ContentProps={{ sx: { backgroundColor: 'red' } }}
                    open={Boolean(error)}
                    autoHideDuration={6000}
                    message={error}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />
            </div>
        </div>
    );
};

export default LoginPage;
