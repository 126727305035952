
import React from 'react';
import Triple from '../TripleLine';
import SplitButton from '../SplitButton';
import KsIcon from '../../assets/svgs/outline-ks.svg';
import MoIcon from '../../assets/svgs/outline-missouri.svg';
import Tagline from '../typography/Tagline';

interface ClusterHeaderProps {
    name: string;
    motto: string;
    backgroundImage: string;
    industry: string;
}

const ClusterHeader: React.FC<ClusterHeaderProps> = ({ name, motto, backgroundImage, industry }) => {
    return (
        <div
            className="relative overflow-hidden flex items-center justify-center h-[900px] bg-cover bg-center font-jakarta"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-custom-black via-black/60"></div>
            <div className="relative z-10 text-center p-4 md:p-8 lg:p-12">
                <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-medium mb-4">{name}</h1>
                <div className='flex justify-center my-8'>
                    <Triple />
                </div>
                <Tagline className='text-white font-normal'>Explore the Data</Tagline>
                <div className='flex justify-center'>
                    <SplitButton
                        left={{
                            label: "Kansas",
                            link: `/explore-data/kansas/${industry}`,
                            textColor: "text-white",
                            bgColor: "bg-ekc-blue",
                            side: "left",
                            icon: KsIcon,
                            roundness: "rounded-l-full",
                            growScale: 1.1,
                        }}
                        right={{
                            label: "Missouri",
                            link: `/explore-data/missouri/${industry}`,
                            textColor: "text-white",
                            bgColor: "bg-ekc-green",
                            side: "right",
                            icon: MoIcon,
                            roundness: "rounded-r-full",
                            growScale: 1.1,
                        }}
                        size="medium"
                        fullWidth={false}
                    />

                </div>
            </div>
        </div>
    );
};

export default ClusterHeader;
