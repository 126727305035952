import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';

interface FadeRightProps {
    children: React.ReactNode;
    playOnce?: boolean;
}

const FadeRight: React.FC<FadeRightProps> = ({ children, playOnce = true }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: playOnce });

    const animationProps = {
        initial: { opacity: 0, translateX: 200 },
        animate: isInView ? { opacity: 1, translateX: 0 } : {},
        transition: { type: "tween", velocity: 100 }
    };

    return (
        <motion.div ref={ref} {...animationProps}>
            {children}
        </motion.div>
    );
};

export default FadeRight;
