import React, { useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './providers/AuthProvider';
import AnimatedRoutes from './components/animations/AnimatedRoutes';

import './styles/fonts.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;


const queryClient = new QueryClient();

function App() {
    useEffect(() => {
        const preloadLink1 = document.createElement('link');
        preloadLink1.rel = 'preload';
        preloadLink1.href = `${process.env.PUBLIC_URL}/fonts/PlusJakartaSans-VariableFont_wght.woff2`;
        preloadLink1.as = 'font';
        preloadLink1.type = 'font/woff2';
        preloadLink1.crossOrigin = 'anonymous';

        const preloadLink2 = document.createElement('link');
        preloadLink2.rel = 'preload';
        preloadLink2.href = `${process.env.PUBLIC_URL}/fonts/PlusJakartaSans-Italic-VariableFont_wght.woff2`;
        preloadLink2.as = 'font';
        preloadLink2.type = 'font/woff2';
        preloadLink2.crossOrigin = 'anonymous';

        document.head.appendChild(preloadLink1);
        document.head.appendChild(preloadLink2);

        return () => {
            document.head.removeChild(preloadLink1);
            document.head.removeChild(preloadLink2);
        };
    }, []);

    return (
        <Router>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <AnimatedRoutes />
                </AuthProvider>
            </QueryClientProvider>
        </Router>
    );
}

export default App;
