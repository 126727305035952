import React from 'react';
import Title from './typography/Title';
import Subtitle from './typography/Subtitle';
import Tagline from './typography/Tagline';

interface HeroTextProps {
    tagline: string;
    title: string;
    subtitle: string | JSX.Element;
    className?: string;
    darkMode?: boolean;
}

const HeroText: React.FC<HeroTextProps> = ({ tagline, title, subtitle, className, darkMode }) => {
    return (
        <div className={`${className}`}>
            <Tagline className={`inline-block ekc-text-gradient ${darkMode ? 'text-gray-100' : ''}`}>{tagline}</Tagline>
            <Title className={darkMode ? 'text-gray-100' : ''}>{title}</Title>
            <Subtitle className={`${darkMode ? 'text-gray-200' : 'text-gray-500'}`}>{subtitle}</Subtitle>
        </div>
    );
};

export default HeroText;