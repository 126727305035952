import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavBarState {
    isTransparent: boolean;
}

const initialState: NavBarState = {
    isTransparent: true,
};

export const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
        setNavbarTransparency: (state, action: PayloadAction<boolean>) => {
            state.isTransparent = action.payload;
        },
    },
});

export const { setNavbarTransparency } = navbarSlice.actions;

export default navbarSlice.reducer;
